
/* eslint-disable import/prefer-default-export */
import {
  takeEvery, all, put,
  call,
  select,
} from 'redux-saga/effects';
import moment from 'moment-timezone';
import * as R from 'ramda';
import * as Api from 'lib/Api';
import { selectors } from 'ducks/accountService';
import transformers from 'lib/CustomFunctions/transform/formatAccountServicesData';
import {
  selectors as loginSelectors,
} from 'ducks/login';
import {
  selectors as dashboardSelectors,
} from 'ducks/dashboard';
import {
  selectors as tombstoneSelectors,
} from 'ducks/tombstone';
import {
  ACCOUNT_SERVICE_EVENT_TYPES,
  DISASTER_RESOLUTION_CHOICE_TYPES,
  METLIFE_RESOLUTION_CHOICE_TYPES,
  ERROR,
  SUCCESS,
  firstTennesseeInvestorCodes, tDealsInvestorCodes, zDealsInvestorCodes,
  FLEX_RESOLUTION_CHOICE_TYPES, SPECIAL_SERVICING_RESOLUTION_CHOICE_TYPES,
  PAYMENT_DEFERRAL_DISASTER_RESOLUTION_CHOICE_TYPES,
  totalComputationTaskCodes,
} from 'constants/common';
import util from 'ducks/tasks-and-checklist/utils';
import {
  SET_RESULT_OPERATION,
  SEARCH_SELECT_EVAL,
  SEARCH_LOAN_WITH_TASK,
  SHOW_LOADER,
  HIDE_LOADER,
  SAVE_EVALID_LOANNUMBER,
} from 'ducks/dashboard/types';
import {
  SET_SNACK_BAR_VALUES_SAGA,
} from 'ducks/notifications/types';
import { SET_CAP_MOD_ID } from 'ducks/tombstone/types';
import DashboardModel from '../../../models/Dashboard';
import {
  ORDER_ESCROW,
  GATHER_ESCROW_DATA,
  OPEN_DIALOG,
  STORE_RESOLUTION_CHOICE_TYPE,
  STORE_ESCROW_DATA,
  STORE_ACC_EVENT_TYPE,
  ON_VALUE_CHANGE,
  STORE_ACC_INIT_VALUES,
  CLOSE_POPUPS,
  STORE_ACCOUNT_SERVICE_HISTORY,
  STORE_HISTORY_DATA,
  STORE_CAPMOD_HISTORY,
  STORE_CAPMOD_HISTORY_DATA,
  ACCOUNT_SERVICE_HISTORY_VIEW,
  STORE_CAPMOD_ID,
  STORE_CAPMOD_ID_HISTORY,
  SET_REQUEST_SUBMITTED,
  COMPLETE_ESCROW,
  DISPLAY_ACCOUNT_SERVICE_SCREEN,
  FETCH_TOMBSTONE_DATA,
  IS_VALUE_CHANGED,
  UPDATE_NET_DISBURSEMENT,
  CLEAR_INFO,
  SHOW_LOADER as SHOW_LOADER_ACCT_SRVC,
  SET_PROMPT_ERROR,
  CLEAR_HISTORY_INFO,
  SET_IS_APPROVED,
  SET_EVENT_TYPE,
  IS_REJECT_SUCCESSFUL,
  IS_NEW_ADHOC_RQST,
  STORE_ANALYSIS_BREAKDOWN_DATA,
  SHOW_LOADER_IN_POPUP,
  REFRESH_DATE,
  IS_NA_RQST,
  TO_CREATE_NEW_RQST,
} from './types';

import {
  triggerOrderCallAction,
  refreshStagerTile as refreshStagerTileAction,
  refreshStagerTileOnInput as refreshStagerTileOnInputAction,
  fetchStagerPayload,
} from '../stager/actions';

import {
  assignLoan,
} from '../dashboard/actions';

import {
  resetWidgetData,
} from '../widgets/actions';

const {
  Messages:
  {
    MSG_SERVICE_DOWN,
  },
} = DashboardModel;

function* fireSnackBar(snackBarData) {
  yield put({
    type: SET_SNACK_BAR_VALUES_SAGA,
    payload: snackBarData,
  });
}

function* rejectAccountServiceInfoSaga() {
  const loanId = yield select(selectors.loanNumber);
  const escrowData = yield select(selectors.getEscrowData);
  const evalId = yield select(selectors.evalId);
  const taskId = yield select(selectors.taskId);
  const capModRequestId = yield select(selectors.getSelectedCapModId);
  const accData = R.propOr({}, 'accountServices', escrowData);
  const appGroupName = yield select(dashboardSelectors.groupName);
  const toCreateNewRqst = yield select(selectors.toCreateNewRqst);

  if (appGroupName !== 'SEARCH_LOAN') {
    yield put({ type: SHOW_LOADER_IN_POPUP, payload: true });
  }

  let rejectResponse = null;
  try {
    yield put({
      type: IS_REJECT_SUCCESSFUL,
      payload: false,
    });

    const brand = yield select(dashboardSelectors.brand);

    const user = yield select(loginSelectors.getUser);
    const userEmail = R.path(['userDetails', 'email'], user);

    const rejectComments = R.propOr(null, 'REJECT_REASON', accData);

    const payload = {
      loanId: +loanId,
      rejectComments: rejectComments ? rejectComments.replace(/\+/g, '') : null,
      anticipatedModEffectiveDate: R.propOr(null, 'ANT_MOD_EFFD', accData),
      capModRequestId,
      brand,
      modifiedBy: userEmail,
      evalId,
    };
    rejectResponse = yield call(Api.callPost, '/api/escrow/escrow/saveRejectEscrowQuote', payload);
    rejectResponse = 'abc';
    const snackBarData = {
      message: rejectResponse !== null ? 'Escrow Request Rejected' : 'Failed to Reject Escrow Request',
      type: rejectResponse !== null ? SUCCESS : ERROR,
      open: true,
    };
    yield call(fireSnackBar, snackBarData);
    if (rejectResponse !== null && appGroupName !== 'SEARCH_LOAN' && toCreateNewRqst) {
      const orderPayload = [{
        evalId: evalId.toString(),
        taskId: taskId.toString(),
      }];
      const stagerPayload = {
        taskData: orderPayload,
        userPrincipalName: userEmail,
      };
      yield put(triggerOrderCallAction(
        stagerPayload, 'EscrowReordered',
      ));

      const toOrderpayload = escrowData.escrowToOrder;

      const requestDate = new Date().toISOString().slice(0, 10);

      const loanNumber = R.propOr(null, 'LOAN_NUMBER', toOrderpayload);
      const requestQuoteType = R.propOr(null, 'QUOTE_TYPE', toOrderpayload);
      const toOrderEvalId = R.propOr(null, 'EVAL_ID', toOrderpayload);
      const modEffectiveDate = moment(R.propOr(null,
        'EFFECTIVE_DATE', toOrderpayload)).format('YYYY-MM-DD');
      const requestSpread = R.propOr(null, 'SPREAD', toOrderpayload);

      const payLoad = {
        userEmail,
        requestDate,
        loanNumber,
        modEffectiveDate,
        requestSpread,
        brand,
        requestQuoteType,
        evalId: toOrderEvalId,
      };

      const response = yield call(Api.callPost, '/api/escrow/escrow/escrowToOrderSubmit', payLoad);

      if (response.responseStatus) {
        yield put({
          type: SET_REQUEST_SUBMITTED,
          payload: true,
        });

        if (response !== null) {
          yield put({
            type: CLEAR_INFO,
          });
          yield put({
            type: DISPLAY_ACCOUNT_SERVICE_SCREEN,
            payload: false,
          });
          yield put(refreshStagerTileOnInputAction({
            activeSearchTerm: 'EscrowCompleted',
          }));
        }
        yield put(refreshStagerTileAction());
        yield put({
          type: CLOSE_POPUPS,
        });
      }
      const toOrderSnackBarData = {
        message: (response.responseStatus) ? 'Escrow Ordered Successfully' : `Escrow Order ${response.message}`,
        type: (response.responseStatus) ? SUCCESS : ERROR,
        open: true,
      };
      yield call(fireSnackBar, toOrderSnackBarData);
    } else {
      if (!toCreateNewRqst) {
        yield put({
          type: TO_CREATE_NEW_RQST,
          payload: true,
        });
        yield put({
          type: CLEAR_INFO,
        });
        yield put({
          type: DISPLAY_ACCOUNT_SERVICE_SCREEN,
          payload: false,
        });

        const orderPayload = [{
          evalId: evalId.toString(),
          taskId: taskId.toString(),
        }];
        const stagerPayload = {
          taskData: orderPayload,
          userPrincipalName: userEmail,
        };
        yield put(triggerOrderCallAction(
          stagerPayload, 'EscrowOrdered',
        ));

        yield put(refreshStagerTileOnInputAction({
          activeSearchTerm: 'EscrowCompleted',
        }));
        yield put(refreshStagerTileAction());
        yield put({
          type: CLOSE_POPUPS,
        });
      }
      yield put({
        type: IS_REJECT_SUCCESSFUL,
        payload: true,
      });
      yield put({
        type: SET_EVENT_TYPE,
        payload: ACCOUNT_SERVICE_EVENT_TYPES.ACC_SERVICE,
      });
      yield put({
        type: SET_REQUEST_SUBMITTED,
        payload: true,
      });
    }
  } catch (e) {
    const snackBarData = {
      message: 'Failed to Reject Escrow Request',
      type: ERROR,
      open: true,
    };
    yield put({
      type: CLOSE_POPUPS,
    });
    yield call(fireSnackBar, snackBarData);
  }
  yield put(resetWidgetData());

  if (appGroupName !== 'SEARCH_LOAN') {
    yield put({ type: SHOW_LOADER_IN_POPUP, payload: false });
  }
}

function* escrowToOrder(eventType) {
  try {
    const evalId = yield select(selectors.evalId);
    const loanNumber = yield select(selectors.loanNumber);
    const taskId = yield select(selectors.taskId);
    const escrowData = yield select(selectors.getEscrowData);
    const resolutionChoicetype = yield call(Api.callGetText, `/api/tkams/search/resolutionDts/${evalId}`);
    yield put({
      type: STORE_RESOLUTION_CHOICE_TYPE,
      payload: resolutionChoicetype,
    });
    const effectiveDate = util.getFirstDayOfNextMonth('MM/DD/YYYY');
    let spreadValue;
    if (METLIFE_RESOLUTION_CHOICE_TYPES.includes(resolutionChoicetype)) {
      spreadValue = 60;
    } else if (DISASTER_RESOLUTION_CHOICE_TYPES.includes(resolutionChoicetype)) {
      spreadValue = 24;
    } else if (![...METLIFE_RESOLUTION_CHOICE_TYPES, ...DISASTER_RESOLUTION_CHOICE_TYPES]
      .includes(resolutionChoicetype)) {
      spreadValue = 12;
    }
    const loanNumbers = [loanNumber.toString()];
    let brand;
    const sodsResponse = yield call(Api.callPost,
      '/api/ods-gateway/stager/loans', { loanNumbers });
    if (!R.isEmpty(sodsResponse)) {
      sodsResponse.forEach((data) => {
        brand = data.brandName;
      });
    }
    const payloadForLsams = {
      loanNumber: loanNumber.toString(),
      brandArray: [brand],
    };
    const lsamsResponse = yield call(Api.callPost, '/api/utility/FetchLsamsLoanInfo', payloadForLsams);
    const investorCode = R.pathOr('', ['getLoanInformationResult', 'investor', 'code'], lsamsResponse);
    const investorName = R.pathOr('', ['getLoanInformationResult', 'investor', 'name'], lsamsResponse);
    const loanType = R.pathOr('', ['getLoanInformationResult', 'loanType'], lsamsResponse);
    if (([...FLEX_RESOLUTION_CHOICE_TYPES, ...PAYMENT_DEFERRAL_DISASTER_RESOLUTION_CHOICE_TYPES]
      .includes(resolutionChoicetype))
      && (investorName.toUpperCase().includes('FNMA') || investorName.toUpperCase().includes('FANNIE')
        || investorName.toUpperCase().includes('FREDDIE') || investorName.toUpperCase().includes('FHLMC'))) {
      spreadValue = 60;
    } else if (![...METLIFE_RESOLUTION_CHOICE_TYPES, ...DISASTER_RESOLUTION_CHOICE_TYPES,
      ...FLEX_RESOLUTION_CHOICE_TYPES, ...SPECIAL_SERVICING_RESOLUTION_CHOICE_TYPES,
      ...PAYMENT_DEFERRAL_DISASTER_RESOLUTION_CHOICE_TYPES]
      .includes(resolutionChoicetype)) {
      const gatewayServiceResponse = yield call(Api.callGet, `/api/ods-gateway/investor/getGNMAflag/${investorCode}`);
      const evalIsStateReviewFlag = yield call(Api.callGet, `/api/tkams/accountService/getEvalIsStateReviewFlag/${evalId}`);
      const gnmaFlagValue = R.propOr('N', 'gnmaFlag', gatewayServiceResponse[0]);
      if (gnmaFlagValue === 'Y' || evalIsStateReviewFlag === 1 || investorName.toUpperCase().includes('FNMA') || investorName.toUpperCase().includes('FANNIE')
        || investorName.toUpperCase().includes('FREDDIE') || investorName.toUpperCase().includes('FHLMC')
        || investorCode === '425' || [1, 4].includes(Number(loanType))
        || firstTennesseeInvestorCodes.includes(investorCode)
        || tDealsInvestorCodes.includes(investorCode)
        || zDealsInvestorCodes.includes(investorCode)) {
        spreadValue = 60;
      }
    }
    const response = {
      LOAN_NUMBER: loanNumber,
      QUOTE_TYPE: 'TREA',
      EFFECTIVE_DATE: effectiveDate,
      SPREAD: spreadValue,
      EVAL_ID: evalId,
      TASK_ID: taskId,
    };
    yield put({ type: STORE_ESCROW_DATA, payload: { ...escrowData, [eventType]: response } });
    yield put({ type: STORE_ACC_INIT_VALUES, payload: { ...escrowData, [eventType]: response } });
    yield put({ type: STORE_ACC_EVENT_TYPE, payload: eventType });
  } catch (e) {
    yield put({
      type: SET_RESULT_OPERATION,
      payload: {
        level: ERROR,
        status: 'Something went wrong while fetching escrowToOrder popup details',
      },
    });
  }
}

function* fetchAccountServiceData(eventType) {
  try {
    yield put({ type: CLEAR_HISTORY_INFO });
    yield put({ type: SHOW_LOADER_ACCT_SRVC, payload: true });
    yield put({ type: STORE_ESCROW_DATA, payload: { [eventType]: null } });
    yield put({ type: STORE_ACC_INIT_VALUES, payload: { [eventType]: null } });
    let loanNumber = null;
    let capModRequestId = yield (select(tombstoneSelectors.getCapModId));
    if (!capModRequestId) {
      loanNumber = yield select(selectors.loanNumber);
      capModRequestId = null;
    }
    const payLoad = {
      loanId: loanNumber,
      capModRequestId,
    };
    if (capModRequestId === 'NA') {
      yield put({ type: IS_NA_RQST, payload: true });
    }
    const escrowResponse = yield call(Api.callPost, '/api/escrow/escrow/getAccountServiceItem', payLoad);
    let response = null;
    if (escrowResponse) {
      response = transformers.formatAccountServicesData(escrowResponse);
    }
    yield put({ type: STORE_ESCROW_DATA, payload: { [eventType]: response } });
    yield put({ type: STORE_ACC_INIT_VALUES, payload: { [eventType]: response } });
    yield put({ type: STORE_ACC_EVENT_TYPE, payload: eventType });
    yield put({ type: STORE_CAPMOD_ID, payload: escrowResponse.capModRequestId });
    yield put({ type: SHOW_LOADER_ACCT_SRVC, payload: false });
    yield put({ type: STORE_ANALYSIS_BREAKDOWN_DATA, payload: response.analysisBreakdown });
  } catch (e) {
    yield put({ type: SHOW_LOADER_ACCT_SRVC, payload: false });
  }
}

function* orderEscrow() {
  try {
    yield put({ type: SHOW_LOADER_IN_POPUP, payload: true });

    const eventType = yield select(selectors.getAccountServiceEventType);
    if (eventType === ACCOUNT_SERVICE_EVENT_TYPES.ESCROW_TO_ORDER
      || eventType === ACCOUNT_SERVICE_EVENT_TYPES.ACC_SERVICE) {
      const isRejectBtnClicked = yield select(selectors.isRejectBtnClicked);
      if (isRejectBtnClicked) {
        yield call(rejectAccountServiceInfoSaga);
        return;
      }
      const appGroupName = yield select(dashboardSelectors.groupName);
      const escrowData = yield select(selectors.getEscrowData);
      const payload = escrowData.escrowToOrder;

      const requestDate = new Date().toISOString().slice(0, 10);

      const loanNumber = R.propOr(null, 'LOAN_NUMBER', payload);
      const requestQuoteType = R.propOr(null, 'QUOTE_TYPE', payload);
      let evalId = R.propOr(null, 'EVAL_ID', payload);
      const modEffectiveDate = moment(R.propOr(null,
        'EFFECTIVE_DATE', payload)).format('YYYY-MM-DD');
      const requestSpread = R.propOr(null, 'SPREAD', payload);
      const taskId = R.propOr(null, 'TASK_ID', payload);

      const loanNumbers = [loanNumber.toString()];
      let brand;
      const sodsResponse = yield call(Api.callPost,
        '/api/ods-gateway/stager/loans', { loanNumbers });
      if (!R.isEmpty(sodsResponse)) {
        sodsResponse.forEach((data) => {
          brand = data.brandName;
        });
      }

      if (appGroupName === 'SEARCH_LOAN') {
        evalId = null;
      }

      const currentUser = yield select(loginSelectors.getUser);
      const userEmail = R.pathOr('', ['userDetails', 'email'], currentUser);
      const payLoad = {
        userEmail,
        requestDate,
        loanNumber,
        modEffectiveDate,
        requestSpread,
        brand,
        requestQuoteType,
        evalId,
      };

      const response = yield call(Api.callPost, '/api/escrow/escrow/escrowToOrderSubmit', payLoad);
      if (response.responseStatus) {
        yield put({
          type: SET_REQUEST_SUBMITTED,
          payload: true,
        });
        if (appGroupName !== 'SEARCH_LOAN') {
          const orderPayload = [{
            evalId: evalId.toString(),
            taskId: taskId.toString(),
          }];
          const stagerPayload = {
            taskData: orderPayload,
            userPrincipalName: userEmail,
          };
          yield put(triggerOrderCallAction(
            stagerPayload, 'EscrowToOrder',
          ));
          yield put(refreshStagerTileAction());

          if (!isRejectBtnClicked) {
            yield put(fetchStagerPayload());
            yield put(refreshStagerTileOnInputAction({
              activeSearchTerm: 'EscrowOrdered',
            }));
          }

          yield put({
            type: CLOSE_POPUPS,
          });
        } else {
          const newCapModRequestId = response.getAccountServiceItemResponse.capModRequestId;
          yield put({ type: STORE_CAPMOD_ID, payload: newCapModRequestId });
          yield put({
            type: SET_CAP_MOD_ID,
            payload: { capModId: newCapModRequestId, viewType: 'loanViewData' },
          });
          yield put({ type: STORE_ACCOUNT_SERVICE_HISTORY });
          yield call(fetchAccountServiceData, ACCOUNT_SERVICE_EVENT_TYPES.ACC_SERVICE);
          yield put({
            type: CLOSE_POPUPS,
          });
          yield put({
            type: DISPLAY_ACCOUNT_SERVICE_SCREEN,
            payload: true,
          });
          yield put({
            type: IS_NEW_ADHOC_RQST,
            payload: true,
          });
          yield put({ type: IS_NA_RQST, payload: false });
        }
      }
      if (appGroupName !== 'SEARCH_LOAN') {
        const snackBarData = {
          message: (response.responseStatus) ? 'Escrow Ordered Successfully' : `Escrow Order ${response.message}`,
          type: (response.responseStatus) ? SUCCESS : ERROR,
          open: true,
        };
        yield call(fireSnackBar, snackBarData);
      } else {
        yield put({
          type: SET_PROMPT_ERROR,
          payload: {
            message: (response.responseStatus)
              ? 'Escrow Ordered Successfully' : `Escrow Order ${response.message}`,
            type: (response.responseStatus) ? 'pp-value-green' : 'pp-value-red',
          },
        });
      }
      yield put({ type: SHOW_LOADER_IN_POPUP, payload: false });
    }
  } catch (e) {
    const snackBarData = {
      message: 'Escrow Order Failed',
      type: ERROR,
      open: true,
    };
    yield put({
      type: CLOSE_POPUPS,
    });
    yield call(fireSnackBar, snackBarData);
    yield put({ type: SHOW_LOADER_IN_POPUP, payload: false });
  }
}

function* gatherEscrowData(action) {
  const { eventType } = action.payload;
  const appGroupName = yield select(dashboardSelectors.groupName);
  const isRejectBtnClicked = yield select(selectors.isRejectBtnClicked);
  const toCreateNewRqst = yield select(selectors.toCreateNewRqst);
  yield put({
    type: SET_REQUEST_SUBMITTED,
    payload: false,
  });
  if (eventType === ACCOUNT_SERVICE_EVENT_TYPES.ESCROW_TO_ORDER
    || (eventType === ACCOUNT_SERVICE_EVENT_TYPES.ACC_SERVICE && isRejectBtnClicked)) {
    if ((appGroupName === 'SEARCH_LOAN' && isRejectBtnClicked) || !toCreateNewRqst) {
      yield call(orderEscrow);
      return;
    }

    yield call(escrowToOrder, eventType);
    yield put({
      type: OPEN_DIALOG,
    });
  }
  if (eventType === ACCOUNT_SERVICE_EVENT_TYPES.ACC_SERVICE) {
    yield call(fetchAccountServiceData, eventType);
    yield put({
      type: DISPLAY_ACCOUNT_SERVICE_SCREEN,
      payload: true,
    });
  }
}

function* onChange(action) {
  try {
    const { id, value } = action.payload;

    if (value === null) {
      return;
    }
    const escrowData = yield select(selectors.getEscrowData);
    const eventType = yield select(selectors.getAccountServiceEventType);
    const accInitValues = yield select(selectors.escrowDataInitValue);
    const data = escrowData[eventType];
    // eslint-disable-next-line no-restricted-globals
    const updatedEscrowData = {
      ...data,
      // eslint-disable-next-line no-restricted-globals
      [id]: (isNaN(value) || value === null)
        ? value : parseFloat(value, 10),
    };
    if (R.contains(id, totalComputationTaskCodes)) {
      const taxes = parseFloat(String(updatedEscrowData.TAXES).replace(/,/g, ''));
      const hazardInsurance = parseFloat(String(updatedEscrowData.HAZ_INS).replace(/,/g, ''));
      const mortgageInsurance = parseFloat(String(updatedEscrowData.MORT_INS).replace(/,/g, ''));
      const shortage = parseFloat(String(updatedEscrowData.SHORTAGE).replace(/,/g, ''));

      updatedEscrowData.TOTAL = taxes + hazardInsurance + mortgageInsurance + shortage;

      updatedEscrowData.analysisBreakdown.MON_TAX = taxes;
      updatedEscrowData.analysisBreakdown.MON_INS = hazardInsurance;
      updatedEscrowData.analysisBreakdown.MON_MI = mortgageInsurance;
      updatedEscrowData.analysisBreakdown.TOT_ESC_PAY = taxes + hazardInsurance
        + shortage + parseFloat(updatedEscrowData.analysisBreakdown.monthlyBorrowerPaidMi);
    }
    const updatedPayload = { ...escrowData, [eventType]: updatedEscrowData };
    yield put({
      type: STORE_ESCROW_DATA,
      payload: updatedPayload,
    });
    const hasValueChange = R.reduce((acc, key) => acc || accInitValues[eventType][key]
      !== updatedPayload[eventType][key], false,
    accInitValues[eventType]
      ? Object.keys(accInitValues[eventType])
      : Object.keys(updatedPayload[eventType]));
    yield put({
      type: IS_VALUE_CHANGED,
      payload: hasValueChange,
    });
  } catch (e) {
    yield put({
      type: SET_RESULT_OPERATION,
      payload: {
        level: ERROR,
        status: MSG_SERVICE_DOWN,
      },
    });
  }
}

function* fetchAccountServiceHistory() {
  try {
    const loanNumber = yield (select(tombstoneSelectors.getLoanNumber));
    let loanId = yield select(selectors.loanNumber);
    if (loanId === null || loanId === 0) {
      loanId = loanNumber;
    }
    const response = yield call(Api.callGet, `/api/dataservice/accountService/getAccServHistoryByLoanId/${loanId}`);
    if (response !== null) {
      yield put({
        type: STORE_HISTORY_DATA,
        payload: response,
      });
    }
  } catch (e) {
    yield put({
      type: SET_RESULT_OPERATION,
      payload: {
        level: ERROR,
        status: MSG_SERVICE_DOWN,
      },
    });
  }
}

function* fetchHistoryForCapModId(action) {
  try {
    yield put({ type: SHOW_LOADER_ACCT_SRVC, payload: true });
    yield put({ type: STORE_CAPMOD_HISTORY_DATA, payload: null });
    yield put({ type: ACCOUNT_SERVICE_HISTORY_VIEW, payload: false });
    const capModId = action.payload;
    if (capModId) {
      const payLoad = {
        loanId: null,
        capModRequestId: capModId,
      };
      const escrowResponse = yield call(Api.callPost, '/api/escrow/escrow/getAccountServiceItem', payLoad);
      let response = null;
      if (escrowResponse) {
        response = transformers.formatAccountServicesData(escrowResponse);
      }
      const combinedResponse = {
        capmodDetails: response,
        analysisResponse: response.analysisBreakdown || {},
      };
      yield put({ type: STORE_CAPMOD_ID_HISTORY, payload: capModId });
      yield put({ type: STORE_CAPMOD_HISTORY_DATA, payload: combinedResponse });
      yield put({ type: ACCOUNT_SERVICE_HISTORY_VIEW, payload: true });
    }
    yield put({ type: SHOW_LOADER_ACCT_SRVC, payload: false });
  } catch (e) {
    yield put({
      type: SET_RESULT_OPERATION,
      payload: {
        level: ERROR,
        status: MSG_SERVICE_DOWN,
      },
    });
    yield put({ type: SHOW_LOADER_ACCT_SRVC, payload: false });
  }
}

function* completeEscrow() {
  /*  escrow completion */
  try {
    const loanNumber = yield select(selectors.loanNumber);
    const escrowData = yield select(selectors.getEscrowData);
    const evalId = yield select(selectors.evalId);
    const taskId = yield select(selectors.taskId);
    const capModRequestId = yield select(selectors.getSelectedCapModId);
    const accData = R.propOr({}, 'accountServices', escrowData);
    const appGroupName = yield select(dashboardSelectors.groupName);

    const brand = yield select(dashboardSelectors.brand);

    const user = yield select(loginSelectors.getUser);
    const userEmail = R.path(['userDetails', 'email'], user);

    const taxP1Amount = R.propOr(null, 'TAXES', accData);
    const insP1Amount = R.propOr(null, 'HAZ_INS', accData);
    const miP1Amount = R.propOr(null, 'MORT_INS', accData);
    const shrtP1Amount = R.propOr(null, 'SHORTAGE', accData);

    const payload = {
      loanId: +loanNumber,
      capModRequestId,
      taxP1Amount: taxP1Amount
        && parseFloat(taxP1Amount.toString().replaceAll(',', '')),
      insP1Amount: insP1Amount
        && parseFloat(insP1Amount.toString().replaceAll(',', '')),
      miP1Amount: miP1Amount
        && parseFloat(miP1Amount.toString().replaceAll(',', '')),
      shrtP1Amount: shrtP1Amount
        && parseFloat(shrtP1Amount.toString().replaceAll(',', '')),
      rqstSprd: R.propOr(null, 'REQ_SPR_MON', accData),
      anticipatedModEffectiveDate:
        moment(R.propOr(null, 'ANT_MOD_EFFD', accData)).format('YYYY-MM-DD'),
      brand,
      modifiedBy: userEmail,
      evalId,
    };

    const response = yield call(Api.callPost, '/api/escrow/escrow/completeEscrowQuote', payload);

    if (response) {
      yield put({
        type: SET_REQUEST_SUBMITTED,
        payload: true,
      });
      yield put({
        type: SET_IS_APPROVED,
        payload: true,
      });
      if (appGroupName !== 'SEARCH_LOAN') {
        const orderPayload = [{
          evalId: evalId.toString(),
          taskId: taskId.toString(),
        }];
        const stagerPayload = {
          taskData: orderPayload,
          userPrincipalName: userEmail,
        };
        yield put(triggerOrderCallAction(
          stagerPayload, 'EscrowOrdered',
        ));
        yield put(refreshStagerTileAction());
        yield put(refreshStagerTileOnInputAction({
          activeSearchTerm: 'EscrowCompleted',
        }));
      }
    }
    const snackBarData = {
      message: response !== null ? 'Escrow Request Approved' : 'Failed to Approve Escrow Request',
      type: response !== null ? SUCCESS : ERROR,
      open: true,
    };
    yield call(fireSnackBar, snackBarData);
  } catch (e) {
    const snackBarData = {
      message: 'Failed to Approve Escrow Request',
      type: ERROR,
      open: true,
    };
    yield put({
      type: CLOSE_POPUPS,
    });
    yield call(fireSnackBar, snackBarData);
  }
}

function* fetchTombstoneData(action) {
  try {
    const rowData = { ...R.pathOr(false, ['rowData'], action.payload) };
    const assignTask = R.pathOr(false, ['assignTask'], action.payload);
    yield put({ type: SHOW_LOADER });
    try {
      const bpmTaskDetail = yield call(Api.callGet, `/api/bpm-audit/audit/task/${rowData.TKIID}`, {});
      if (bpmTaskDetail) {
        rowData.taskIterationCounter = bpmTaskDetail.currentTaskIterationCounter;
        rowData.taskName = bpmTaskDetail.taskName;
        rowData.taskStatus = bpmTaskDetail.currentStatus;
        rowData.PID = bpmTaskDetail.processId;
        if (rowData['Assigned To']) {
          rowData['Assigned To'] = rowData['Assigned To'].startsWith('cmod-') ? 'In Queue' : rowData['Assigned To'];
        }
        yield put({
          type: SEARCH_LOAN_WITH_TASK,
          payload: rowData,
        });
      } else {
        yield put({
          type: SEARCH_LOAN_WITH_TASK,
          payload: { statusCode: 404 },
        });
      }
    } catch (e) {
      yield put({
        type: SEARCH_LOAN_WITH_TASK,
        payload: { loanNumber: rowData['Loan Number'], valid: false },
      });
    }
    const payload = {
      ...rowData,
      isSearch: true,
      evalId: rowData['Eval ID'].toString(),
      loanNumber: rowData['Loan Number'],
      taskId: rowData.TKIID,
      piid: rowData.PID,
      pStatus: 'Active',
      tStatus: rowData.taskStatus,
      processName: rowData.taskName,
      taskIterationCounter: rowData.taskIterationCounter,
      assignee: rowData['Assigned To'],
    };
    yield put({ type: SEARCH_SELECT_EVAL, payload });
    yield put({ type: SAVE_EVALID_LOANNUMBER, payload });
    yield put({ type: HIDE_LOADER });

    if (assignTask) {
      yield put(assignLoan());
    }
  } catch (e) {
    yield put({
      type: SET_RESULT_OPERATION,
      payload: {
        level: ERROR,
        status: 'Something went wrong while fetching tombstone data informations',
      },
    });
  }
}

function* updateNetDisbursementSaga() {
  let response = null;
  try {
    const escrowData = yield select(selectors.getEscrowData);
    const capModRequestId = yield select(selectors.getSelectedCapModId);
    const accData = escrowData.accountServices || {};

    const user = yield select(loginSelectors.getUser);
    const userEmail = R.path(['userDetails', 'email'], user);

    const convDisbursementAmount = R.propOr(null, 'CONV_DISB', accData);
    const unpaidLegalFees = R.propOr(null, 'UNP_LEG_FEE', accData);

    const payload = {
      loanId: +(yield select(dashboardSelectors.loanNumber)),
      capModRequestId,
      convDisbursementAmount: convDisbursementAmount
        && parseFloat(convDisbursementAmount.toString().replaceAll(',', '')),
      unpaidLegalFees: unpaidLegalFees
        && parseFloat(unpaidLegalFees.toString().replaceAll(',', '')),
      createdBy: userEmail,
    };
    response = yield call(Api.callPost, '/api/escrow/escrow/escrowReqToUpdateQuote', payload);
    const snackBarData = {};
    snackBarData.message = response !== null ? 'Escrow Request Updated' : 'Failed to Update Ecrow Request';
    snackBarData.type = response !== null ? SUCCESS : ERROR;
    snackBarData.open = true;
    yield call(fireSnackBar, snackBarData);
  } catch (e) {
    const snackBarData = {};
    snackBarData.message = 'Something went wrong!!';
    snackBarData.type = ERROR;
    snackBarData.open = true;
    yield call(fireSnackBar, snackBarData);
  }
  if (response !== null) {
    yield put({
      type: DISPLAY_ACCOUNT_SERVICE_SCREEN,
      payload: false,
    });
    yield put({
      type: IS_VALUE_CHANGED,
      payload: false,
    });
  }
}

function* updateRefreshDateSaga() {
  const capModRequestId = yield select(selectors.getSelectedCapModId);

  try {
    const refreshedDate = yield call(Api.callGet, `/api/escrow/escrow/updateRefreshDate/${capModRequestId}`);

    yield call(onChange, {
      payload: {
        id: 'REF_DATE', value: refreshedDate,
      },
    });
  } catch (e) {
    const snackBarData = {
      message: 'Failed to Updated Refresh Date',
      type: ERROR,
      open: true,
    };
    yield put({
      type: CLOSE_POPUPS,
    });
    yield call(fireSnackBar, snackBarData);
  }
}

function* watchOrderEscrow() {
  yield takeEvery(ORDER_ESCROW, orderEscrow);
}

function* watchGatherEscrowData() {
  yield takeEvery(GATHER_ESCROW_DATA, gatherEscrowData);
}

function* watchOnChange() {
  yield takeEvery(ON_VALUE_CHANGE, onChange);
}

function* watchstoreAccountServicesHistory() {
  yield takeEvery(STORE_ACCOUNT_SERVICE_HISTORY, fetchAccountServiceHistory);
}

function* watchStoreCapModIdHistory() {
  yield takeEvery(STORE_CAPMOD_HISTORY, fetchHistoryForCapModId);
}

function* watchCompleteEscrow() {
  yield takeEvery(COMPLETE_ESCROW, completeEscrow);
}

function* watchFetchTombstoneData() {
  yield takeEvery(FETCH_TOMBSTONE_DATA, fetchTombstoneData);
}

function* watchUpdateNetDisbursementSaga() {
  yield takeEvery(UPDATE_NET_DISBURSEMENT, updateNetDisbursementSaga);
}

function* watchUpdateRefreshDateSaga() {
  yield takeEvery(REFRESH_DATE, updateRefreshDateSaga);
}

export function* combinedSaga() {
  yield all([
    watchUpdateNetDisbursementSaga(),
    watchOrderEscrow(),
    watchGatherEscrowData(),
    watchOnChange(),
    watchstoreAccountServicesHistory(),
    watchStoreCapModIdHistory(),
    watchCompleteEscrow(),
    watchFetchTombstoneData(),
    watchUpdateRefreshDateSaga(),
  ]);
}
