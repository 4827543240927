const ERROR = 'error';
const SUCCESS = 'success';
const FAILED = 'failed';
const NOTFOUND = 'NotFound';
const TRUE = 'true';
const FALSE = 'false';
const ALL = 'all';
const ANY = 'any';
const EXCEL_FORMATS = ['xlsx', 'xls'];
const checklistGridColumnSize = {
  title: {
    'income-calculator': 8,
    'expense-calculator': 8,
    'asset-checklist': 8,
    'fico-checklist': 8,
    'asset-checklist-back': 2,
    'sla-rules': 10,
    'doc-checklist': 8,
    default: 6,
  },
  lockedDate: {
    default: 1,
  },
  lockId: {
    default: 2,
  },
  lockedBy: {
    default: 6,
  },
  check: {
    'income-calculator': 1,
    'expense-calculator': 1,
    'asset-verification': 1,
    'fico-score': 1,
    'doc-checklist': 1,
    'asset-checklist': 1,
    'fico-checklist': 1,
    default: 2,
  },
  lock: {
    'income-calculator': 1,
    'expense-calculator': 1,
    'asset-checklist': 1,
    'fico-checklist': 1,
    default: 2,
  },
  prev: {
    'income-calculator': 1,
    'expense-calculator': 1,
    'asset-checklist': 1,
    'sla-rules': 1,
    'fico-score': 1,
    'doc-checklist': 1,
    'fico-checklist': 1,
    default: 2,
  },
  next: {
    'income-calculator': 1,
    'expense-calculator': 1,
    'asset-checklist': 1,
    'sla-rules': 1,
    'fico-score': 1,
    'doc-checklist': 1,
    'fico-checklist': 1,
    default: 2,
  },
  back: {
    default: 1,
  },
  clear: {
    default: 2,
  },
};
const hideClearButton = ['sla-rules', 'income-calculator', 'expense-calculator', 'asset-verification', 'fico-checklist', 'asset-checklist', 'delay-checklist'];
const hidePrevNextButton = ['delay-checklist'];
const financialChecklist = ['income-calculator', 'expense-calculator', 'asset-verification', 'fico-checklist', 'asset-checklist'];
const checklistForms = ['fico-checklist', 'asset-checklist', 'doc-checklist', 'delay-checklist'];
const MortgageConnectRequiredFields = ['BAL_PRINCIPAL',
  'MOD_BAL_AMORITIZED',
  'MOD_BAL_PRINCIPAL',
  'MOD_EFFDTE',
  'MOD_IR_ANNUALAPR',
  'MOD_IR_EFFDTE',
  'MOD_LOAN_TERM',
  'MOD_MATUREDTE',
  'MOD_PITIPYMNT',
  'REC_REQINDICATOR',
  'TTLPOLICY_REQINDICATOR'];

const STAGER = {
  TO_ORDER: 'To Order',
  ORDERED: 'Ordered',
  COMPLETED: 'Completed',
};

const ACCOUNT_SERVICE_EVENT_TYPES = {
  ESCROW_TO_ORDER: 'escrowToOrder',
  ACC_SERVICE: 'accountServices',
};

const nonNumericKeys = ['netDisbursementRefreshDate', 'anticipatedModEffectiveDate', 'isDelinquentOnPropertyTaxes',
  'monthStart', 'monthEnd', 'forceEscrow', 'escrow', 'modEffectiveDate', 'requestDate', 'completedDate', 'requestSpread',
  'escrowRejectedComments'];

const METLIFE_RESOLUTION_CHOICE_TYPES = ['MetLife Pandemic Trial', 'MetLife Pandemic Modification', 'MetLife Standard Modification', 'MetLife Standard Trial'];
const DISASTER_RESOLUTION_CHOICE_TYPES = ['Disaster FHA Modification'];
const FLEX_RESOLUTION_CHOICE_TYPES = ['Flex Modification', 'Flex Modification Trial'];
const SPECIAL_SERVICING_RESOLUTION_CHOICE_TYPES = ['Special Servicing Trial', 'Special Servicing Modification'];
const PAYMENT_DEFERRAL_DISASTER_RESOLUTION_CHOICE_TYPES = ['Payment Deferral Disaster'];

const RESEARCH_REQUESTED = 'Research Requested';

const modInfoScreenTaskCode = ['CONV_DISB', 'UNP_LEG_FEE', 'ANT_MOD_EFFD', 'NET_DISB'];

const escrowOrderedTaskCodes = ['TAXES',
  'HAZ_INS',
  'MORT_INS',
  'SHORTAGE',
  'REQ_SPR_MON',
  'NET_DISB',
];

const totalComputationTaskCodes = [
  'TAXES',
  'HAZ_INS',
  'MORT_INS',
  'SHORTAGE',
];

const messages = {
  PENDING_APPROVAL: 'Research Requested - Quote can not be completed at this time.',
  APPROVED: 'Escrow Request Successfully approved',
  REJECTED: 'Escrow Request rejected',
};

const firstTennesseeInvestorCodes = ['090', '301', '305', '767', '778', '782', '806'];
const tDealsInvestorCodes = ['BJ9', 'CAT', 'BX6', 'BX7'];
const zDealsInvestorCodes = ['F02', 'F03', 'F04', 'Y87', 'Y88'];
const SUCCESS_RESULT = 'Success';

module.exports = {
  escrowOrderedTaskCodes,
  messages,
  RESEARCH_REQUESTED,
  METLIFE_RESOLUTION_CHOICE_TYPES,
  DISASTER_RESOLUTION_CHOICE_TYPES,
  FLEX_RESOLUTION_CHOICE_TYPES,
  SPECIAL_SERVICING_RESOLUTION_CHOICE_TYPES,
  PAYMENT_DEFERRAL_DISASTER_RESOLUTION_CHOICE_TYPES,
  STAGER,
  NOTFOUND,
  ERROR,
  FAILED,
  SUCCESS,
  TRUE,
  FALSE,
  ALL,
  ANY,
  EXCEL_FORMATS,
  checklistGridColumnSize,
  hideClearButton,
  hidePrevNextButton,
  financialChecklist,
  checklistForms,
  ACCOUNT_SERVICE_EVENT_TYPES,
  modInfoScreenTaskCode,
  nonNumericKeys,
  firstTennesseeInvestorCodes,
  tDealsInvestorCodes,
  zDealsInvestorCodes,
  MortgageConnectRequiredFields,
  totalComputationTaskCodes,
  SUCCESS_RESULT,
};
