import React, { useState, useEffect } from 'react';
import {
  Button, MenuItem, Select,
} from '@material-ui/core';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import '../BulkStatus.css';
import InputField from 'components/InputField/InputField';
import InputFieldMultiline from 'components/ProductSupport/InputFieldMultiline';
import SubmittedRecords from 'components/SubmittedRecords';
import Popup from 'components/Popup';
import { BULK_CASE_STATUS_OPTIONS } from 'containers/SupportAdmin/constants';
import {
  operations as admintoolOperations,
} from 'ducks/admin-tools';
import { BULK_STATUS } from 'constants/SupportAdmin';
import SweetAlertBox from '../../../../components/SweetAlertBox';
import { selectors as dashboardSelectors, operations as dashboardOperations } from '../../../../state/ducks/dashboard';

const defaultFormData = {
  ticket: '',
  case: '0',
  fromStatus: '',
  toStatus: '',
  requestorID: '',
  comments: '',
};


const BulkStatusCase = ({
  recordsInfo, type, submitBulkStatusOperation,
  resultOperation, closeSweetAlert,
}) => {
  const [caseObj, setCaseObj] = useState(defaultFormData);
  const [disableButton, setDisableButton] = useState(true);
  const [disableChangeStatus, setDisableChangeStatus] = useState(true);
  const [ispopupVisible, setIspopupVisible] = useState(false);
  const [idLimitValidation, setIdLimitValidation] = useState(false);
  const [renderContent, setRenderContent] = useState('');
  const [msgType, setMsgType] = useState('');
  const [isOpen, setIsOpen] = useState(false);


  useEffect(() => {
    if (caseObj.case === '') {
      setCaseObj(prevState => ({
        ...prevState,
        case: 0,
      }));
      setDisableChangeStatus(true);
    }
  }, [caseObj]);

  useEffect(() => {
    if (resultOperation && resultOperation.status) {
      const { status, level } = resultOperation;
      setRenderContent(status);
      setMsgType(level || 'Failed');
      setIsOpen(true);
    }
  }, [resultOperation]);


  useEffect(() => {
    setCaseObj(defaultFormData);
    setDisableButton(true);
    setDisableChangeStatus(true);
  }, [recordsInfo]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    const updatedObject = { ...caseObj };
    updatedObject[name] = value;
    setCaseObj(updatedObject);
  };

  const handleBlur = () => {
    const {
      ticket, case: caseId, fromStatus, toStatus, requestorID, comments,
    } = caseObj;
    const enableClear = ticket !== '' || (caseId !== 0 && caseId.length > 0)
      || fromStatus !== '' || toStatus !== '' || requestorID !== '' || comments !== '';
    const enableChangeStatus = ticket !== '' && (caseId !== 0 && caseId.length > 0)
      && fromStatus !== '' && toStatus !== '' && requestorID !== '' && comments !== '';
    setDisableButton(!enableClear);
    setDisableChangeStatus(!enableChangeStatus);
  };

  const handleDialogClose = () => {
    setIsOpen(false);
    closeSweetAlert();
  };

  const handleChangeID = (event) => {
    const { name, value } = event.target;
    const updatedObjectNew = { ...caseObj };
    const validIdEntries = RegExp(/[a-zA-Z]|[~`(@!#$%^&*+._)=\-[\]\\';/{}|\\":<>?]/);
    updatedObjectNew[name] = !validIdEntries.test(value) ? value.split(/,| /).join('\n') : '';
    const conIdsArray = value && value.split('\n').map(id => +id);
    setCaseObj(updatedObjectNew);
    setIdLimitValidation(conIdsArray.length > 100);
    handleBlur();
  };

  // eslint-disable-next-line max-len
  const renderFromStatusOptions = () => Object.keys(BULK_CASE_STATUS_OPTIONS).map(ele => <MenuItem value={ele}>{ele || 'Select'}</MenuItem>);

  const renderToStatusOptions = () => (!R.isEmpty(caseObj.fromStatus)
    ? BULK_CASE_STATUS_OPTIONS[caseObj.fromStatus]
      .map(ele => <MenuItem value={ele}>{ele}</MenuItem>)
    : <MenuItem value="">Select</MenuItem>);

  const enableDropDowns = caseObj.case !== '0' && caseObj.case.length > 0;

  const handleSubmit = () => {
    const idsArray = caseObj.case.split('\n').map(id => +id).filter(id => id !== 0);
    const requestData = {
      ...caseObj,
      busKeyType: 'case',
      action: BULK_STATUS,
      ids: idsArray,
    };
    submitBulkStatusOperation(requestData);
    setIspopupVisible(false);
  };

  return (
    <div styleName="form-fields-container">
      <InputField
        handleBlur={handleBlur}
        handleChange={handleChange}
        label="ServiceNow Ticket"
        name="ticket"
        type="number"
        value={caseObj.ticket}
      />
      <InputFieldMultiline
        errorMsg={idLimitValidation && 'Entries exceeded the maximum limit of 100'}
        handleChange={handleChangeID}
        label="Case ID"
        name="case"
        value={caseObj.case}
      />
      <div styleName="flex-container select-field-container">
        <p>From Status</p>
        <Select
          disabled={!enableDropDowns}
          displayEmpty
          label="Select"
          name="fromStatus"
          onBlur={handleBlur}
          onChange={handleChange}
          value={caseObj.fromStatus}
        >
          <MenuItem value="">Select</MenuItem>
          {renderFromStatusOptions()}
        </Select>
      </div>
      <div styleName="flex-container select-field-container">
        <p>To Status</p>
        <Select
          disabled={!enableDropDowns || caseObj.fromStatus === ''}
          displayEmpty
          label="Select"
          name="toStatus"
          onBlur={handleBlur}
          onChange={handleChange}
          value={caseObj.toStatus}
        >
          {renderToStatusOptions()}
        </Select>
      </div>

      <InputField
        handleBlur={handleBlur}
        handleChange={handleChange}
        label="Requestor ID"
        name="requestorID"
        value={caseObj.requestorID}
      />
      <InputField
        handleBlur={handleBlur}
        handleChange={handleChange}
        label="Comments"
        multiline
        name="comments"
        value={caseObj.comments}
      />
      <span styleName="divider" />
      <div
        style={{ justifyContent: 'flex-end' }}
        styleName="flex-container"
      >
        <Button
          color="primary"
          disabled={disableButton}
          onClick={() => {
            setCaseObj(defaultFormData);
            setDisableButton(true);
            setDisableChangeStatus(true);
            setIdLimitValidation(false);
          }}
          style={{ marginRight: '8px' }}
          variant="outlined"
        >
          CLEAR
        </Button>
        <Button
          color="primary"
          disabled={idLimitValidation || disableChangeStatus}
          onClick={() => setIspopupVisible(true)}
          variant="contained"
        >
          CHANGE STATUS
        </Button>
      </div>
      <SubmittedRecords
        successfulRecords={recordsInfo.successCount || 0}
        type={type === 'case' ? 'Case ID' : 'Eval ID'}
        unSuccessfulRecords={recordsInfo.failureCount || 0}
        unsuccessfulRecordsResp={recordsInfo.unsuccessfulRecords || []}
      />
      <Popup
        cancelButtonText="Cancel"
        confirmButtonText="Ok"
        level="Info"
        message="Are you sure you want to proceed?"
        onCancel={() => setIspopupVisible(false)}
        onConfirm={handleSubmit}
        show={ispopupVisible}
        showCancelButton
        showConfirmButton
      />
      {resultOperation && resultOperation.status && (
      <SweetAlertBox
        message={renderContent}
        onConfirm={() => handleDialogClose()}
        show={isOpen}
        type={msgType}
      />
      )}
    </div>
  );
};

BulkStatusCase.defaultProps = {
  submitBulkStatusOperation: () => { },
  closeSweetAlert: () => {},
};

BulkStatusCase.propTypes = {
  closeSweetAlert: PropTypes.func,
  recordsInfo: PropTypes.shape().isRequired,
  resultOperation: PropTypes.shape({
    status: PropTypes.string,
  }).isRequired,
  submitBulkStatusOperation: PropTypes.func,
  type: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  resultOperation: dashboardSelectors.resultOperation(state),
});

const mapDispatchToProps = dispatch => ({
  submitBulkStatusOperation: admintoolOperations.submitBulkStatusOperation(dispatch),
  closeSweetAlert: dashboardOperations.closeSweetAlert(dispatch),

});

export default connect(mapStateToProps, mapDispatchToProps)(BulkStatusCase);
