import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button, Grid, Typography, IconButton, Modal, TextField,
}
  from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import { selectors as loginSelectors } from '../../../state/ducks/login';
import { operations, selectors } from '../../../state/ducks/eval';

import './OverrideMessage.css';
import { OVERRIDE_CONFIRMATION_DIALOG_MSG } from '../../../constants/eval';

const OverrideMessage = (props) => {
  const {
    ruleEngineErrorMsg, selectedCaseId, saveOverriddenRule,
  } = props;
  const [overrideData, setOverrideData] = useState({
    isErrorMsgVisible: true,
    show: false,
    notes: '',
    isDisabled: true,
    isConfirmPopupVisible: false,
  });

  const handleChange = (event) => {
    const notesValue = event.target.value;
    setOverrideData({
      ...overrideData,
      notes: notesValue,
      isDisabled: notesValue.length <= 0,
    });
  };

  const handleClose = () => {
    setOverrideData({
      ...overrideData,
      show: false,
    });
  };

  const defaultState = () => {
    setOverrideData({
      ...overrideData,
      notes: '',
      show: false,
      isConfirmPopupVisible: false,
      isDisabled: true,
    });
  };

  const handleSubmit = () => {
    const { notes } = overrideData;
    const saveData = {
      comments: notes,
      ruleId: ruleEngineErrorMsg.ruleId,
      ruleMessage: ruleEngineErrorMsg.errorMsg,
      resolutionId: selectedCaseId,
    };
    saveOverriddenRule(saveData);
    defaultState();
  };

  const renderOverridePopup = () => {
    const {
      show, notes, isDisabled, isConfirmPopupVisible,
    } = overrideData;
    return (
      <Modal
        disableBackdropClick
        onClose={handleClose}
        open={show}
      >
        <div styleName="override-popup-container">
          <Typography variant="h2">Override Rule</Typography>
          {!isConfirmPopupVisible && (
          <IconButton aria-label="close" onClick={() => setOverrideData({ ...overrideData, isConfirmPopupVisible: true })} styleName="close-btn">
            <CloseIcon />
          </IconButton>
          )}

          {!isConfirmPopupVisible ? (
            <div>
              <Typography style={{ display: 'flex' }} variant="body2">
                    Rule
                {ruleEngineErrorMsg && (<span styleName="error-msg">{ruleEngineErrorMsg.errorMsg}</span>)}
              </Typography>
              <div styleName="input-container">
                <Typography variant="body2">Notes</Typography>
                <TextField
                  hiddenLabel
                  inputProps={{ maxLength: 1000 }}
                  multiline
                  name="notes"
                  onChange={handleChange}
                  rows="4"
                  size="small"
                  value={notes}
                  variant="filled"
                />
              </div>
              <div styleName="button-overrider-container">
                <Button color="primary" disabled={isDisabled} disableElevation onClick={handleSubmit} variant="contained">
                      OVERRIDE
                </Button>
              </div>
            </div>
          ) : (
            <div>
              <Typography styleName="confirmation-msg" variant="body2">
                {OVERRIDE_CONFIRMATION_DIALOG_MSG}
              </Typography>
              <div styleName="button-overrider-container">
                <Button
                  color="primary"
                  disableElevation
                  onClick={defaultState}
                  variant="contained"
                >
                      YES
                </Button>
                <Button color="primary" onClick={() => setOverrideData({ ...overrideData, isConfirmPopupVisible: false })} variant="text">
                      NO
                </Button>
              </div>
            </div>
          )}
        </div>
      </Modal>
    );
  };
  const { isErrorMsgVisible } = overrideData;
  return (
    <>
      {isErrorMsgVisible && (
        <>
          <Grid item styleName="error-msg-container" xs={12}>
            {ruleEngineErrorMsg && ruleEngineErrorMsg.errorMsg && <Typography component="span" variant="body2">{ruleEngineErrorMsg.errorMsg}</Typography>}
            {(ruleEngineErrorMsg && ruleEngineErrorMsg.overridable) && (
              <IconButton aria-label="Edit" component="span" onClick={() => setOverrideData({ ...overrideData, show: true })} styleName="btn-edit">
                <EditIcon />
              </IconButton>
            )}
          </Grid>
          {renderOverridePopup()}
        </>
      )}

    </>
  );
};

OverrideMessage.defaultProps = {
  saveOverriddenRule: () => { },
  ruleEngineErrorMsg: {
    errorMsg: null,
    overridable: false,
  },
};

OverrideMessage.propTypes = {
  ruleEngineErrorMsg: PropTypes.shape({
    errorMsg: PropTypes.string,
    overridable: PropTypes.bool,
    ruleId: PropTypes.number,
  }),
  saveOverriddenRule: PropTypes.func,
  selectedCaseId: PropTypes.func.isRequired,

};

const mapStateToProps = state => ({
  selectedCaseId: selectors.getSelectedCaseId(state),
  userGroupList: loginSelectors.getGroupList(state),
  ruleEngineErrorMsg: selectors.getRuleEngineErrorMsg(state),
});

const mapDispatchToProps = dispatch => ({
  saveOverriddenRule: operations.saveOverriddenRuleOperation(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(OverrideMessage);
