import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import {
  Grid, FormControl, MenuItem, OutlinedInput, Select, TextField, Button,
  InputAdornment, Divider,
} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { operations as repaymentEvalOperations, selectors as repaymentEvalSelectors } from 'ducks/repaymentEval';
import { operations as evalOperations, selectors as evalSelector } from 'ducks/eval';
import { operations as tombstoneOperations } from 'ducks/tombstone';
import { selectors as notificationSelectors, operations as notificationOperations } from 'ducks/notifications';
import { selectors as dashboardSelectors, operations } from 'ducks/dashboard';
import { selectors as widgetsSelectors } from 'ducks/widgets';
import LSAMSNotesWidget from 'components/Widgets/LSAMSNotesWidget';
import { selectors as loginSelectors } from 'ducks/login';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import MenuIcon from '@material-ui/icons/Menu';
import CustomSnackBar from 'components/CustomSnackBar';
import PopupContainer from 'components/PopupContainer';
import MUITable from 'components/MUITable';
import ConfirmationDialogSingleOption from 'components/ConfirmationDialogSingleOption';
import Loader from 'components/Loader/Loader';
import {
  REPAYMENT_CASEDETAILS_DEFAULT_TABS, WORKOUT_TYPE, DURATION, MONTHLY_REPAYMENT_AMOUNT, START_DATE,
  DELEGATION_INDICATOR, REPAYMENT_AMOUNT_REGEX, TRACK_PAYMENT,
  CASEDETAILS_DISABLED_WORKOUTTYPE, REPAYMENT_CASE_TYPE, OPEN,
  REJECTED, SENT_FOR_REJECT, TOTAL_DURATION_TABLE_COLUMNS,
  EXTEND_PLAN_CONFIRMATION_DIALOG_MSG, CASEDETAILS_EDITABLE_LOSS_MIT_GROUPS,
  CASEDETAILS_EDITABLE_CMOD_GROUPS, APPROVED, FORBEARANCE_WORKOUT_TYPE,
  CASEHEADER_EDIT_ONLY_GROUPS, CASEHEADER_EDIT_APPROVE_GROUPS, EXTEND_PLAN_BUTTON_GROUPS,
  REPAY_NONDELAPPROVALDATE_CASETYPE, NON_DEL_DATE_ERR_MSG1, NON_DEL_DATE_ERR_MSG2, DELEGATED,
  NON_DEL_DATE_ERR_MSG3,
  NON_DELEGATED,
  // REPAYMENT_ERROR_MSG,
} from '../../constants/eval';
import MapCustomTabs from '../../components/CustomTabs/MapCustomTabs';
import Eligibility from '../Eval/MODCaseDetails/Eligibility/Eligibility';
import './RepaymentCaseScreen.css';
import CaseDetails from './CaseDetails/CaseDetails';
import CalcLog from '../Eval/CalcLog/CalcLog';
import TrackPayment from './TrackPayment/TrackPayment';
import RejectReasonPopup from './RejectReasonPopup/RejectReasonPopup';
import LOAN_SEARCH_SCREEN from '../../constants/loanInfoComponents';
import SweetAlertBox from '../../components/SweetAlertBox';
import {
  LSAMS_NOTES,
} from '../../constants/widgets';


const mockdelegationIndicator = [{ label: 'DELEGATED', value: DELEGATED }, { label: 'NON-DELEGATED', value: NON_DELEGATED }];

const RepaymentCaseScreen = (props) => {
  const {
    caseStatus, getCaseHeaderInfo, caseHeaderInfo, setChecklistCenterPaneData,
    ruleCheckErrorMessage, snackBarData,
    closeSnackBar, lockCalculationOperation, enableCalc, isCalcButtonEnabled,
    sendForApprovalOperation, fetchRejectReasons, isCaseRejected, isExtendPlanApplicable,
    user, sendForApproval,
    workoutTypes, planDurations,
    planStartDates, repayPlanSchedules, monthlyPaymentDetails, calcRepayOperation,
    selectedRepaymentCaseId, paymentChangesOperation,
    inProgress, openWidgetList, fetchRepayDetails,
    isCalcLoading,
  } = props;

  const {
    resolutionId, caseType, status, subStatus, lockFlag, evalId, evalType, caseDate, createdBy,
    qualifyingDocReceivedDate, disasterFlag, sentForApprovalFlag, reslWorkoutType, resolutionTypeId,
  } = caseHeaderInfo || {};

  const [workoutType, setWorkoutType] = useState('');
  const [startdate, setStartDate] = useState('');
  const [duration, setDuration] = useState('');
  const [monthlyRepaymentAmount, setMonthlyRepaymentAmount] = useState('');
  const [delegationIndicator, setDelegationIndicator] = useState('');
  const [currTab, setCurrTab] = useState(0);
  const [tabsList, setTabsList] = useState(REPAYMENT_CASEDETAILS_DEFAULT_TABS);
  const [displayRepaymentDetails, setDisplayRepaymentDetails] = useState(false);
  const [openExtendPlanAlertBox, setOpenExtendPlanAlertBox] = useState(false);
  const [isSendForApprovalVisible, setSendForApprovalVisible] = useState(false);
  const [isApproveVisible, setApproveVisible] = useState(false);
  const [isSendForApprovalDisabled, setSendForApprovalDisabled] = useState(false);
  const [isApproveDisabled, setApproveDisabled] = useState(true);
  const [isLockVisible, setLockVisible] = useState(true);
  const [visible, setVisible] = useState(true);
  const [isConfirmationPopupOpen, setConfirmationPopupOpen] = useState(false);
  const CASE_TYPES = ['Disaster', 'Pandemic'];
  const [isCaseHeaderEditOnlyDisabled, setCaseHeaderEditOnlyDisabled] = useState(true);
  const [isCaseHeaderEditApproveDisabled, setCaseHeaderEditApproveDisabled] = useState(true);
  const [isExtendDisabled, setExtendDisabled] = useState(true);
  const [nonDelegationDate, setNonDelegationDate] = useState('');
  const [isNonDelDateDisabled, setNonDelDateDisabled] = useState(false);
  const [nonDelDateValidation, setNonDelDateValidation] = useState({
    isPopupVisible: false,
    popUpMessage: '',
  });
  const [workoutTypeDuration, setworkoutTypeDuration] = useState([]);
  const [workoutTypeStartDate, setworkoutTypeStartDate] = useState([]);
  const [isRejectPopupOpen, setIsRejectPopupOpen] = useState(false);
  const [isDisabled, setDisabled] = useState(false);
  const [isPlanExtended, setPlanExtended] = useState(false);
  const [isCaseDetailsEditable, setCaseDetailsEditable] = useState(false);
  const [isTrackPayEditable, setIsTrackPayEditable] = useState(false);

  const convertStringToList = (str) => {
    if (typeof str !== 'string') {
      throw new TypeError('The argument should be a string');
    }
    if (str.trim() === '') {
      return [];
    }
    return str.split(',').map(item => item.trim()).filter(item => item);
  };

  const updateStartDateDuration = (workoutTypeInput) => {
    setWorkoutType(workoutTypeInput);
    const startDates = planStartDates[workoutTypeInput];
    if (startDates) {
      const startDateList = convertStringToList(startDates);
      setworkoutTypeStartDate(startDateList);
    } else {
      setworkoutTypeStartDate([]);
    }
    if (workoutTypeInput === 'Disaster' && repayPlanSchedules.length > 0) {
      const startDateDurationsMap = repayPlanSchedules.reduce((acc, schedule) => {
        acc[schedule.startDate] = schedule.durations
          .map(disasterDuration => disasterDuration.toString());
        return acc;
      }, {});
      const getDurationsByStartDate = startDate => startDateDurationsMap[startDate] || [];
      const durations = getDurationsByStartDate(startdate);
      if (durations) {
        setworkoutTypeDuration(durations);
      } else {
        setworkoutTypeDuration([]);
      }
    } else if (workoutTypeInput === 'Special') {
      setworkoutTypeDuration([]);
    } else {
      const durations = planDurations[workoutTypeInput];
      if (durations) {
        const durationList = convertStringToList(durations);
        setworkoutTypeDuration(durationList);
      } else {
        setworkoutTypeDuration([]);
      }
    }
  };

  const updateForm = (paymentDetails) => {
    // const caseDetails = new Map();
    if (paymentDetails) {
      // paymentDetails.forEach((item) => {
      //   caseDetails.set(item.columnName, item.columnVal);
      // });
      const workoutTypeVal = paymentDetails.workoutType && paymentDetails.workoutType.columnVal ? paymentDetails.workoutType.columnVal : '';
      setWorkoutType(workoutTypeVal);
      setDelegationIndicator(paymentDetails.delegationIndicator ? paymentDetails.delegationIndicator.columnVal : '');
      setMonthlyRepaymentAmount(paymentDetails.repaymentMonthlyAmount ? paymentDetails.repaymentMonthlyAmount.columnVal : '');
      setDuration(paymentDetails.duration ? paymentDetails.duration.columnVal : '');
      setStartDate(paymentDetails.startDate ? paymentDetails.startDate.columnVal : '');
      setNonDelegationDate(paymentDetails.nonDelApprovalDate ? paymentDetails.nonDelApprovalDate.columnVal : '');

      if (workoutTypeVal && workoutTypeVal !== '') {
        updateStartDateDuration(workoutTypeVal);
      }
    }
  };

  useEffect(() => {
    if (selectedRepaymentCaseId && ((R.isEmpty(caseHeaderInfo) || R.isNil(caseHeaderInfo))
      || (resolutionId !== selectedRepaymentCaseId))) {
      fetchRepayDetails(selectedRepaymentCaseId);
    }
  }, [selectedRepaymentCaseId]);

  useEffect(() => {
    const groups = user && user.groupList ? user.groupList : [];
    const isHeaderEditOnly = groups.some(group => CASEHEADER_EDIT_ONLY_GROUPS.includes(group));
    setCaseHeaderEditOnlyDisabled(!isHeaderEditOnly);
    const isHeaderEditApprove = groups
      .some(group => CASEHEADER_EDIT_APPROVE_GROUPS.includes(group));
    setCaseHeaderEditApproveDisabled(!isHeaderEditApprove);
    const isExtendable = groups
      .some(group => EXTEND_PLAN_BUTTON_GROUPS.includes(group));
    setExtendDisabled(!isExtendable);
  }, [user]);

  useEffect(() => {
    updateForm(monthlyPaymentDetails);
  }, [monthlyPaymentDetails]);
  const caseSts = status !== 'Open';
  const isCaseLocked = lockFlag !== 0 && caseSts;
  useEffect(() => {
    const isRepaymentCaseType = caseType === REPAYMENT_CASE_TYPE;
    const isEvalTypeInCaseTypes = CASE_TYPES.includes(evalType) && evalId === 0;
    const isDisabledWorkoutType = caseType
      && CASEDETAILS_DISABLED_WORKOUTTYPE.includes(R.toLower(caseType));

    if (lockFlag === 1) {
      if ((isRepaymentCaseType && isEvalTypeInCaseTypes)) {
        setSendForApprovalVisible(true);
        setApproveVisible(true);
        setVisible(false);
        setLockVisible(false);
        setSendForApprovalDisabled(false);
        setApproveDisabled(true);
      } else if (isDisabledWorkoutType) {
        setSendForApprovalVisible(false);
        setApproveVisible(false);
        setVisible(false);
        setLockVisible(false);
      } else if (status === REJECTED) {
        setLockVisible(false);
      }
    }

    if (sentForApprovalFlag === 1) {
      setApproveVisible(true);
      setSendForApprovalVisible(true);
      setApproveDisabled(false);
      setSendForApprovalDisabled(true);
    }

    if (reslWorkoutType === FORBEARANCE_WORKOUT_TYPE && status === APPROVED) {
      setSendForApprovalVisible(false);
      setApproveVisible(false);
    }
  }, [caseType, lockFlag, evalType, sentForApprovalFlag, reslWorkoutType, status]);

  useEffect(() => {
    if (delegationIndicator === NON_DELEGATED) {
      setNonDelDateDisabled(false);
    } else {
      setNonDelDateDisabled(true);
    }
  }, [delegationIndicator]);

  useEffect(() => {
    const userGroups = user && user.groupList;

    if (status === APPROVED) {
      if (!tabsList.includes(TRACK_PAYMENT)) {
        setTabsList(prevState => [...prevState, TRACK_PAYMENT]);
      }
    }

    if (!isCaseRejected) {
      if (status === REJECTED) {
        setDisabled(true);
        setDisplayRepaymentDetails(true);
        // fetchMonthlyPaymentDetails(resolutionId);
      } else if (((evalId !== 0 && evalId !== null) && (qualifyingDocReceivedDate === null))
        || (status !== REJECTED && subStatus === SENT_FOR_REJECT
        && qualifyingDocReceivedDate !== null && ['E', 'B', 'PE', 'PB'].includes(disasterFlag)
        )
      ) {
        setDisabled(false);
      } else if (qualifyingDocReceivedDate === null || status !== REJECTED) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    }

    if (!R.isEmpty(caseHeaderInfo)) {
      if ((evalId === 0
        && CASEDETAILS_EDITABLE_LOSS_MIT_GROUPS.some(group => userGroups.includes(group)))
        || (!R.isNil(disasterFlag)
        && (CASEDETAILS_EDITABLE_LOSS_MIT_GROUPS.some(group => userGroups.includes(group))
        || CASEDETAILS_EDITABLE_CMOD_GROUPS.some(group => userGroups.includes(group))))
        || (R.isNil(disasterFlag)
        && CASEDETAILS_EDITABLE_CMOD_GROUPS.some(group => userGroups.includes(group)))) {
        setCaseDetailsEditable(true);
      }
    }

    const requiredRoles = ['lossmitigation', 'lossmitigation-mgr'];
    const hasRequiredRole = userGroups.some(role => requiredRoles.includes(role));
    setIsTrackPayEditable(hasRequiredRole);
  }, [caseStatus, caseHeaderInfo, user, isCaseRejected]);

  useEffect(() => {
    if ((startdate !== null && startdate !== '')) {
      updateStartDateDuration(workoutType);
    }
  }, [workoutType, startdate]);

  const handleNonDelPopupClose = () => {
    setNonDelDateValidation(prev => ({ ...prev, isPopupVisible: false }));
  };

  const checkNonDelegationDate = () => {
    let valid = true;
    if (!nonDelegationDate || nonDelegationDate.length === 0 || nonDelegationDate.trim() === '') {
      valid = false;
      setNonDelDateValidation(prev => ({
        ...prev,
        isPopupVisible: true,
        popUpMessage: NON_DEL_DATE_ERR_MSG1,
      }));
    } else {
      const delDate = new Date(nonDelegationDate);
      if (delDate > new Date()) {
        valid = false;
        setNonDelDateValidation(prev => ({
          ...prev,
          isPopupVisible: true,
          popUpMessage: NON_DEL_DATE_ERR_MSG3,
        }));

        return false;
      } if (delDate < new Date('01/01/2020')) {
        valid = false;
        setNonDelDateValidation(prev => ({
          ...prev,
          isPopupVisible: true,
          popUpMessage: NON_DEL_DATE_ERR_MSG2,
        }));
      }
    }
    return valid;
  };


  const handleApprove = () => {
    sendForApproval({ caseId: resolutionId });
  };

  const handleConfirmationResponse = (isConfirmed) => {
    setConfirmationPopupOpen(false);
    if (isConfirmed) {
      handleApprove();
      setChecklistCenterPaneData(LOAN_SEARCH_SCREEN);
    }
  };

  const renderSnackBar = () => (
    <CustomSnackBar
      message={snackBarData && snackBarData.message}
      onClose={closeSnackBar}
      open={snackBarData && snackBarData.open}
      type={snackBarData && snackBarData.type}
    />
  );

  const handleReject = () => {
    fetchRejectReasons({ resolutionTypeId, status });
    setIsRejectPopupOpen(true);
  };

  const handleSendForApproval = () => {
    sendForApprovalOperation({ caseId: resolutionId });
    getCaseHeaderInfo({ caseId: resolutionId });
  };

  const handleClose = () => {
    setIsRejectPopupOpen(false);
  };

  const handleRejectRepayScreen = () => {
    setIsRejectPopupOpen(false);
    setDisabled(true);
    setVisible(prev => !prev);
    setLockVisible(false);
  };

  const handleConfirmationDialogBoxForExtendPLan = (isConfirmed) => {
    setOpenExtendPlanAlertBox(false);
    if (R.equals(isConfirmed, true)) {
      setPlanExtended(true);
    }
  };

  const navigateTab = () => {
    switch (true) {
      case currTab === 0:
        return (
          <CaseDetails
            caseType={caseType}
            disasterFlag={disasterFlag}
            displayRepaymentDetails={displayRepaymentDetails}
            handleCalcButton={() => {
              enableCalc(true);
            }}
            isCaseDetailsEditable={isCaseDetailsEditable
              && !isCaseLocked && displayRepaymentDetails}
            isPlanExtended={isPlanExtended}
            status={status}
          />
        );
      case currTab === 1:
        return <Eligibility editable={!isCaseLocked} isRepayCase status={status} />;
      case currTab === 2:
        return <CalcLog isRepayCase />;
      case currTab === 3 && status === APPROVED:
        return (
          <TrackPayment
            caseStatus={status}
            isTrackPayEditable={isTrackPayEditable}
            resolutionId={resolutionId}
          />
        );
      default:
        return (
          <Eligibility editable={!isCaseLocked} isRepayCase />
        );
    }
  };
  const handleDropDownData = (event, dropDownName) => {
    const newValue = event.target.value;
    switch (dropDownName) {
      case WORKOUT_TYPE:
        setWorkoutType(newValue);
        break;
      case START_DATE:
        setStartDate(newValue);
        break;
      case DURATION:
        setDuration(newValue);
        break;
      case DELEGATION_INDICATOR:
        if (newValue === DELEGATED) {
          setNonDelegationDate('');
        }
        setDelegationIndicator(newValue);
        break;
      default:
        break;
    }
  };


  const handleTextBox = (event) => {
    const isEmpty = R.isEmpty(R.trim(event.target.value));
    const isValidValue = REPAYMENT_AMOUNT_REGEX.test(event.target.value);
    if (!isEmpty && isValidValue) {
      setMonthlyRepaymentAmount(event.target.value);
    }
  };

  const [isTotalDurationDialogOpen, toggleTotalDurationDialog] = useState(false);

  const renderTotalDurationPopOver = () => (isTotalDurationDialogOpen ? (
    <PopupContainer
      handleClose={value => toggleTotalDurationDialog(value)}
      show={isTotalDurationDialogOpen}
      title="Total Duration"
    >
      <div styleName="table-container">
        <MUITable
          columns={TOTAL_DURATION_TABLE_COLUMNS}
          data={[]}
        />
      </div>
    </PopupContainer>
  ) : null);

  const renderCaseDetailsFields = () => {
    const isDisasterFlagMatch = ['E', 'B', 'PE', 'PB'].includes(disasterFlag);
    const isHeaderEnabledPandemic = lockFlag !== 0 && caseSts
    && isDisasterFlagMatch && isExtendPlanApplicable;

    return (
      <Grid alignItems="center" container direction="row" item styleName="dropDown" xs={7}>
        {Object.keys(caseHeaderInfo).length > 0
          && !CASEDETAILS_DISABLED_WORKOUTTYPE.includes(R.toLower(caseType)) && (
            <Grid item>
              <div styleName="repayment-dropDown-title">
                <div>{WORKOUT_TYPE}</div>
                <FormControl
                  className="repayment-dropDown-select"
                  disabled={isCaseLocked || caseSts || isCaseRejected
                    || (isCaseHeaderEditOnlyDisabled && isCaseHeaderEditApproveDisabled)}
                  fullWidth
                  variant="outlined"
                >
                  <Select
                    id="workoutTypeDropdown"
                    input={<OutlinedInput name="selectedWorkout" />}
                    label="idcategory"
                    onChange={e => handleDropDownData(e, WORKOUT_TYPE)}
                    styleName="repayment-dropDown-select"
                    value={workoutType}
                  >
                    {workoutTypes.map(item => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </Grid>
        )}
        <Grid item>
          <div styleName="repayment-dropDown-title">
            <div>{START_DATE}</div>
            <FormControl
              disabled={isCaseLocked || caseSts || isCaseRejected
                || (isCaseHeaderEditOnlyDisabled && isCaseHeaderEditApproveDisabled)}
              fullWidth
              variant="outlined"
            >
              <Select
                id="startDateDropdown"
                input={<OutlinedInput name="selectedStartDate" />}
                label="idcategory"
                onChange={e => handleDropDownData(e, START_DATE)}
                styleName="repayment-dropDown-select"
                value={startdate}
              >
                {workoutTypeStartDate && workoutTypeStartDate.length > 0
                  && workoutTypeStartDate.map(item => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
        </Grid>
        <Grid item>
          <div styleName="repayment-dropDown-title">
            <div>
              {DURATION}
              {isPlanExtended && (
                <MenuIcon
                  onClick={() => { toggleTotalDurationDialog(true); }}
                  styleName="durationMenu"
                />
              )}
            </div>
            <FormControl
  // enabling duration and repayment plan
  // when case is eligible for extension and case is pandmic/disaster
              disabled={isCaseRejected || (isCaseLocked && !isHeaderEnabledPandemic)
                || isPlanExtended
                || (isCaseHeaderEditOnlyDisabled && isCaseHeaderEditApproveDisabled)}
              fullWidth
              variant="outlined"
            >
              <Select
                id="durationDropdown"
                input={<OutlinedInput name="selectedDuration" />}
                label="idcategory"
                onChange={e => handleDropDownData(e, DURATION)}
                styleName="repayment-dropDown-select"
                value={duration > 0 ? duration : ''}
              >
                {workoutTypeDuration && workoutTypeDuration.length > 0
                && workoutTypeDuration.map(item => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </Grid>
        <Grid item>
          <div styleName="repayment-dropDown-title">
            <div>{MONTHLY_REPAYMENT_AMOUNT}</div>
            <TextField
              disabled
              id="monthlyRepaymentAmount"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    $
                  </InputAdornment>
                ),
                style: { height: '2rem', width: '11rem', marginTop: '0.5rem' },
              }}
              onChange={e => handleTextBox(e)}
              value={monthlyRepaymentAmount}
              variant="outlined"
            />
          </div>
        </Grid>
        <Grid item>
          <div styleName="repayment-dropDown-title">
            <div>{DELEGATION_INDICATOR}</div>
            <FormControl
              className="repayment-dropDown-select"
              disabled={isCaseLocked || caseSts || isCaseRejected
                || (isCaseHeaderEditOnlyDisabled && isCaseHeaderEditApproveDisabled)}
              fullWidth
              variant="outlined"
            >
              <Select
                id="workoutTypeDropdown"
                input={<OutlinedInput name="selectedWorkout" />}
                label="idcategory"
                onChange={e => handleDropDownData(e, DELEGATION_INDICATOR)}
                styleName="repayment-dropDown-select"
                value={delegationIndicator}
              >
                {mockdelegationIndicator && mockdelegationIndicator.map(item => (
                  <MenuItem key={item.label} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </Grid>
        {REPAY_NONDELAPPROVALDATE_CASETYPE.includes(caseType) && status === OPEN && (
        <Grid item>
          <div styleName="repayment-dropDown-title">
            <div>Non-Del Approved Date</div>
            <TextField
              disabled={isNonDelDateDisabled}
              id="date"
              InputProps={{
                style: { height: '2rem', width: '11rem', marginTop: '0.5rem' },
              }}
              onChange={e => setNonDelegationDate(e.target.value)}
              type="Date"
              value={nonDelegationDate}
              variant="outlined"
            />
          </div>
        </Grid>
        )}
        <Grid item>
          <div styleName="extend-plan-btn">
            {isExtendPlanApplicable && !isPlanExtended && (
              <Button
                color="primary"
                disabled={isCaseLocked || isExtendDisabled}
                onClick={() => { setOpenExtendPlanAlertBox(true); }}
                variant="outlined"
              >
                EXTEND PLAN
              </Button>
            )}
          </div>
        </Grid>
      </Grid>
    );
  };


  const renderCaseStatusDetails = () => (
    <Grid alignItems="center" container direction="row" item justifyContent="space-evenly" spacing={2}>
      <Grid item>
        <div>
          Creator:
          <b>{createdBy}</b>
        </div>
      </Grid>
      <Grid item>
        <div>
          Case Date:
          <b>{caseDate}</b>
        </div>
      </Grid>
      <Grid item>
        <div>
          Case ID:
          <b>{resolutionId}</b>
        </div>
      </Grid>
      <Grid item>
        <div>
          Status:
          <b>{status}</b>
        </div>
      </Grid>
      <Grid item>
        <div>
          Sub-Status:
          <b>{subStatus}</b>
        </div>
      </Grid>
    </Grid>
  );

  const handleBack = () => {
    const {
      setBeginSearch, onSearchLoan, loanNumber, clearRepaymentData,
    } = props;
    clearRepaymentData();
    setBeginSearch();
    onSearchLoan(loanNumber);
    setChecklistCenterPaneData(LOAN_SEARCH_SCREEN);
  };

  const renderButtons = () => (
    <Grid alignItems="center" container direction="row-reverse" item justifyContent="space-around" spacing={2}>
      <Grid item>
        <Button
          onClick={() => { handleBack(); }}
          size="large"
          startIcon={<ArrowBackIosIcon />}
        >
          BACK
        </Button>
      </Grid>
      <Grid item>
        {displayRepaymentDetails ? (
          isLockVisible && (
            <Button
              color="primary"
              disabled={(lockFlag ? true : isCalcButtonEnabled)
            || (isCaseHeaderEditOnlyDisabled && isCaseHeaderEditApproveDisabled)
            || isCalcLoading}
              onClick={() => {
                const payload = {
                  workoutType,
                  startDate: startdate,
                  duration,
                  delegationIndicator,
                  nonDelApprovalDate: nonDelegationDate,
                  repaymentMonthlyAmount: monthlyRepaymentAmount,
                  source: 'lock',
                };
                if (REPAY_NONDELAPPROVALDATE_CASETYPE.includes(caseType)
                && status === OPEN && delegationIndicator === NON_DELEGATED) {
                  const valid = checkNonDelegationDate();
                  if (valid) {
                    lockCalculationOperation(payload);
                  }
                } else {
                  lockCalculationOperation(payload);
                }
              }}
              variant="contained"
            >
            LOCK
            </Button>
          )
        ) : (
          <Button color="primary" disabled={isDisabled || isCaseHeaderEditApproveDisabled} onClick={handleReject} variant="contained">
            REJECT
          </Button>
        )
        }
      </Grid>
      {visible && status !== REJECTED && (
        <Grid item>
          <Button
            color="primary"
            disabled={!status === OPEN || isCalcLoading
              || (isCaseHeaderEditOnlyDisabled && isCaseHeaderEditApproveDisabled)}
            onClick={() => {
              const payload = {
                workoutType,
                startDate: startdate,
                duration,
                delegationIndicator,
                nonDelApprovalDate: nonDelegationDate,
                repaymentMonthlyAmount: monthlyRepaymentAmount,
              };
              if (REPAY_NONDELAPPROVALDATE_CASETYPE.includes(caseType)
                && status === OPEN && delegationIndicator === NON_DELEGATED) {
                const valid = checkNonDelegationDate();
                if (valid) {
                  setDisplayRepaymentDetails(true);
                  calcRepayOperation(payload);
                  paymentChangesOperation(resolutionId);
                }
              } else {
                setDisplayRepaymentDetails(true);
                calcRepayOperation(payload);
                paymentChangesOperation(resolutionId);
              }
            }}
            style={{ borderWidth: '2px' }}
            variant="outlined"
          >
            CALCULATE
          </Button>
        </Grid>
      )
      }
      {displayRepaymentDetails && (
        <Grid item>
          <Button color="primary" disabled={isDisabled || isCaseHeaderEditApproveDisabled || isCalcLoading} onClick={handleReject} variant="text">
            REJECT
          </Button>
        </Grid>
      )
      }
      {lockFlag === 1 && (
        <>
          {isSendForApprovalVisible && (
            <Grid item>
              <Button color="primary" disabled={isSendForApprovalDisabled || (isCaseHeaderEditOnlyDisabled && isCaseHeaderEditApproveDisabled)} onClick={handleSendForApproval} variant="contained">
                SEND FOR APPROVAL
              </Button>
            </Grid>
          )}
          {isApproveVisible && (
            <Grid item>
              <Button color="primary" disabled={isApproveDisabled || isCaseHeaderEditApproveDisabled} onClick={() => setConfirmationPopupOpen(true)} style={{ borderWidth: '2px' }} variant="outlined">
                APPROVE
              </Button>
            </Grid>
          )}
          <ConfirmationDialogSingleOption
            isOpen={isConfirmationPopupOpen}
            message="Are you sure you want to approve the repayment plan case?"
            onClose={handleConfirmationResponse}
            option="Ok"
            title="Confirmation"
          />
        </>
      )
      }
    </Grid>
  );

  return (
    <>
      { inProgress ? <Loader message="Please Wait" size={40} /> : (
        <>
          {R.contains(LSAMS_NOTES, openWidgetList) && <LSAMSNotesWidget />}
          <>
            <div style={{ display: R.contains(LSAMS_NOTES, openWidgetList) ? 'none' : '' }}>
              <Grid container>
                {renderCaseDetailsFields()}
                <Grid
                  alignItems="flex-end"
                  container
                  direction="column"
                  item
                  justifyContent="space-evenly"
                  styleName="dropDown"
                  xs={5}
                >
                  <Grid item>
                    {renderCaseStatusDetails()}
                  </Grid>
                  <Grid item styleName="btns">
                    {renderButtons()}
                  </Grid>
                  {
            isRejectPopupOpen
            && (
              <RejectReasonPopup
                caseId={resolutionId}
                handleClose={handleClose}
                handleRejectRepayScreen={handleRejectRepayScreen}
                isOpen={isRejectPopupOpen}
              />
            )
          }
                </Grid>
              </Grid>
              {!R.isEmpty(ruleCheckErrorMessage) && (
              <div styleName="errorMessage">
                <ErrorOutlineIcon styleName="errorIcon" />
                <span>{ruleCheckErrorMessage}</span>
              </div>
              )
      }
              <SweetAlertBox
                message={nonDelDateValidation.popUpMessage}
                onConfirm={handleNonDelPopupClose}
                show={nonDelDateValidation.isPopupVisible}
                type="Failed"
              />
              <Divider style={{ height: '1px', margin: '1rem 0rem 1.5rem 0' }} />
              { isCalcLoading ? <Loader message="Please Wait" size={40} /> : (
                <div styleName={!displayRepaymentDetails ? 'blurred' : ''}>
                  {displayRepaymentDetails ? (
                    <MapCustomTabs
                      className="tabs"
                      currTab={currTab}
                      navigateTab={navigateTab}
                      position="relative"
                      setCurrTab={setCurrTab}
                      tabsArray={tabsList}
                    />
                  ) : (
                    <MapCustomTabs
                      className="tabs"
                      currTab={currTab}
                      disabled
                      navigateTab={navigateTab}
                      position="relative"
                      setCurrTab={setCurrTab}
                      tabsArray={REPAYMENT_CASEDETAILS_DEFAULT_TABS}
                    />
                  )}
                </div>
              )
              }
              {renderSnackBar()}
              {renderTotalDurationPopOver()}
              <ConfirmationDialogSingleOption
                isOpen={openExtendPlanAlertBox}
                message=""
                onClose={isConfirmed => handleConfirmationDialogBoxForExtendPLan(isConfirmed)}
                option="Ok"
                title={EXTEND_PLAN_CONFIRMATION_DIALOG_MSG}
              />
            </div>
          </>
        </>
      )}
    </>
  );
};

// add pop up if any validation failed
RepaymentCaseScreen.defaultProps = {
  //   openSweetAlert: () => { },
  caseStatus: 'Completed',
  snackBarData: null,
  closeSnackBar: () => { },
  isCalcButtonEnabled: false,
  isExtendPlanApplicable: false,
  fetchRepayDetails: () => { },
  planDurations: {},
  planStartDates: {},
  repayPlanSchedules: {},
  monthlyPaymentDetails: {},
  openWidgetList: [],
  isCalcLoading: false,
};
RepaymentCaseScreen.propTypes = {
  // openSweetAlert: PropTypes.func,
  calcRepayOperation: PropTypes.func.isRequired,
  caseHeaderInfo: PropTypes.shape({}).isRequired,
  caseStatus: PropTypes.string,
  clearRepaymentData: PropTypes.func.isRequired,
  closeSnackBar: PropTypes.func,
  enableCalc: PropTypes.func.isRequired,
  // fetchMonthlyPaymentDetails: PropTypes.func.isRequired,
  fetchRejectReasons: PropTypes.func.isRequired,
  fetchRepayDetails: PropTypes.func,
  // fetchUniversalActionData: PropTypes.func,
  getCaseHeaderInfo: PropTypes.func.isRequired,
  inProgress: PropTypes.bool.isRequired,
  isCalcButtonEnabled: PropTypes.bool,
  isCalcLoading: PropTypes.bool,
  isCaseRejected: PropTypes.bool.isRequired,
  isExtendPlanApplicable: PropTypes.bool,
  loanNumber: PropTypes.number.isRequired,
  lockCalculationOperation: PropTypes.func.isRequired,
  monthlyPaymentDetails: PropTypes.objectOf(
    PropTypes.arrayOf(PropTypes.string),
  ),
  onSearchLoan: PropTypes.func.isRequired,
  openWidgetList: PropTypes.arrayOf(PropTypes.string),
  paymentChangesOperation: PropTypes.func.isRequired,
  planDurations: PropTypes.objectOf(PropTypes.string),
  planStartDates: PropTypes.objectOf(PropTypes.string),
  repayPlanSchedules: PropTypes.objectOf(
    PropTypes.arrayOf(PropTypes.string),
  ),
  ruleCheckErrorMessage: PropTypes.string.isRequired,
  selectedRepaymentCaseId: PropTypes.number.isRequired,
  sendForApproval: PropTypes.func.isRequired,
  sendForApprovalOperation: PropTypes.func.isRequired,
  setBeginSearch: PropTypes.func.isRequired,
  setChecklistCenterPaneData: PropTypes.func.isRequired,
  snackBarData: PropTypes.shape(),
  user: PropTypes.shape({
    groupList: PropTypes.array,
    userDetails: PropTypes.shape({
      email: PropTypes.string,
      jobTitle: PropTypes.string,
      name: PropTypes.string,
    }),
    userGroups: PropTypes.array,
  }).isRequired,
  workoutTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
};
const mapDispatchToProps = dispatch => ({
  openSweetAlert: operations.openSweetAlert(dispatch),
  getCaseHeaderInfo: evalOperations.getCaseHeaderInfo(dispatch),
  // fetchMonthlyPaymentDetails: repaymentEvalOperations.fetchMonthlyPaymentDetails(dispatch),
  setChecklistCenterPaneData: tombstoneOperations.setChecklistCenterPaneDataOperation(dispatch),
  closeSnackBar: notificationOperations.closeSnackBar(dispatch),
  lockCalculationOperation: repaymentEvalOperations.lockCalculationOperation(dispatch),
  enableCalc: repaymentEvalOperations.enableCalc(dispatch),
  sendForApproval: repaymentEvalOperations.sendForApproval(dispatch),
  sendForApprovalOperation: repaymentEvalOperations.sendForApprovalOperation(dispatch),
  fetchRejectReasons: repaymentEvalOperations.fetchResolutionRejectReasonData(dispatch),
  fetchUniversalActionData: evalOperations.fetchUniversalActionData(dispatch),
  calcRepayOperation: repaymentEvalOperations.calcRepayOperation(dispatch),
  setBeginSearch: operations.setBeginSearch(dispatch),
  onSearchLoan: operations.onSearchLoan(dispatch),
  paymentChangesOperation: evalOperations.paymentChangesOperation(dispatch),
  fetchRepayDetails: evalOperations.fetchRepayDetailsOperation(dispatch),
  clearRepaymentData: repaymentEvalOperations.clearDataOperation(dispatch),

});

const mapStateToProps = state => ({
  loanNumber: dashboardSelectors.loanNumber(state),
  // selectedCaseId: evalSelector.getSelectedCaseId(state),
  caseHeaderInfo: evalSelector.caseHeaderInfoData(state),
  ruleCheckErrorMessage: repaymentEvalSelectors.getRuleCheckErrorMessage(state),
  snackBarData: notificationSelectors.getSnackBarState(state),
  loanType: dashboardSelectors.getLoanType(state),
  isCaseRejected: repaymentEvalSelectors.isCaseRejected(state),
  user: loginSelectors.getUser(state),
  universalActionResp: repaymentEvalSelectors.universalActionResp(state),
  workoutTypes: repaymentEvalSelectors.workoutTypes(state),
  planDurations: repaymentEvalSelectors.planDurations(state),
  planStartDates: repaymentEvalSelectors.planStartDates(state),
  repayPlanSchedules: repaymentEvalSelectors.repayPlanSchedules(state),
  // universalFieldValues: evalSelector.generalInformation(state),
  selectedRepaymentCaseId: dashboardSelectors.getRepaymentCaseId(state),
  inProgress: repaymentEvalSelectors.fetchRepayDataLoading(state),
  openWidgetList: widgetsSelectors.getOpenWidgetList(state),
  monthlyPaymentDetails: repaymentEvalSelectors.getMonthlyPaymentDetails(state),
  isCalcLoading: repaymentEvalSelectors.calcLoading(state),
});


export default connect(mapStateToProps, mapDispatchToProps)(RepaymentCaseScreen);
