import * as R from 'ramda';

const showDialog = state => R.pathOr(false, ['accountService', 'showDialog'], state);
const loanNumber = state => R.pathOr(0, ['accountService', 'loanId'], state);
const evalId = state => R.pathOr(0, ['accountService', 'evalId'], state);
const taskId = state => R.pathOr(0, ['accountService', 'taskId'], state);
const getResolutionChoiceType = state => R.pathOr('', ['accountService', 'resolutionChoiceType'], state);
const getEscrowData = state => R.pathOr({}, ['accountService', 'escrowData'], state);
const getAccountServiceEventType = state => R.pathOr('', ['accountService', 'eventType'], state);
const escrowDataInitValue = state => R.pathOr({}, ['accountService', 'accInitValues'], state);
const showConfirmationDialog = state => R.pathOr(false, ['accountService', 'showConfirmationDialog'], state);
const getAnalysisBreakdownData = state => R.pathOr({}, ['accountService', 'analysisBreakdownData'], state);
const getHistoryData = state => R.pathOr([], ['accountService', 'historyData'], state);
const getCapModHistoryData = state => R.pathOr({}, ['accountService', 'capModHistory'], state);
const isHistoryView = state => R.pathOr(false, ['accountService', 'isHistoryView'], state);
const getSelectedCapModId = state => R.pathOr(0, ['accountService', 'capModId'], state);
const isAnalysisScreenOpen = state => R.pathOr(false, ['accountService', 'isAnalysisScreenOpen'], state);
const isAccountServiceScreenVisible = state => R.pathOr(false, ['accountService', 'isAccountServiceScreenVisible'], state);
const hasValueChange = state => R.pathOr(false, ['accountService', 'hasValueChange'], state);
const isRejectBtnClicked = state => R.pathOr(false, ['accountService', 'isRejectBtnClicked'], state);
const getSelectedCapModIdHistory = state => R.pathOr(false, ['accountService', 'capModIdHistory'], state);
const showLoader = state => R.pathOr(false, ['accountService', 'showLoader'], state);
const showLoaderInPopup = state => R.pathOr(false, ['accountService', 'showLoaderInPopup'], state);
const getPromptError = state => R.pathOr({ message: '', type: '' }, ['accountService', 'promptError'], state);
const isRequestSubmitted = state => R.pathOr(false, ['accountService', 'isRequestSubmitted'], state);
const isApproved = state => R.pathOr(false, ['accountService', 'isApproved'], state);
const isRejectSuccessful = state => R.pathOr(false, ['accountService', 'isRejectSuccessful'], state);
const isNewAdhocRqst = state => R.pathOr(false, ['accountService', 'isNewAdhocRqst'], state);
const isNARqst = state => R.pathOr(false, ['accountService', 'isNARqst'], state);
const toCreateNewRqst = state => R.pathOr(false, ['accountService', 'toCreateNewRqst'], state);

const selectors = {
  isApproved,
  isRequestSubmitted,
  getPromptError,
  isAnalysisScreenOpen,
  getCapModHistoryData,
  getHistoryData,
  showDialog,
  loanNumber,
  evalId,
  taskId,
  getResolutionChoiceType,
  getEscrowData,
  getAccountServiceEventType,
  escrowDataInitValue,
  showConfirmationDialog,
  getAnalysisBreakdownData,
  isHistoryView,
  getSelectedCapModId,
  isAccountServiceScreenVisible,
  hasValueChange,
  isRejectBtnClicked,
  getSelectedCapModIdHistory,
  showLoader,
  isRejectSuccessful,
  isNewAdhocRqst,
  showLoaderInPopup,
  isNARqst,
  toCreateNewRqst,
};

export default selectors;
