import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Pagination from '@material-ui/lab/Pagination';
import * as R from 'ramda';
import './SupportAdminTable.css';
import { DEFAULT_ROWS_PER_PAGE } from 'constants/SupportAdmin';

const useStyles = makeStyles(() => ({
  root: {
    minHeight: 'auto',
  },
  pagination: {
    marginLeft: '15rem',
    paddingTop: '1rem',
  },
  pgnWithMorePages: {
    marginLeft: '10rem',
    paddingTop: '1rem',
  },
}));

function TableHeader(props) {
  const {
    columns,
  } = props;

  return (
    <TableHead>
      <TableRow>
        {columns.map(headCell => (
          <TableCell
            key={headCell.name}
            align={headCell.align}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

TableHeader.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    align: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    options: PropTypes.object,
  })).isRequired,
};

function SupportAdminTable(props) {
  const { columns, data, handleChange } = props;
  const classes = useStyles();
  const processData = () => data && data.records.map((item) => {
    let newObject = {};
    columns.forEach(
      (obj) => {
        newObject = R.assoc(
          obj.name,
          obj.cellFormat ? obj.cellFormat(item[obj.name]) : item[obj.name],
          newObject,
        );
        return newObject;
      },
    );
    return newObject;
  });

  const [currentTableFilter, setCurrentTableFilter] = React.useState({
    initialData: processData(),
  });

  useEffect(() => {
    const newState = {};
    setCurrentTableFilter({
      ...newState,
      ...currentTableFilter,
    });
  }, []);


  const handleChangePage = (event, newPage) => {
    handleChange(newPage - 1, R.propOr(DEFAULT_ROWS_PER_PAGE, 'pageSize', data));
  };

  const totalRecords = R.propOr(0, 'totalRecords', data);

  return (
    <div styleName="table-container">
      <TableContainer>
        <Table
          aria-labelledby="tableTitle"
          size="small"
          sx={{ minWidth: 750 }}
        >
          <TableHeader
            columns={columns}
          />
          <TableBody>
            {currentTableFilter
              ? currentTableFilter.initialData.map(row => (
                <TableRow
                  key={row.name}
                  hover
                  sx={{ cursor: 'pointer' }}
                  tabIndex={-1}
                >
                  {Object.entries(row).map(([key, value]) => (
                    <TableCell key={key} align="left" id={`${key}_${value}`} style={{ textOverflow: 'ellipsis' }}>
                      {value || ''}
                    </TableCell>
                  ))}
                </TableRow>
              ))
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      <div>
        <Pagination
          className={totalRecords < 50 ? classes.pagination : classes.pgnWithMorePages}
          color="primary"
          count={Math.ceil(R.propOr(0, 'totalRecords', data) / R.propOr(DEFAULT_ROWS_PER_PAGE, 'pageSize', data))}
          onChange={handleChangePage}
          page={R.propOr(0, 'page', data) + 1}
          shape="rounded"
          showFirstButton
          showLastButton
        />
      </div>
    </div>
  );
}
SupportAdminTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    align: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    options: PropTypes.object,
  })).isRequired,
  data: PropTypes.shape({
    records: PropTypes.arrayOf(PropTypes.shape).isRequired,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
};
export default SupportAdminTable;
