import React from 'react';
import * as R from 'ramda';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import './RepaymentSummary.css';
import { convertToUSCurrency } from 'lib/Formatters';

const RepaymentSummary = ({ monthlyPaymentDetails }) => (
  <Grid id="repaymentSummary" item xs={8}>
    <Paper
      elevation={2}
      square
      styleName="repaymentSummaryPaper"
    >
      <Grid container direction="column" justifyContent="space-between">
        <Grid id="repaymentHeader" item>
          <div styleName="card-header">
            Repayment Summary
          </div>
        </Grid>
        <Grid
          container
          direction="row"
          id="repaymentPlan"
          item
          justifyContent="space-between"
          spacing={3}
          styleName="repaymentPlan"
        >
          <Grid id="planStart" item>
            <div styleName="displayInColumn">
              <div styleName="repaymentPlanHeader">
                  Plan Start
              </div>
              <div>{monthlyPaymentDetails.planStart}</div>
            </div>
          </Grid>
          <Grid id="planEnd" item>
            <div styleName="displayInColumn">
              <div styleName="repaymentPlanHeader">
                  Plan End
              </div>
              <div>{monthlyPaymentDetails.planEnd}</div>
            </div>
          </Grid>
          <Grid id="duration" item>
            <div styleName="displayInColumn">
              <div styleName="repaymentPlanHeader">
                  Duration
              </div>
              <div>{R.pathOr(0, ['duration', 'columnVal'], monthlyPaymentDetails)}</div>
            </div>
          </Grid>
          <Grid id="repaymentMonthlyAmount" item>
            <div styleName="displayInColumn">
              <div styleName="repaymentPlanHeader">
                Monthly Repayment Amount
              </div>
              <div>
                {convertToUSCurrency.format(R.pathOr(0, ['repaymentMonthlyAmount', 'columnVal'], monthlyPaymentDetails))}
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  </Grid>
);

RepaymentSummary.propTypes = {
  monthlyPaymentDetails: PropTypes.shape().isRequired,
};

export default RepaymentSummary;
