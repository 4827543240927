import React, { useEffect, useState } from 'react';
import {
  Paper, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import './CaseDetails.css';
import { connect } from 'react-redux';
import * as R from 'ramda';
import Loader from 'components/Loader';
import { operations as repaymentEvalOperations, selectors as repaymentEvalSelectors } from 'ducks/repaymentEval';
import { selectors as tombstoneSelectors } from 'ducks/tombstone';
// import { selectors as dashboardSelectors } from 'ducks/dashboard';
// import { operations as evalOperations} from 'ducks/eval';
import { STANDARD_EVAL_SCREEN } from 'constants/loanInfoComponents';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import moment from 'moment-timezone';
import RepaymentTable from './RepaymentTable';
import TotalDebt from './TotalDebt';
import FundsInHouse from './FundsInHouse';
import RepaymentTotalAmount from './RepaymentTotalAmount';
import RepaymentSummary from './RepaymentSummary';
import { PAYMENTS_CHANGES_TABLE_COLUMNS, CASEDETAILS_DISABLED_WORKOUTTYPE } from '../../../constants/eval';

const CaseDetails = ({
  handleCalcButton, pastDuePayments, monthlyPaymentDetails,
  updateMonthlyPaymentDetails, checklistCenterPaneView,
  paymentChangesDetails, inProgress, isPlanExtended, isCaseDetailsEditable,
  caseType, status, disasterFlag, displayRepaymentDetails,
}) => {
  const [isRepaymentSectionOpened, setIsRepaymentSectionOpened] = useState(false);
  const [approvedArr, setApprovedArr] = useState([]);
  const [pendingArr, setPendingArr] = useState([]);
  const [fees, setFees] = useState(R.pathOr(0, ['fees', 'columnVal'], monthlyPaymentDetails));
  const [advances, setAdvances] = useState(R.pathOr(0, ['advances', 'columnVal'], monthlyPaymentDetails));

  // useEffect(() => {
  //   paymentChangesOperation(repaymentCaseId);
  // }, []);

  useEffect(() => {
    if (paymentChangesDetails && paymentChangesDetails.length > 0) {
      const { approved, pending } = !R.isNil(paymentChangesDetails)
    && paymentChangesDetails.reduce((acc, item) => {
      if (!acc[item.status]) {
        acc[item.status] = [];
      }
      acc[item.status].push(item);
      return acc;
    }, {});
      setApprovedArr(approved || []);
      setPendingArr(pending || []);
    }
  }, [paymentChangesDetails]);

  return (
    <Grid alignItems="flex-start" container direction="row">
      <Grid id="monthlyPaymentSection" item style={{ padding: '1rem' }} xs={7}>
        <Paper
          elevation={2}
          square
          styleName="monthlyPayment"
        >
          <Grid container direction="column" item justifyContent="space-around">
            <Grid id="monthlyPaymentSchedule" item>
              <div styleName="card-header">
                Monthly Payment Schedule
              </div>
            </Grid>
            {
              displayRepaymentDetails && (
                <>
                  <Grid
                    container
                    direction="row"
                    id="editableFields"
                    item
                    justifyContent="space-around"
                    styleName="editableGrid"
                  >
                    <TotalDebt
                      advances={advances}
                      fees={fees}
                      handleCalcButton={handleCalcButton}
                      isEditable={
                        checklistCenterPaneView !== STANDARD_EVAL_SCREEN
                        && isCaseDetailsEditable
                        && !CASEDETAILS_DISABLED_WORKOUTTYPE.includes(R.toLower(caseType))
                      }
                      pastDuePayments={pastDuePayments}
                      setAdvances={setAdvances}
                      setFees={setFees}
                      updateMonthlyPaymentDetails={updateMonthlyPaymentDetails}
                    />
                    <FundsInHouse
                      advances={advances}
                      fees={fees}
                      handleCalcButton={handleCalcButton}
                      isEditable={
                        checklistCenterPaneView !== STANDARD_EVAL_SCREEN
                        && isCaseDetailsEditable
                      }
                      updateMonthlyPaymentDetails={updateMonthlyPaymentDetails}
                    />
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    id="repaymentSection"
                    item
                    justifyContent="space-around"
                  >
                    <RepaymentTotalAmount
                      monthlyPaymentDetails={monthlyPaymentDetails}
                      setIsRepaymentSectionOpened={setIsRepaymentSectionOpened}
                    />
                    <RepaymentSummary monthlyPaymentDetails={monthlyPaymentDetails} />
                  </Grid>
                  {isRepaymentSectionOpened && (
                  <RepaymentTable
                    disasterFlag={disasterFlag}
                    handleCalcButton={handleCalcButton}
                    isEditable={isCaseDetailsEditable}
                    isPlanExtended={isPlanExtended}
                    repaymentMonthlyAmounts={monthlyPaymentDetails.repaymentMonthlyAmounts}
                    status={status}
                    updateMonthlyPaymentDetails={updateMonthlyPaymentDetails}
                  />
                  )}
                </>
              )
            }
          </Grid>
        </Paper>
      </Grid>
      <Grid id="paymentChangesSection" item style={{ padding: '1rem' }} xs={5}>
        <Paper
          elevation={0}
          square
          styleName="monthlyPayment"
        >
          <Grid container direction="column" item justifyContent="space-around">
            <Grid id="paymentChangesHeader" item>
              <div styleName="card-header">
                Payment Changes
              </div>
            </Grid>
            {displayRepaymentDetails && (
              <Grid id="paymentChangesTable" item style={{ marginTop: '.5rem' }}>
                {inProgress
                  ? <Loader size={40} /> : (
                    <TableContainer component={Paper} elevation={0} styleName="payment-container ">
                      {(approvedArr.length === 0 && pendingArr.length === 0)
                        ? (
                          <>
                            <br />
                            <span> Payment changes do not exist for the loan</span>
                          </>
                        ) : (
                          <Table aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                {PAYMENTS_CHANGES_TABLE_COLUMNS.map(header => (
                                  <TableCell>{header}</TableCell>
                                ))}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {approvedArr.length > 0 ? approvedArr.map((row, index) => (
                                <TableRow
                                  key={`${row.changeFlag}_${index + 1}`}
                                  styleName="white-bg approved-row"
                                  sx={{
                                    '&:last-child td, &:last-child th': { border: 0 },
                                  }}
                                >
                                  <TableCell>
                                    {index + 1}
                                  </TableCell>
                                  <TableCell align="left">{row.changeDate ? moment(new Date(row.changeDate)).format('D MMM YYYY') : null}</TableCell>
                                  <TableCell align="left">
                                    {row.changeFlagDescription}
                                  </TableCell>
                                  <TableCell align="left">
                                    <div styleName="arrow-wrapper">
                                      {row.changeFlag === 'R' ? `${row.changeRate} %` : `$${row.changeAmount}`}
                                      {row.effect && (
                                        <>
                                          {row.direction === 'up' ? (
                                            <ArrowUpwardIcon styleName={row.effect} />
                                          ) : (
                                            <ArrowDownwardIcon styleName={row.effect} />
                                          )}
                                        </>
                                      )}
                                    </div>
                                  </TableCell>
                                  <TableCell align="left">
                                    {row.newTotalAmt ? `$${row.newTotalAmt}` : null}
                                  </TableCell>
                                </TableRow>

                              )) : null}
                              <TableRow><TableCell colspan="5" styleName="divider" /></TableRow>
                              {pendingArr.length > 0 ? pendingArr.map((row, index) => (
                                <TableRow
                                  key={`${row.changeFlag}_${index + 1}`}
                                  styleName="white-bg"
                                  sx={{
                                    '&:last-child td, &:last-child th': { border: 0 },
                                  }}
                                >
                                  <TableCell>
                                    {approvedArr.length + index + 1}
                                  </TableCell>
                                  <TableCell align="left">{row.changeDate ? moment(new Date(row.changeDate)).format('D MMM YYYY') : null}</TableCell>
                                  <TableCell align="left">
                                    {row.changeFlagDescription}
                                  </TableCell>
                                  <TableCell align="left">
                                    <div styleName="arrow-wrapper">
                                      {row.changeFlag === 'R' ? `${row.changeRate} %` : `$${row.changeAmount}`}
                                      {row.effect && (
                                        <>
                                          {row.direction === 'up' ? (
                                            <ArrowUpwardIcon styleName={row.effect} />
                                          ) : (
                                            <ArrowDownwardIcon styleName={row.effect} />
                                          )}
                                        </>
                                      )}
                                    </div>
                                  </TableCell>
                                  <TableCell align="left">
                                    {row.newTotalAmt ? `$${row.newTotalAmt}` : null}
                                  </TableCell>
                                </TableRow>

                              )) : null}
                            </TableBody>
                          </Table>
                        )}
                    </TableContainer>
                  )}
              </Grid>
            )}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

CaseDetails.defaultProps = {
  caseType: '',
  checklistCenterPaneView: '',
  inProgress: true,
};

CaseDetails.propTypes = {
  caseType: PropTypes.string,
  checklistCenterPaneView: PropTypes.string,
  disasterFlag: PropTypes.string.isRequired,
  displayRepaymentDetails: PropTypes.bool.isRequired,
  handleCalcButton: PropTypes.func.isRequired,
  inProgress: PropTypes.bool,
  isCaseDetailsEditable: PropTypes.bool.isRequired,
  isPlanExtended: PropTypes.bool.isRequired,
  monthlyPaymentDetails: PropTypes.shape().isRequired,
  pastDuePayments: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  paymentChangesDetails: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  // paymentChangesOperation: PropTypes.func.isRequired,
  // repaymentCaseId: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  updateMonthlyPaymentDetails: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  pastDuePayments: repaymentEvalSelectors.getPastDuePayments(state),
  monthlyPaymentDetails: repaymentEvalSelectors.getMonthlyPaymentDetails(state),
  checklistCenterPaneView: tombstoneSelectors.getChecklistCenterPaneView(state),
  inProgress: repaymentEvalSelectors.inProgress(state),
  paymentChangesDetails: repaymentEvalSelectors.getPaymentChangesDetails(state),
  // repaymentCaseId: dashboardSelectors.getRepaymentCaseId(state),
});

const mapDispatchToProps = dispatch => ({
  // paymentChangesOperation: evalOperations.paymentChangesOperation(dispatch),
  updateMonthlyPaymentDetails: repaymentEvalOperations.updateMonthlyPaymentDetails(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CaseDetails);
