import React from 'react';
import PropTypes from 'prop-types';
import './AccountServices.css';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import AccountServicesHistory from 'containers/AccountServices/AccountServicesHistory';
import moment from 'moment-timezone';
import ConfirmationDialogBox from 'components/Tasks/OptionalTask/ConfirmationDialogBox';
import { ACCOUNT_SERVICE_EVENT_TYPES, messages } from 'constants/common';
import Dialog from '@material-ui/core/Dialog';
import UITaskGenerator from 'components/UITaskGenerator';
import Loader from 'components/Loader/Loader';
import accountServiceTemplate from 'constants/AccountService/accountService';
import util from 'ducks/tasks-and-checklist/utils';
import AnalysisBreakDownPopover from './AnalysisBreakDownPopover';

const SearchLoanAccountServiceScreen = (props) => {
  const {
    children, analysisBreakdownData, isAnalysisScreenOpen, setOpenAnalysisPopup,
    closeDialog, processAction,
    toggleAccountServiceScreen, selectedCapModId,
    historyData,
    showConfirmationDialog, handleConfirmDialogBoxButtons,
    setChecklistCenterPaneData,
    gatherEscrowDataOperation,
    escrowData,
    onChange,
    showDialog,
    eventType,
    setEventType,
    clearInfo,
    clearPromptError,
    getPromptError, storeAnalysisBreakdownData, showLoaderInPopup,
    setRejected, completeEscrow, setActionFromLoanSearch, isRejectSuccessful, escrowAccess,
    isNARqst,
  } = props;

  React.useEffect(() => () => {
    util.modifyTaskTreeWithValue(
      accountServiceTemplate.loanDetailsTemplate, 'ERROR_MSG', { value: null },
    );
  }, []);

  const selectedCapModData = historyData.find(history => history.capModId
    === selectedCapModId) || {};

  const closeAccountServiceScreen = () => {
    if (isRejectSuccessful) {
      clearInfo();
      setChecklistCenterPaneData('');
    } else {
      closeDialog();
    }
  };

  return (
    <Grid alignItems="center" container direction="column" styleName="whiteBackground">
      <Grid alignItems="center" container direction="row" item justifyContent="space-evenly">
        <Grid id="title" item style={{ justifyContent: 'flex-start' }} xs={selectedCapModData.escrowStatus || !escrowAccess ? 8 : 2}>
          <div style={{ display: 'inline-flex' }}>
            <Typography styleName="titleTop">
              Account Services
            </Typography>
            <AccountServicesHistory />
            {
              selectedCapModData.escrowStatus || !escrowAccess
                ? (
                  <div styleName="displayInlineCenter">
                    <Typography styleName="history-title">
                      <span>CapMod ID:  </span>
                      <span>{selectedCapModId}</span>
                    </Typography>
                    <Typography styleName="history-title">
                      <span>Completed Date:  </span>
                      <span>
                        {
                          selectedCapModData.completedDate
                          && moment(selectedCapModData.completedDate).format('DD MMM YYYY')
                        }
                      </span>
                    </Typography>
                    <Typography styleName="history-title">
                      <span>Completed By:  </span>
                      <span>{selectedCapModData.completedBy}</span>
                    </Typography>
                    <Typography styleName="history-title">
                      <span>Escrow Status:  </span>
                      <span>{selectedCapModData.escrowStatus}</span>
                    </Typography>
                  </div>
                )
                : null
            }
          </div>

        </Grid>
        <Grid alignItems="center" container direction="row" id="status" item style={{ justifyContent: 'flex-end' }} xs={selectedCapModData.escrowStatus || !escrowAccess ? 4 : 10}>
          <Grid item styleName={selectedCapModData.escrowStatus || !escrowAccess || isNARqst ? 'hide-update-button' : 'screenHeader'}>
            <div styleName="displayInlineCenter">
              <CloseIcon styleName="close" />
              <div styleName="reject">{isRejectSuccessful ? messages.REJECTED : messages.PENDING_APPROVAL}</div>
            </div>
          </Grid>
          {
            !isNARqst && (
              <Grid id="viewAnalysisButton" styleName="searchloan">
                <Button
                  onClick={() => {
                    storeAnalysisBreakdownData(analysisBreakdownData);
                    setOpenAnalysisPopup();
                  }}
                  styleName={selectedCapModData.escrowStatus || !escrowAccess || isRejectSuccessful ? 'view-analysis-button' : 'escrowAnalysis'}
                >
                  VIEW ANALYSIS BREAKDOWN
                </Button>
              </Grid>
            )
          }
          <Grid id="updateButton" styleName={selectedCapModData.escrowStatus || !escrowAccess || isNARqst ? 'searchloan' : 'hide-update-button'}>
            <Button
              onClick={() => {
                gatherEscrowDataOperation(ACCOUNT_SERVICE_EVENT_TYPES.ESCROW_TO_ORDER);
              }}
              styleName="approve-button"
              variant="contained"
            >
              REQUEST NEW ESCROW
            </Button>
          </Grid>

          {
            !isNARqst && (
              <>
                <Grid id="reject" item styleName={selectedCapModData.escrowStatus || !escrowAccess || isRejectSuccessful ? 'hide-update-button' : 'screenHeader'}>
                  <Button
                    onClick={() => {
                      setRejected(true);
                      setActionFromLoanSearch(true);
                    }}
                    styleName="reject-button"
                    variant="outlined"
                  >
                    REJECT
                  </Button>
                </Grid>
                <Grid id="approve" item styleName={selectedCapModData.escrowStatus || !escrowAccess || isRejectSuccessful ? 'hide-update-button' : 'screenHeader'}>
                  <Button
                    onClick={() => { completeEscrow(); setActionFromLoanSearch(true); }}
                    styleName="approve-button"
                    variant="contained"
                  >
                    APPROVE
                  </Button>
                </Grid>
              </>
            )
          }

          <Grid id="close" item styleName="searchloan">
            <Button onClick={() => { closeAccountServiceScreen(); }}>
              <CloseIcon />

            </Button>
          </Grid>
        </Grid>
      </Grid>
      {children}
      <AnalysisBreakDownPopover
        closeDialog={setOpenAnalysisPopup}
        data={analysisBreakdownData}
        isOpen={isAnalysisScreenOpen}
        processAction={processAction}
      />
      <ConfirmationDialogBox
        isOpen={showConfirmationDialog}
        message=""
        onClose={(value) => {
          handleConfirmDialogBoxButtons(value);
          if (value) {
            if (eventType === ACCOUNT_SERVICE_EVENT_TYPES.ESCROW_TO_ORDER) {
              toggleAccountServiceScreen(false);
              clearPromptError();
              setEventType(ACCOUNT_SERVICE_EVENT_TYPES.ACC_SERVICE);
              return;
            }
            clearInfo();
            setChecklistCenterPaneData('');
          }
        }}
        title="Are you sure you want to close the page?"
      />
      {showDialog && (
        <Dialog onClose={() => { }} open={() => { }}>
          {
          showLoaderInPopup ? (
            <div styleName="loanDetailsPopupLoader">
              <Loader message="Please Wait" />
            </div>
          )
            : (
              <UITaskGenerator
                checklistItems={util.modifyTaskTreeWithValue(
                  accountServiceTemplate.loanDetailsTemplate, 'ERROR_MSG', { value: getPromptError.message, styleName: getPromptError.type },
                )}
                escrowData={escrowData[ACCOUNT_SERVICE_EVENT_TYPES.ESCROW_TO_ORDER]}
                onChange={onChange}
                processAction={processAction}
              />
            )
        }
        </Dialog>
      )}
    </Grid>
  );
};

SearchLoanAccountServiceScreen.propTypes = {
  analysisBreakdownData: PropTypes.shape.isRequired,
  children: PropTypes.node.isRequired,
  clearInfo: PropTypes.func.isRequired,
  clearPromptError: PropTypes.func.isRequired,
  closeDialog: PropTypes.func.isRequired,
  completeEscrow: PropTypes.func.isRequired,
  escrowAccess: PropTypes.bool.isRequired,
  escrowData: PropTypes.shape.isRequired,
  eventType: PropTypes.string.isRequired,
  gatherEscrowDataOperation: PropTypes.func.isRequired,
  getPromptError: PropTypes.string.isRequired,
  handleConfirmDialogBoxButtons: PropTypes.func.isRequired,
  historyData: PropTypes.arrayOf(PropTypes.shape).isRequired,
  isAnalysisScreenOpen: PropTypes.bool.isRequired,
  isNARqst: PropTypes.bool.isRequired,
  isRejectSuccessful: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  processAction: PropTypes.func.isRequired,
  selectedCapModId: PropTypes.number.isRequired,
  setActionFromLoanSearch: PropTypes.func.isRequired,
  setChecklistCenterPaneData: PropTypes.func.isRequired,
  setEventType: PropTypes.func.isRequired,
  setOpenAnalysisPopup: PropTypes.func.isRequired,
  setRejected: PropTypes.func.isRequired,
  showConfirmationDialog: PropTypes.bool.isRequired,
  showDialog: PropTypes.bool.isRequired,
  showLoaderInPopup: PropTypes.bool.isRequired,
  storeAnalysisBreakdownData: PropTypes.func.isRequired,
  toggleAccountServiceScreen: PropTypes.func.isRequired,
};

export default SearchLoanAccountServiceScreen;
