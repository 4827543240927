const ORDER_ESCROW = 'app/accountService/ORDER_ESCROW';
const GATHER_ESCROW_DATA = 'app/accountService/GATHER_ESCROW_DATA';
const CLOSE_DIALOG = 'app/accountService/CLOSE_DIALOG';
const OPEN_DIALOG = 'app/accountService/OPEN_DIALOG';
const SET_LOAN_EVAL = 'app/accountService/SET_LOAN_EVAL';
const STORE_RESOLUTION_CHOICE_TYPE = 'app/accountService/STORE_RESOLUTION_CHOICE_TYPE';
const STORE_ACC_EVENT_TYPE = 'app/accountService/STORE_ACC_EVENT_TYPE';
const STORE_ESCROW_DATA = 'app/accountService/STORE_ESCROW_DATA';
const ON_VALUE_CHANGE = 'app/accountService/ON_VALUE_CHANGE';
const STORE_ACC_INIT_VALUES = 'app/accountService/STORE_ACC_INIT_VALUES';
const HANDLE_CONFIRM_DIALOG_ACTIONS = 'app/accountService/HANDLE_CONFIRM_DIALOG_ACTIONS';
const CLOSE_POPUPS = 'app/accountService/CLOSE_POPUPS';
const STORE_ANALYSIS_BREAKDOWN_DATA = 'app/accountService/STORE_ANALYSIS_BREAKDOWN_DATA';
const STORE_ACCOUNT_SERVICE_HISTORY = 'app/accountServices/STORE_ACCOUNT_SERVICE_HISTORY';
const STORE_HISTORY_DATA = 'app/accountServices/STORE_HISTORY_DATA';
const STORE_CAPMOD_HISTORY = 'app/accountServices/STORE_CAPMOD_HISTORY';
const STORE_CAPMOD_HISTORY_DATA = 'app/accountServices/STORE_CAPMOD_HISTORY_DATA';
const ACCOUNT_SERVICE_HISTORY_VIEW = 'app/accountServices/ACCOUNT_SERVICE_HISTORY_VIEW';
const STORE_CAPMOD_ID = 'app/accountServices/STORE_CAPMOD_ID';
const STORE_CAPMOD_ID_HISTORY = 'app/accountServices/STORE_CAPMOD_ID_HISTORY';
const STORE_LOAN_NUMBER = 'app/accountServices/STORE_LOAN_NUMBER';
const TOGGLE_ANALYSIS_BREAKDOWN = 'app/accountServices/TOGGLE_ANALYSIS_BREAKDOWN';
const SET_REQUEST_SUBMITTED = 'app/accountServices/SET_REQUEST_SUBMITTED';
const COMPLETE_ESCROW = 'app/accountServices/COMPLETE_ESCROW';
const SET_EVENT_TYPE = 'app/accountServices/SET_EVENT_TYPE';
const DISPLAY_ACCOUNT_SERVICE_SCREEN = 'app/accountServices/DISPLAY_ACCOUNT_SERVICE_SCREEN';
const FETCH_TOMBSTONE_DATA = 'app/accountServices/fetchTombstoneDataAction';
const IS_VALUE_CHANGED = 'app/accountService/IS_VALUE_CHANGED';
const RESET_VALUE_CHANGED = 'app/accountService/RESET_VALUE_CHANGED';
const UPDATE_NET_DISBURSEMENT = 'app/accountService/UPDATE_NET_DISBURSEMENT';
const IS_REJECT_BTN_CLICKED = 'app/accountService/IS_REJECT_BTN_CLICKED';
const CLEAR_INFO = 'app/accountService/CLEAR_INFO';
const CLEAR_HISTORY_INFO = 'app/accountService/CLEAR_HISTORY_INFO';
const SHOW_LOADER = 'app/accountService/SHOW_LOADER';
const SHOW_LOADER_IN_POPUP = 'app/accountService/SHOW_LOADER_IN_POPUP';
const SET_PROMPT_ERROR = 'app/accountService/SET_PROMPT_ERROR';
const SET_IS_APPROVED = 'app/accountService/SET_IS_APPROVED';
const IS_REJECT_SUCCESSFUL = 'app/accountService/IS_REJECT_SUCCESSFUL';
const IS_NEW_ADHOC_RQST = 'app/accountService/IS_NEW_ADHOC_RQST';
const REFRESH_DATE = 'app/accountService/REFRESH_DATE';
const IS_NA_RQST = 'app/accountService/IS_NA_RQST';
const TO_CREATE_NEW_RQST = 'app/accountService/TO_CREATE_NEW_RQST';

export {
  SET_IS_APPROVED,
  SET_PROMPT_ERROR,
  RESET_VALUE_CHANGED,
  UPDATE_NET_DISBURSEMENT,
  IS_VALUE_CHANGED,
  SET_EVENT_TYPE,
  COMPLETE_ESCROW,
  SET_REQUEST_SUBMITTED,
  STORE_CAPMOD_ID,
  STORE_CAPMOD_ID_HISTORY,
  STORE_LOAN_NUMBER,
  STORE_CAPMOD_HISTORY_DATA,
  STORE_HISTORY_DATA,
  STORE_ACCOUNT_SERVICE_HISTORY,
  CLOSE_POPUPS,
  HANDLE_CONFIRM_DIALOG_ACTIONS,
  STORE_ACC_INIT_VALUES,
  ORDER_ESCROW,
  GATHER_ESCROW_DATA,
  CLOSE_DIALOG,
  OPEN_DIALOG,
  SET_LOAN_EVAL,
  STORE_RESOLUTION_CHOICE_TYPE,
  STORE_ACC_EVENT_TYPE,
  STORE_ESCROW_DATA,
  ON_VALUE_CHANGE,
  STORE_ANALYSIS_BREAKDOWN_DATA,
  STORE_CAPMOD_HISTORY,
  ACCOUNT_SERVICE_HISTORY_VIEW,
  TOGGLE_ANALYSIS_BREAKDOWN,
  DISPLAY_ACCOUNT_SERVICE_SCREEN,
  FETCH_TOMBSTONE_DATA,
  IS_REJECT_BTN_CLICKED,
  CLEAR_INFO,
  CLEAR_HISTORY_INFO,
  SHOW_LOADER,
  IS_REJECT_SUCCESSFUL,
  IS_NEW_ADHOC_RQST,
  SHOW_LOADER_IN_POPUP,
  REFRESH_DATE,
  IS_NA_RQST,
  TO_CREATE_NEW_RQST,
};
