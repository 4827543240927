import * as R from 'ramda';

const repayTrackPayments = state => R.pathOr({}, ['rePayment', 'repayTrackPayments'], state);
const getPastDuePayments = state => R.pathOr([], ['rePayment', 'pastDuePayments'], state);
const getMonthlyPaymentDetails = state => R.pathOr({}, ['rePayment', 'monthlyPayment'], state);
const getRuleCheckErrorMessage = state => R.pathOr('', ['rePayment', 'errorMessage'], state);
const getCalcButtonState = state => R.pathOr(true, ['rePayment', 'enableCalc'], state);
const getPaymentChangesDetails = state => R.pathOr([], ['rePayment', 'paymentChanges'], state);
const inProgress = state => R.pathOr(false, ['rePayment', 'inProgress'], state);
const universalActionResp = state => R.pathOr({}, ['rePayment', 'monthlyPayment', 'universalActionResp'], state);
const getRejectReasons = state => R.pathOr([], ['rePayment', 'rejectReasons'], state);
const isCaseRejected = state => R.pathOr([], ['rePayment', 'isCaseRejected'], state);
const getCaseDetails = state => R.pathOr([], ['rePayment', 'caseDetails'], state);
const getFees = state => R.pathOr(0, ['rePayment', 'fees'], state);
const getAdvances = state => R.pathOr(0, ['rePayment', 'advances'], state);
const forbearancePlanSchedule = state => R.pathOr([], ['rePayment', 'monthlyPayment', 'universalActionResp', 'forbearancePlanSchedule'], state);
const planStartDates = state => R.pathOr({}, ['rePayment', 'monthlyPayment', 'universalActionResp', 'planStartDates'], state);
const isInterestRateChanged = state => R.pathOr(false, ['rePayment', 'monthlyPayment', 'isInterestRateChanged'], state);
const approvalHistory = state => R.pathOr({}, ['rePayment', 'monthlyPayment', 'universalActionResp', 'approvalHistory'], state);
const fetchRepayDataLoading = state => R.pathOr(false, ['rePayment', 'fetchRepayDataLoading'], state);
const repayPlanSchedules = state => R.pathOr({}, ['rePayment', 'monthlyPayment', 'universalActionResp', 'repayPlanSchedules'], state);
const workoutTypes = state => R.pathOr([], ['rePayment', 'monthlyPayment', 'universalActionResp', 'workoutTypes'], state);
const planDurations = state => R.pathOr({}, ['rePayment', 'monthlyPayment', 'universalActionResp', 'planDurations'], state);
const calcLoading = state => R.pathOr(false, ['rePayment', 'calcLoading'], state);

const selectors = {
  getRuleCheckErrorMessage,
  repayTrackPayments,
  getPastDuePayments,
  getMonthlyPaymentDetails,
  getCalcButtonState,
  getPaymentChangesDetails,
  inProgress,
  universalActionResp,
  getRejectReasons,
  isCaseRejected,
  getCaseDetails,
  getFees,
  getAdvances,
  forbearancePlanSchedule,
  planStartDates,
  isInterestRateChanged,
  approvalHistory,
  fetchRepayDataLoading,
  repayPlanSchedules,
  planDurations,
  workoutTypes,
  calcLoading,
};

export default selectors;
