import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button, MenuItem, Select,
} from '@material-ui/core';
import './UpdateInterestRate.css';
import PopupContainer from 'components/PopupContainer';
import SubmittedRecords from 'components/SubmittedRecords';
import { operations as businessAdminOperations, selectors as businessAdminSelectors } from 'ducks/admin-tools';
import {
  selectors as loginSelectors,
} from 'ducks/login';
import {
  PATH_VARIABLE_UPDATE_INTEREST_RATE,
  DEFAULT_ROWS_PER_PAGE,
  investorAndClassNameMapping,
} from 'constants/busniessAdmin';


const UpdateInterestRate = ({
  user, handleUpdateInsertRateRequest, recordsInfo,
  getModInvestorsOperation, modInvestors, fetchHistoryData,
  setInvestorName,
}) => {
  const defaultCaseObj = {
    investorName: '',
    effectiveDate: '',
  };

  useEffect(() => {
    getModInvestorsOperation();
  }, []);

  const [caseObj, setCaseObj] = useState(defaultCaseObj);

  useEffect(() => {
    if (caseObj.investorName !== '') {
      setInvestorName(investorAndClassNameMapping[caseObj.investorName] || '');
      fetchHistoryData(0, DEFAULT_ROWS_PER_PAGE);
    }
  }, [caseObj.investorName]);


  const [compData, setCompData] = useState({
    show: false,
    isDisabled: false,
  });
  const [lableName, setLableName] = useState('');

  const today = new Date().toISOString().split('T')[0];

  const otherOptions = ['4.75000', '4.87500', '5.00000', '5.12500',
    '5.25000', '5.37000', '5.50000', '5.62500', '5.75000', '5.87500', '6.00000', '6.12500', '6.37500', '6.50000', '6.62500', '6.75000'];

  const renderStatusOptions = values => values.map((option) => {
    const value = option.replace('/', '').split(' ').join('').toLowerCase();
    return <MenuItem value={value}>{option}</MenuItem>;
  });

  const validatefields = (obj) => {
    const verifyData = Object.values(obj).map(x => x !== '').every(Boolean);
    setCompData({ ...compData, isDisabled: verifyData });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    let updatedObject = { ...caseObj };
    if (name === 'investorName') {
      const { investorName } = caseObj;
      updatedObject = { investorName, effectiveDate: '' };
      setLableName(event.currentTarget.textContent);
    }
    updatedObject[name] = value;
    validatefields(updatedObject);
    setCaseObj(updatedObject);
  };

  const clearEdits = () => {
    setCaseObj({
      investorName: '',
      effectiveDate: '',
    });
    setCompData({
      show: false,
      isDisabled: false,
    });
  };

  const handleClose = () => {
    const { show } = compData;
    setCompData({
      ...compData, show: !show,
    });
    setCaseObj({
      investorName: '',
      effectiveDate: '',
    });
  };

  const [requestData, setRequestData] = useState({});

  const handleSubmit = () => {
    const { investorName } = caseObj;
    const requestObj = {
      ...caseObj,
      pathVariable: PATH_VARIABLE_UPDATE_INTEREST_RATE,
      action: 'UPDATE_INTEREST_RATE',
      email: user.userDetails.email,
      investorName: investorAndClassNameMapping[investorName],
    };
    setRequestData(requestObj);
    setCompData({ show: true, isDisabled: false });
  };

  const handleConfirm = () => {
    handleUpdateInsertRateRequest(requestData);
    handleClose();
  };

  return (
    <div styleName="form-fields-container">
      <div styleName="flex-container select-field-container">
        <p>Interest Name</p>
        <Select
          displayEmpty
          label="Select"
          name="investorName"
          onChange={handleChange}
          value={caseObj.investorName}
        >
          <MenuItem value="">Select</MenuItem>
          {renderStatusOptions(modInvestors)}
        </Select>
      </div>
      {caseObj.investorName.length > 0 && (
        <>
          <div styleName="flex-container select-field-container">
            <p>{lableName}</p>
            <Select
              displayEmpty
              label="Select"
              name="interestRate"
              onChange={handleChange}
              value={caseObj.interestRate || ''}
            >
              <MenuItem value="">Select</MenuItem>
              {renderStatusOptions(otherOptions)}
            </Select>
          </div>
          <div styleName="flex-container select-field-container">
            <p>Future Effective Date</p>
            <input
              min={today}
              name="effectiveDate"
              onChange={handleChange}
              styleName="inputTextElem"
              type="date"
              value={caseObj.effectiveDate}
            />
          </div>
          <div styleName="flex-container select-field-container">
            <span styleName="caution-msg">If interest rate is not available, contact support team</span>
          </div>
        </>
      )}
      <span styleName="divider" />
      <div
        style={{ justifyContent: 'flex-end' }}
        styleName="flex-container"
      >
        <Button
          color="primary"
          disabled={!compData.isDisabled}
          onClick={clearEdits}
          style={{ marginRight: '8px' }}
          variant="outlined"
        >
          CLEAR
        </Button>
        <Button color="primary" disabled={!compData.isDisabled} onClick={handleSubmit} variant="contained">
          ADD/UPDATE
        </Button>
      </div>
      <SubmittedRecords
        successfulRecords={recordsInfo.successCount || 0}
        type="Investor Name"
        unSuccessfulRecords={recordsInfo.failureCount || 0}
        unsuccessfulRecordsResp={recordsInfo.unsuccessfulRecords || []}
      />
      <PopupContainer
        handleClose={handleClose}
        show={compData.show}
        sizeSmall
        title="Do you proceed to ADD/UPDATE?"
      >
        <div
          style={{ justifyContent: 'flex-start', padding: '20px' }}
          styleName="flex-container"
        >
          <Button color="primary" onClick={handleConfirm} variant="contained">
            CONFIRM
          </Button>
          <Button
            color="primary"
            onClick={handleClose}
            style={{ marginLeft: '8px' }}
            variant="outlined"
          >
            CANCEL
          </Button>
        </div>
      </PopupContainer>
    </div>
  );
};

UpdateInterestRate.defaultProps = {
  handleUpdateInsertRateRequest: () => {},
  modInvestors: [],
  fetchHistoryData: () => {},
};

UpdateInterestRate.propTypes = {
  fetchHistoryData: PropTypes.func,
  getModInvestorsOperation: PropTypes.func.isRequired,
  handleUpdateInsertRateRequest: PropTypes.func,
  modInvestors: PropTypes.arrayOf(PropTypes.string),
  recordsInfo: PropTypes.shape({
    failureCount: PropTypes.number,
    success: PropTypes.bool,
    successCount: PropTypes.number,
    unsuccessfulRecords: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  setInvestorName: PropTypes.func.isRequired,
  user: PropTypes.shape({
    groupList: PropTypes.array,
    userDetails: PropTypes.shape({
      email: PropTypes.string,
      jobTitle: PropTypes.string,
      name: PropTypes.string,
    }),
    userGroups: PropTypes.array,
  }).isRequired,
};

const mapStateToProps = state => ({
  user: loginSelectors.getUser(state),
  recordsInfo: businessAdminSelectors.getRecordsInfo(state),
  modInvestors: businessAdminSelectors.getModInvestors(state),
});

const mapDispatchToProps = dispatch => ({
  handleUpdateInsertRateRequest: businessAdminOperations
    .submitModSupportRequestData(dispatch),
  getModInvestorsOperation: businessAdminOperations.getModInvestorsOperation(dispatch),
  fetchHistoryData: businessAdminOperations.fetchBusinessAdminHistoryData(dispatch),
  setInvestorName: businessAdminOperations.setInvestorName(dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(UpdateInterestRate);
