/* eslint-disable no-restricted-globals */
import React, { useState, useEffect } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { selectors as repaymentEvalSelectors } from 'ducks/repaymentEval';
import { selectors as evalSelector } from 'ducks/eval';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { convertToUSCurrency, FORMAT } from 'lib/Formatters';
import './FundsInHouse.css';
import { NON_CURRENCY_ONLY_REGEX } from 'constants/eval';

const FundsInHouse = ({
  monthlyPaymentDetails, updateMonthlyPaymentDetails, handleCalcButton,
  isEditable, caseHeaderInfo, isCaseRejected, fees, advances,
}) => {
  const [isPendingPaymentsDisabled, setDisablePendingPayments] = useState(true);

  const {
    pastDuePayments,
    suspense,
  } = monthlyPaymentDetails;

  const {
    lockFlag,
  } = caseHeaderInfo;

  const [isValueUpdated, setIsValueUpdated] = useState(false);
  const [pendingPayment, setPendingPayment] = useState(R.pathOr(0, ['pendingPayment', 'columnVal'], monthlyPaymentDetails));
  useEffect(() => {
    if (isValueUpdated) {
      handleCalcButton();
    }
  }, [isValueUpdated]);

  return (
    <Grid id="fundInHouse" item xs={5}>
      <Paper
        elevation={2}
        square
        styleName="fundInHouse"
      >
        <Grid
          container
          direction="column"
          id="fundInHousePaper"
          item
          justifyContent="space-around"
        >
          <Grid
            container
            direction="row"
            id="fundInHouseValue"
            item
            styleName="fundInHouseGrid"
          >
            <Grid item xs={6}>
              <div styleName="card-header">
                Funds In House
              </div>
            </Grid>
            <Grid item xs={6}>
              <div styleName="fundInHouseValue">
                <b>
                  {
                    convertToUSCurrency.format(parseFloat(fees) + parseFloat(advances) + parseFloat(R.pathOr(0, ['columnVal'], pastDuePayments)) - (R.pathOr(0, ['columnVal'], suspense)))
                  }
                </b>
              </div>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            id="fihPaymentDetails"
            item
            justifyContent="space-between"
            spacing={2}
          >
            <Grid item>
              <div styleName="displayInColumn">
                <div styleName="suspense-misc">
                  Suspense/MISC
                </div>
                <div>{convertToUSCurrency.format(R.pathOr(0, ['columnVal'], suspense))}</div>
              </div>
            </Grid>
            <Grid item>
              <div styleName="displayInColumn">
                <div styleName="textColor">Pending Payments</div>
                <div>
                  <OutlinedInput
                    disabled={isPendingPaymentsDisabled}
                    endAdornment={(
                      <InputAdornment position="end">
                        { isEditable && (
                        <IconButton
                          disabled={lockFlag || isCaseRejected}
                          edge="end"
                          style={{ padding: '0' }}
                        >
                          <EditIcon
                            onClick={() => {
                              setDisablePendingPayments(false);
                            }}
                            styleName="editIcon"
                          />
                        </IconButton>
                        ) }
                      </InputAdornment>
)}
                    id="outlined-pendingpayments-textBox"
                    onBlur={() => {
                      setDisablePendingPayments(true);
                      setIsValueUpdated(true);
                    }}
                    onChange={(e) => {
                      if (
                        !NON_CURRENCY_ONLY_REGEX.test(
                          e.target.value,
                        )
                      ) {
                        updateMonthlyPaymentDetails('pendingPayment', e.target.value.replace(/,/g, ''));
                        setPendingPayment(e.target.value.replace(/,/g, ''));
                      }
                    }}
                    startAdornment={
                      <InputAdornment position="start">$</InputAdornment>
                    }
                    styleName={isPendingPaymentsDisabled ? 'disabled-background' : 'enabled-background'}
                    type="text"
                    value={FORMAT.currency((pendingPayment || 0).toString())}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};
FundsInHouse.propTypes = {
  advances: PropTypes.number.isRequired,
  caseHeaderInfo: PropTypes.shape().isRequired,
  fees: PropTypes.number.isRequired,
  handleCalcButton: PropTypes.func.isRequired,
  isCaseRejected: PropTypes.bool.isRequired,
  isEditable: PropTypes.bool.isRequired,
  monthlyPaymentDetails: PropTypes.shape().isRequired,
  updateMonthlyPaymentDetails: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  monthlyPaymentDetails: repaymentEvalSelectors.getMonthlyPaymentDetails(state),
  caseHeaderInfo: evalSelector.caseHeaderInfoData(state),
  isCaseRejected: repaymentEvalSelectors.isCaseRejected(state),
  getUpdatedFees: repaymentEvalSelectors.getFees(state),
  getUpdatedAdvances: repaymentEvalSelectors.getAdvances(state),
});

export default connect(mapStateToProps, null)(FundsInHouse);
