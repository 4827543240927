import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { connect } from 'react-redux';
import {
  Grid, Box, Button, IconButton,
} from '@material-ui/core';
import DeleteOutlined from '@material-ui/icons/DeleteOutlined';
import EvalTable from 'components/Eval/EvalTable';
import DatePicker from 'components/Eval/DatePicker';
import moment from 'moment-timezone';
// import findSeq from 'lib/CustomFunctions/Eval/findSeqNumber';
import compareArrays from 'lib/CustomFunctions/Eval/compareTrackPaymentData';
import Popup from 'components/Popup';
import './TrackPayment.css';
import { operations as evalOperations, selectors as evalSelectors } from '../../../state/ducks/eval';
import { selectors } from '../../../state/ducks/repaymentEval';
import { selectors as loginSelectors } from '../../../state/ducks/login';
import {
  TRACK_PAYMENT_DELETE_ICON_VISIBILITY_CASE_TYPES, DELETE_ICON_ENABLE_AD_LIST_WITHOUT_WORKOUT_TYPE,
  DELETE_ICON_ENABLE_AD_LIST_WITH_WORKOUT_TYPE, TRACK_PAYMENT_CASE_TYPES_UPDATE,
} from '../../../constants/eval';

const TrackPayment = (props) => {
  const {
    repayTrackPayments, fetchTrackPayments, updateTrackPaymentsDates,
    deleteTrackPayment, isCaseRejected, caseStatus, userGroupList, userEmail,
    caseHeaderInfo, resolutionId, isTrackPayEditable, updateTrackPaymentsResponse,
  } = props;
  const [tableData, setTableData] = useState(repayTrackPayments.trackPaymentDetailsList);
  const [visible, setVisible] = useState(false);
  const [initialData, setInitialData] = useState(repayTrackPayments.trackPaymentDetailsList);
  const isDeleteIconVisible = TRACK_PAYMENT_DELETE_ICON_VISIBILITY_CASE_TYPES.includes(caseStatus);
  const [isCaseRejectedValue, SetIsCaseRejectedValue] = useState(isCaseRejected);
  const [showPopup, setShowPopup] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(
    { isSuccessModalVisible: false, updatedTrackPayment: false },
  );

  const disableDeleteIcon = () => {
    if (userGroupList && userGroupList.length > 0 && caseHeaderInfo && caseHeaderInfo.reslWorkoutType === 'F') {
      if (userGroupList.some(e => DELETE_ICON_ENABLE_AD_LIST_WITH_WORKOUT_TYPE.includes(e))) {
        return false;
      }
    } else if (userGroupList && userGroupList.length > 0 && caseHeaderInfo && caseHeaderInfo.reslWorkoutType !== 'F') {
      if (userGroupList.some(e => DELETE_ICON_ENABLE_AD_LIST_WITHOUT_WORKOUT_TYPE.includes(e))) {
        return false;
      }
    }
    return true;
  };

  const disableDelete = disableDeleteIcon();

  useEffect(() => {
    fetchTrackPayments({ resolutionId, isRepaymentEval: 'Yes' });
  }, []);

  useEffect(() => {
    SetIsCaseRejectedValue(isCaseRejected);
  }, [isCaseRejected]);

  useEffect(() => {
    setTableData(repayTrackPayments.trackPaymentDetailsList);
    setInitialData(repayTrackPayments.trackPaymentDetailsList);
  }, [repayTrackPayments]);

  useEffect(() => {
    const newVisible = (compareArrays(initialData, tableData, 1));
    setVisible(newVisible);
  }, [tableData]);

  const handleDelete = (terms) => {
    // TODO modify the payload with API integration
    deleteTrackPayment({ id: terms, resolutionId, isRepaymentEval: 'Yes' });
  };

  const handleDateChange = (columnName, value, terms) => {
    setTableData((prevState) => {
      const newState = prevState.map((obj, idx) => {
        if (obj.terms === terms) {
          return { ...obj, [columnName]: value.format(), [`is${columnName}Updated`]: !moment(initialData[idx][columnName]).isSame(moment(value), 'days') };
        }
        return obj;
      });
      return newState;
    });
  };

  const handleUpdate = (isConfirm) => {
    const validateStatus = TRACK_PAYMENT_CASE_TYPES_UPDATE.includes(caseStatus);
    setShowPopup(validateStatus && !isConfirm);
    if (isConfirm || !validateStatus) {
      const paymentList = initialData.map((item, idx) => ({
        datePaymentReceived: tableData[idx].paymentDate,
        forbDtlId: item.detailId,
        hssnReportedDate: item.hssnreportedDate,
        isPaymentDateUpdated: R.propOr(false, 'ispaymentDateUpdated', tableData[idx]),
        isProcessDateUpdated: false,
        seq: item.terms,
      }));
      const payload = {
        paymentHSSNDatesUpdateReqDataList: paymentList,
        userEmail,
        resolutionId,
      };
      updateTrackPaymentsDates(payload);
      setShowSuccessModal(prev => ({ ...prev, updatedTrackPayment: true }));
    }
  };

  const renderDeleteIcon = (terms, isDisabled) => (
    <IconButton
      disabled={isCaseRejectedValue || isDisabled}
    >
      <DeleteOutlined onClick={() => handleDelete(terms)} styleName={isDisabled ? 'disabledIcon' : 'deleteIcon'} />
    </IconButton>
  );

  const TRACKPAYMENT_COL = [
    {
      name: 'terms',
      label: 'Terms',
      align: 'left',
    },
    {
      name: 'startedDueDate',
      label: 'Stated Due Date',
      align: 'left',
      cellFormat: value => moment(value).format('DD MMM YYYY'),
    },
    {
      name: 'actualDueDate',
      label: 'Actual Due Date',
      align: 'left',
      cellFormat: value => moment(value).format('DD MMM YYYY'),
    },
    {
      name: 'paymentDate',
      label: 'Date Payment Received',
      align: 'left',
      editable: true,
      dateField: true,
      cellFormat: row => (
        <DatePicker
          format="DD MMM YYYY"
          handleChange={(columnName, val) => {
            handleDateChange(columnName, val, row.terms);
          }}
          id="paymentDate"
          isViewOnly={isCaseRejectedValue || !isTrackPayEditable}
          label="paymentDate"
          override
          value={moment(row.paymentDate).format('DD MMM YYYY')}
        />
      ),
    },
    {
      name: 'amount',
      label: 'Amount',
      align: 'left',
    },
    isDeleteIconVisible && {
      name: 'del',
      label: '',
      align: 'left',
      deleteIcon: true,
      name1: 'terms',
      cellFormat: terms => renderDeleteIcon(terms, disableDelete),
    },
  ];


  return (
    <Box styleName="trackPaymentTab">
      <Grid styleName="trackPayment tableBlock">
        <EvalTable
          columns={TRACKPAYMENT_COL}
          data={tableData || []}
        />
      </Grid>
      <Button
        color="primary"
        disabled={isCaseRejectedValue || !visible}
        onClick={() => handleUpdate(false)}
        styleName="updateButton"
        variant="contained"
      >
          UPDATE PAYMENT DATES
      </Button>

      <Popup
        cancelButtonText="NO"
        confirmButtonText="YES"
        message="This will re-open the Case. Are you sure you want to proceed?"
        onCancel={() => setShowPopup(false)}
        onConfirm={() => handleUpdate(true)}
        show={showPopup}
        showCancelButton
        showConfirmButton
      />
      <Popup
        confirmButtonText="OK"
        level="Success"
        onConfirm={() => setShowSuccessModal(prev => ({ ...prev, isSuccessModalVisible: false }))}
        show={showSuccessModal.isSuccessModalVisible}
        showConfirmButton
        title={updateTrackPaymentsResponse && updateTrackPaymentsResponse.status}
      />
    </Box>
  );
};

TrackPayment.defaultProps = {
  caseHeaderInfo: {
    reslWorkoutType: '',
  },
  repayTrackPayments: [],
  fetchTrackPayments: () => { },
  updateTrackPaymentsDates: () => { },
  deleteTrackPayment: () => { },
  isCaseRejected: false,
  userGroupList: [],
  updateTrackPaymentsResponse: {},
};

TrackPayment.propTypes = {
  caseHeaderInfo: PropTypes.shape({
    reslWorkoutType: PropTypes.string,
  }),
  caseStatus: PropTypes.string.isRequired,
  deleteTrackPayment: PropTypes.func,
  fetchTrackPayments: PropTypes.func,
  isCaseRejected: PropTypes.bool,
  isTrackPayEditable: PropTypes.bool.isRequired,
  repayTrackPayments: PropTypes.arrayOf(PropTypes.shape()),
  resolutionId: PropTypes.number.isRequired,
  updateTrackPaymentsDates: PropTypes.func,
  updateTrackPaymentsResponse: PropTypes.shape(),
  userEmail: PropTypes.string.isRequired,
  userGroupList: PropTypes.arrayOf(PropTypes.string.isRequired),
};

const mapStateToProps = state => ({
  caseHeaderInfo: evalSelectors.caseHeaderInfoData(state),
  repayTrackPayments: evalSelectors.trackPayments(state),
  isCaseRejected: selectors.isCaseRejected(state),
  userGroupList: loginSelectors.getGroupList(state),
  userEmail: loginSelectors.getUserPrincipalName(state),
  updateTrackPaymentsResponse: evalSelectors.updateTrackPaymentsResponse(state),
});

const mapDispatchToProps = dispatch => ({
  fetchTrackPayments: evalOperations.fetchTrackPaymentData(dispatch),
  updateTrackPaymentsDates: evalOperations.updateTrackPaymentsDates(dispatch),
  deleteTrackPayment: evalOperations.deleteTrackPaymentOperation(dispatch),
});


const TrackPaymentContainer = connect(mapStateToProps, mapDispatchToProps)(TrackPayment);

export default TrackPaymentContainer;
