import React, { useState } from 'react';
import UITaskGenerator from 'components/UITaskGenerator';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { operations as accountServiceOperations, selectors as accountServiceSelectors } from 'ducks/accountService';
import { selectors as loginSelectors } from 'ducks/login';
import { operations as checkListOperations } from 'ducks/tasks-and-checklist';
import { selectors as dashboardSelectors, operations as dashboardOperations } from 'ducks/dashboard';
import { selectors as tombstoneSelectors, operations as tombstoneOperations } from 'ducks/tombstone';
import accountServiceTemplate from 'constants/AccountService/accountService';
import { ACCOUNT_SERVICE_EVENT_TYPES, escrowOrderedTaskCodes, modInfoScreenTaskCode } from 'constants/common';
import WidgetBuilder from 'components/Widgets/WidgetBuilder';
import './AccountServices.css';
import util from 'ducks/tasks-and-checklist/utils';
import { getDaysDiffFromCurrentDate } from 'lib/DateUtils';
import * as R from 'ramda';
import AccountServiceScreen from 'containers/AccountServices/AccountServiceScreen';
import { selectors as notificationSelectors, operations as notificationOperations } from 'ducks/notifications';
import { selectors as widgetSelectors } from 'ducks/widgets';
import CustomSnackBar from 'components/CustomSnackBar';
import {
  HISTORY, ADDITIONAL_INFO, FINANCIAL_CALCULATOR, DOCUMENT_CHECKLIST, FHLMC, LSAMS_NOTES,
  WESTWING,
} from 'constants/widgets';
import Loader from 'components/Loader/Loader';
import AdditionalInfo from 'containers/AdditionalInfo';
import LSAMSNotesWidget from 'components/Widgets/LSAMSNotesWidget';
import MilestoneActivity from 'containers/LoanActivity/MilestoneActivity';
import IncomeCalcWidget from 'containers/IncomeCalc/IncomeCalcWidget';
import DocChecklistWidget from 'components/Widgets/DocChecklistWidget';
import WestWingWidget from 'components/Widgets/WestWing';
import FHLMCWidget from 'components/Widgets/FHLMCWidget';
import SearchLoanAccountServiceScreen from './SearchLoanAccountServiceScreen';
import ApprovedAccountServiceScreen from './ApprovedAccountServiceScreen';
import CapModHistoryScreen from './CapModHistoryScreen';
import RejectScreen from './RejectScreen';
import ModInfoAccountServiceScreen from './ModInfoAccountServiceScreen';

function AccountServices(props) {
  const {
    escrowData, onChange, processActionCommand,
    analysisBreakdownData,
    isHistoryView, capModHistoryData, historyData, selectedCapModId,
    toggleHistoryView, isAnalysisScreenOpen,
    toggleAnalysisScreen, completeEscrow, showConfirmationDialog,
    handleConfirmDialogBoxButtons, closeDialog,
    toggleAccountServiceScreen, stagerTaskName, searchLoanTaskResponse,
    getCapModHistoryOperation,
    isNavigatedFromTombstone,
    groupName,
    tombstoneData,
    hasValueChange,
    snackBarData,
    closeSnackBar,
    updateDisbursement,
    setChecklistCenterPaneData,
    inLoanSearchPage,
    showDialog,
    gatherEscrowDataOperation,
    eventType,
    setEventType,
    onAutoSave,
    openWidgetList,
    resetValueChange,
    clearInfo, clearHistoryInfo, selectedCapModIdHistory, showLoader, showLoaderInPopup,
    clearPromptError,
    getPromptError,
    storeAnalysisBreakdownData,
    isApproved, isRejectSuccessful, isNewAdhocRqst, userGroupsList, isNARqst,
  } = props;

  const [isRejected, setRejected] = useState(false);
  const [template, setTemplate] = useState([]);
  const [isActionFromLoanSearch, setActionFromLoanSearch] = useState(false);

  React.useEffect(() => (() => { toggleAccountServiceScreen(false); }), []);

  const shouldRenderWidgetView = () => R.any(widget => R.contains(
    widget, [HISTORY, ADDITIONAL_INFO,
      FINANCIAL_CALCULATOR, DOCUMENT_CHECKLIST, FHLMC, LSAMS_NOTES, WESTWING],
  ))(openWidgetList);

  const renderWidgetComponents = () => {
    const mainWidget = R.head(openWidgetList);
    let widgetToRender = null;
    switch (mainWidget) {
      case ADDITIONAL_INFO:
        widgetToRender = <AdditionalInfo groupName={groupName} />;
        break;
      case HISTORY:
        widgetToRender = <MilestoneActivity />;
        break;
      case FINANCIAL_CALCULATOR:
        widgetToRender = <IncomeCalcWidget />;
        break;
      case DOCUMENT_CHECKLIST:
        widgetToRender = <DocChecklistWidget />;
        break;
      case LSAMS_NOTES:
        widgetToRender = <LSAMSNotesWidget style={{ width: '100%' }} />;
        break;
      case WESTWING:
        widgetToRender = <WestWingWidget />;
        break;
      case FHLMC:
        widgetToRender = <FHLMCWidget />;
        break;
      default:
        widgetToRender = null;
    }
    return widgetToRender;
  };

  React.useEffect(() => {
    let taskTree = JSON.parse(JSON.stringify(accountServiceTemplate.escrowOrderingTemplate));
    const capModId = R.propOr(0, 'content',
      R.find(val => val.title === 'Cap Mod Id')(tombstoneData));
    const { escrowStatus, completedDate } = historyData
      .find(data => data.capModId === capModId) || {};
    const { escrowStatus: historyEscrowStatus } = historyData
      .find(data => data.capModId === selectedCapModIdHistory) || {};
    const isEscrowApproved = escrowStatus === 'Approved' && completedDate !== null;
    const isHistoryEscrowApproved = historyEscrowStatus === 'Approved' && completedDate !== null;
    if (isHistoryView || (isRejected && !isRejectSuccessful)) {
      taskTree = util.modifyTaskTreeWithVisibility(
        taskTree,
        [...escrowOrderedTaskCodes, 'REJECT_REASON'],
        isHistoryView,
        isHistoryView ? isHistoryEscrowApproved : isEscrowApproved,
      );
    } else if (isApproved || inLoanSearchPage || (isNavigatedFromTombstone && (!isEscrowApproved
      || !['BEUW', 'DOCGEN'].includes(groupName)
      || (isEscrowApproved && getDaysDiffFromCurrentDate(completedDate) > 90 && ['BEUW', 'DOCGEN'].includes(groupName))
    ))) {
      if (inLoanSearchPage && !completedDate && !(isApproved || isRejectSuccessful) && !isNARqst) {
        taskTree = util.modifyTaskTreeWithEditableFields(
          taskTree,
          escrowOrderedTaskCodes,
          undefined,
        );
      } else {
        taskTree = util.modifyTaskTreeWithEditableFields(
          taskTree,
          [],
          escrowStatus ? isEscrowApproved : undefined,
        );
      }
    } else if (isNavigatedFromTombstone && isEscrowApproved && getDaysDiffFromCurrentDate(completedDate) <= 90 && ['BEUW', 'DOCGEN'].includes(groupName)) {
      taskTree = util.modifyTaskTreeWithEditableFields(
        taskTree,
        modInfoScreenTaskCode,
        isEscrowApproved,
      );
    } else {
      taskTree = util.modifyTaskTreeWithEditableFields(
        taskTree, escrowOrderedTaskCodes, undefined,
      );
    }
    setTemplate(taskTree);
  }, [
    isHistoryView, isApproved, isRejected,
    historyData, selectedCapModIdHistory, isNewAdhocRqst,
    isRejectSuccessful,
  ]);

  const loanUnassignment = () => {
    onAutoSave('Paused');
  };

  const renderAccountServiceScreen = () => (
    <AccountServiceScreen
      analysisBreakdownData={analysisBreakdownData}
      clearInfo={clearInfo}
      closeDialog={closeDialog}
      completeEscrow={completeEscrow}
      escrowData={escrowData[ACCOUNT_SERVICE_EVENT_TYPES.ACC_SERVICE]}
      handleConfirmDialogBoxButtons={handleConfirmDialogBoxButtons}
      isAnalysisScreenOpen={isAnalysisScreenOpen}
      processAction={processActionCommand}
      setOpenAnalysisPopup={toggleAnalysisScreen}
      setRejected={setRejected}
      showConfirmationDialog={showConfirmationDialog}
      storeAnalysisBreakdownData={storeAnalysisBreakdownData}
      toggleAccountServiceScreen={toggleAccountServiceScreen}
      unAssignLoan={loanUnassignment}
    >
      <UITaskGenerator
        checklistItems={template}
        escrowData={escrowData[ACCOUNT_SERVICE_EVENT_TYPES.ACC_SERVICE]}
        onChange={onChange}
        processAction={processActionCommand}
      />
    </AccountServiceScreen>
  );

  const renderApprovedScreen = () => (
    <ApprovedAccountServiceScreen
      analysisBreakdownData={analysisBreakdownData}
      clearInfo={clearInfo}
      escrowData={escrowData[ACCOUNT_SERVICE_EVENT_TYPES.ACC_SERVICE]}
      isActionFromLoanSearch={isActionFromLoanSearch}
      isAnalysisScreenOpen={isAnalysisScreenOpen}
      processAction={processActionCommand}
      setChecklistCenterPaneData={setChecklistCenterPaneData}
      setOpenAnalysisPopup={toggleAnalysisScreen}
      storeAnalysisBreakdownData={storeAnalysisBreakdownData}
      toggleAccountServiceScreen={toggleAccountServiceScreen}
      unAssignLoan={loanUnassignment}
    >
      <UITaskGenerator
        checklistItems={template}
        escrowData={escrowData[ACCOUNT_SERVICE_EVENT_TYPES.ACC_SERVICE]}
        onChange={onChange}
        processAction={processActionCommand}
      />
    </ApprovedAccountServiceScreen>
  );

  const renderHistoryScreen = () => (
    <CapModHistoryScreen
      analysisBreakdownData={capModHistoryData.analysisResponse}
      capModId={selectedCapModIdHistory}
      clearHistoryInfo={clearHistoryInfo}
      escrowData={capModHistoryData.capmodDetails}
      getCapModHistoryOperation={getCapModHistoryOperation}
      isAnalysisScreenOpen={isAnalysisScreenOpen}
      processAction={processActionCommand}
      searchLoanTaskResponse={searchLoanTaskResponse}
      selectedCapModData={historyData.find(data => data.capModId === selectedCapModIdHistory) || {}}
      setOpenAnalysisPopup={toggleAnalysisScreen}
      stagerTaskName={stagerTaskName}
      storeAnalysisBreakdownData={storeAnalysisBreakdownData}
      toggleAccountServiceScreen={toggleAccountServiceScreen}
      toggleHistoryView={toggleHistoryView}
    >
      <UITaskGenerator
        checklistItems={template}
        escrowData={capModHistoryData.capmodDetails}
        onChange={onChange}
        processAction={processActionCommand}
      />
    </CapModHistoryScreen>
  );

  const renderRejectScreen = () => (
    <RejectScreen
      clearInfo={clearInfo}
      clearPromptError={clearPromptError}
      isActionFromLoanSearch={isActionFromLoanSearch}
      setChecklistCenterPaneData={setChecklistCenterPaneData}
      setRejected={setRejected}
      showLoaderInPopup={showLoaderInPopup}
      unAssignLoan={loanUnassignment}
    >
      <UITaskGenerator
        checklistItems={template}
        escrowData={escrowData[ACCOUNT_SERVICE_EVENT_TYPES.ACC_SERVICE]}
        onChange={onChange}
        processAction={processActionCommand}
      />
    </RejectScreen>
  );

  const renderModInfoScreen = () => (
    <ModInfoAccountServiceScreen
      analysisBreakdownData={analysisBreakdownData}
      clearInfo={clearInfo}
      closeDialog={closeDialog}
      escrowData={escrowData[ACCOUNT_SERVICE_EVENT_TYPES.ACC_SERVICE]}
      groupName={groupName}
      handleConfirmDialogBoxButtons={handleConfirmDialogBoxButtons}
      hasValueChange={hasValueChange}
      historyData={historyData}
      isAnalysisScreenOpen={isAnalysisScreenOpen}
      processAction={processActionCommand}
      resetValueChange={resetValueChange}
      selectedCapModId={selectedCapModId}
      setChecklistCenterPaneData={setChecklistCenterPaneData}
      setOpenAnalysisPopup={toggleAnalysisScreen}
      showConfirmationDialog={showConfirmationDialog}
      storeAnalysisBreakdownData={storeAnalysisBreakdownData}
      toggleAccountServiceScreen={toggleAccountServiceScreen}
      updateDisbursement={updateDisbursement}
    >
      <UITaskGenerator
        checklistItems={template}
        escrowData={escrowData[ACCOUNT_SERVICE_EVENT_TYPES.ACC_SERVICE]}
        onChange={onChange}
        processAction={processActionCommand}
      />
    </ModInfoAccountServiceScreen>
  );

  const renderSearchLoanPageAccountServiceScreen = () => (
    <SearchLoanAccountServiceScreen
      analysisBreakdownData={analysisBreakdownData}
      clearInfo={clearInfo}
      clearPromptError={clearPromptError}
      closeDialog={closeDialog}
      completeEscrow={completeEscrow}
      escrowAccess={userGroupsList.includes('escrow-mgr') || userGroupsList.includes('escrow')}
      escrowData={escrowData}
      eventType={eventType}
      gatherEscrowDataOperation={gatherEscrowDataOperation}
      getPromptError={getPromptError}
      handleConfirmDialogBoxButtons={handleConfirmDialogBoxButtons}
      historyData={historyData}
      isAnalysisScreenOpen={isAnalysisScreenOpen}
      isNARqst={isNARqst}
      isRejectSuccessful={isRejectSuccessful}
      onChange={onChange}
      processAction={processActionCommand}
      selectedCapModId={selectedCapModId}
      setActionFromLoanSearch={setActionFromLoanSearch}
      setChecklistCenterPaneData={setChecklistCenterPaneData}
      setEventType={setEventType}
      setOpenAnalysisPopup={toggleAnalysisScreen}
      setRejected={setRejected}
      showConfirmationDialog={showConfirmationDialog}
      showDialog={showDialog}
      showLoaderInPopup={showLoaderInPopup}
      storeAnalysisBreakdownData={storeAnalysisBreakdownData}
      toggleAccountServiceScreen={toggleAccountServiceScreen}
    >
      <UITaskGenerator
        checklistItems={template}
        escrowData={escrowData[ACCOUNT_SERVICE_EVENT_TYPES.ACC_SERVICE]}
        onChange={onChange}
        processAction={processActionCommand}
      />
    </SearchLoanAccountServiceScreen>
  );

  return (
    <>
      {
        !shouldRenderWidgetView() && !showLoader ? (
          <>
            {!isApproved && !isRejected && !isHistoryView && !isNavigatedFromTombstone
              && !inLoanSearchPage && !isActionFromLoanSearch
              && renderAccountServiceScreen()}
            {isApproved && !isHistoryView && renderApprovedScreen()}
            {(isRejected && !isRejectSuccessful) && !isHistoryView && renderRejectScreen()}
            {isNavigatedFromTombstone && !isHistoryView && renderModInfoScreen()}
            {inLoanSearchPage && !isHistoryView && (!isRejected || isRejectSuccessful)
              && !isApproved && renderSearchLoanPageAccountServiceScreen()}
            {isHistoryView && renderHistoryScreen()}
          </>
        ) : (
          <div styleName="widget-indexer">
            {showLoader && <Loader message="Please Wait" />}
            {renderWidgetComponents()}
          </div>
        )
      }
      {!isNavigatedFromTombstone && !inLoanSearchPage && <WidgetBuilder page="UWSTAGER" styleName="widget" />}
      <CustomSnackBar
        message={snackBarData && snackBarData.message}
        onClose={closeSnackBar}
        open={snackBarData && snackBarData.open}
        type={snackBarData && snackBarData.type}
      />
    </>
  );
}

const mapStateToProps = state => ({
  escrowData: accountServiceSelectors.getEscrowData(state),
  analysisBreakdownData: accountServiceSelectors.getAnalysisBreakdownData(state),
  isHistoryView: accountServiceSelectors.isHistoryView(state),
  capModHistoryData: accountServiceSelectors.getCapModHistoryData(state),
  historyData: accountServiceSelectors.getHistoryData(state),
  selectedCapModId: accountServiceSelectors.getSelectedCapModId(state),
  selectedCapModIdHistory: accountServiceSelectors.getSelectedCapModIdHistory(state),
  isAnalysisScreenOpen: accountServiceSelectors.isAnalysisScreenOpen(state),
  isRejectSuccessful: accountServiceSelectors.isRejectSuccessful(state),
  isNewAdhocRqst: accountServiceSelectors.isNewAdhocRqst(state),
  showConfirmationDialog: accountServiceSelectors.showConfirmationDialog(state),
  stagerTaskName: dashboardSelectors.stagerTaskName(state),
  searchLoanTaskResponse: dashboardSelectors.searchLoanTaskResponse(state),
  groupName: dashboardSelectors.groupName(state),
  tombstoneData: tombstoneSelectors.getTombstoneData(state),
  hasValueChange: accountServiceSelectors.hasValueChange(state),
  snackBarData: notificationSelectors.getSnackBarState(state),
  setChecklistCenterPaneData: PropTypes.func.isRequired,
  showDialog: accountServiceSelectors.showDialog(state),
  eventType: accountServiceSelectors.getAccountServiceEventType(state),
  openWidgetList: widgetSelectors.getOpenWidgetList(state),
  evalId: accountServiceSelectors.evalId(state),
  taskId: accountServiceSelectors.taskId(state),
  popupData: dashboardSelectors.getPopupData(state),
  showLoader: accountServiceSelectors.showLoader(state),
  showLoaderInPopup: accountServiceSelectors.showLoaderInPopup(state),
  getPromptError: accountServiceSelectors.getPromptError(state),
  isApproved: accountServiceSelectors.isApproved(state),
  userGroupsList: loginSelectors.getGroupList(state),
  isNARqst: accountServiceSelectors.isNARqst(state),
});

const mapDispatchToProps = dispatch => ({
  processActionCommand: checkListOperations.preProcessChecklistItems(dispatch),
  onChange: accountServiceOperations.onValueChange(dispatch),
  toggleHistoryView: accountServiceOperations.toggleHistoryView(dispatch),
  toggleAnalysisScreen: accountServiceOperations.toggleAnalysisScreen(dispatch),
  completeEscrow: accountServiceOperations.completeEscrow(dispatch),
  handleConfirmDialogBoxButtons: accountServiceOperations.handleConfirmDialogBoxButtons(dispatch),
  closeDialog: accountServiceOperations.closedialog(dispatch),
  toggleAccountServiceScreen: accountServiceOperations.toggleAccountServiceScreen(dispatch),
  getCapModHistoryOperation: accountServiceOperations.getCapModHistoryOperation(dispatch),
  closeSnackBar: notificationOperations.closeSnackBar(dispatch),
  updateDisbursement: accountServiceOperations.updateNetDisbursementOperation(dispatch),
  setChecklistCenterPaneData: tombstoneOperations.setChecklistCenterPaneDataOperation(dispatch),
  gatherEscrowDataOperation: accountServiceOperations.gatherEscrowDataOperation(dispatch),
  setEventType: accountServiceOperations.setEventType(dispatch),
  onAutoSave: dashboardOperations.onAutoSave(dispatch),
  resetValueChange: accountServiceOperations.resetValueChange(dispatch),
  clearInfo: accountServiceOperations.clearInfoOperation(dispatch),
  clearHistoryInfo: accountServiceOperations.clearHistoryInfoOperation(dispatch),
  clearPromptError: accountServiceOperations.clearPromptError(dispatch),
  storeAnalysisBreakdownData: accountServiceOperations.storeAnalysisBreakdownDataOperation(
    dispatch,
  ),
});

AccountServices.defaultProps = {
  isNavigatedFromTombstone: false,
  inLoanSearchPage: false,
};

AccountServices.propTypes = {
  analysisBreakdownData: PropTypes.shape.isRequired,
  capModHistoryData: PropTypes.shape.isRequired,
  clearHistoryInfo: PropTypes.func.isRequired,
  clearInfo: PropTypes.func.isRequired,
  clearPromptError: PropTypes.func.isRequired,
  closeDialog: PropTypes.func.isRequired,
  closeSnackBar: PropTypes.func.isRequired,
  completeEscrow: PropTypes.func.isRequired,
  escrowData: PropTypes.shape.isRequired,
  eventType: PropTypes.string.isRequired,
  gatherEscrowDataOperation: PropTypes.func.isRequired,
  getCapModHistoryOperation: PropTypes.func.isRequired,
  getPromptError: PropTypes.string.isRequired,
  groupName: PropTypes.string.isRequired,
  handleConfirmDialogBoxButtons: PropTypes.func.isRequired,
  hasValueChange: PropTypes.bool.isRequired,
  historyData: PropTypes.arrayOf(PropTypes.shape).isRequired,
  inLoanSearchPage: PropTypes.bool,
  isAnalysisScreenOpen: PropTypes.bool.isRequired,
  isApproved: PropTypes.bool.isRequired,
  isHistoryView: PropTypes.bool.isRequired,
  isNARqst: PropTypes.bool.isRequired,
  isNavigatedFromTombstone: PropTypes.bool,
  isNewAdhocRqst: PropTypes.bool.isRequired,
  isRejectSuccessful: PropTypes.bool.isRequired,
  onAutoSave: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  openWidgetList: PropTypes.arrayOf(PropTypes.string).isRequired,
  popupData: PropTypes.shape({
    cancelButtonText: PropTypes.string,
    clearData: PropTypes.string,
    confirmButtonText: PropTypes.string,
    isOpen: PropTypes.bool,
    level: PropTypes.string,
    message: PropTypes.string,
    onConfirm: PropTypes.func,
    showCancelButton: PropTypes.bool,
    showConfirmButton: PropTypes.bool,
    title: PropTypes.string,
  }).isRequired,
  processActionCommand: PropTypes.func.isRequired,
  resetValueChange: PropTypes.func.isRequired,
  searchLoanTaskResponse: PropTypes.shape.isRequired,
  selectedCapModId: PropTypes.number.isRequired,
  selectedCapModIdHistory: PropTypes.number.isRequired,
  setChecklistCenterPaneData: PropTypes.func.isRequired,
  setEventType: PropTypes.func.isRequired,
  showConfirmationDialog: PropTypes.bool.isRequired,
  showDialog: PropTypes.bool.isRequired,
  showLoader: PropTypes.bool.isRequired,
  showLoaderInPopup: PropTypes.bool.isRequired,
  snackBarData: PropTypes.shape().isRequired,
  stagerTaskName: PropTypes.shape.isRequired,
  storeAnalysisBreakdownData: PropTypes.func.isRequired,
  toggleAccountServiceScreen: PropTypes.func.isRequired,
  toggleAnalysisScreen: PropTypes.func.isRequired,
  toggleHistoryView: PropTypes.func.isRequired,
  tombstoneData: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.any.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ).isRequired,
  updateDisbursement: PropTypes.func.isRequired,
  userGroupsList: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountServices);
