/* eslint-disable array-callback-return */
/* eslint-disable no-param-reassign */
/* eslint-disable import/prefer-default-export */
import {
  all, put, takeEvery, call, select,
} from 'redux-saga/effects';
import * as R from 'ramda';
import * as Api from 'lib/Api';
import { selectors as loginSelectors } from 'ducks/login';
import {
  DEFAULT_ROWS_PER_PAGE, enumActionTypeMap, enumIdTypeMap, bulkActionTypeMap, EVAL_CASE_TYPE_MAP,
} from 'constants/SupportAdmin';
import moment from 'moment-timezone';
import { TEMPLATE_FILE_INFO, UPLOAD_DATA_LIST, enumActionTypeMap as businessEnumActionTypeMap } from 'constants/busniessAdmin';
import { SET_RESULT_OPERATION } from 'ducks/dashboard/types';
import {
  CASE_STATUS, CASE_SUB_STATUS, EVAL_STATUS, EVAL_SUB_STATUS,
} from '../../../containers/SupportAdmin/constants';
import selectors from './selectors';
import {
  TOGGLE_LOADER,
  FETCH_GRID_DATA, SET_SUBSTATUS_REQUEST_OPTIONS,
  SET_SB_HISTORY_GRID_DATA,
  SET_AUTO_COMPLETE_DATA,
  FILTER_RESULTS,
  IS_FILTER_ENABLED,
  FILTER_CRITERIA,
  UNREJECT_REQUEST,
  FETCH_SUBSTATUS_REQUEST_OPTIONS,
  SUBMIT_STATUS_OPTIONS,
  SUBMIT_BULK_STATUS_OPTIONS,
  SET_ID_TYPE,
  SET_RECORDS_INFO,
  EVAL_DISASTER_ID_REQUEST_SAGA,
  GATHER_AUTO_COMPLETE_MATCH,
  FETCH_CASE_TYPE,
  SET_CASE_TYPES,
  SUBMIT_FORCE_CREATE_CASE,
  SUBMIT_FORCE_CREATE_EVAL_CASE,
  SET_BULK_ACTION_RSN_CDS,
  FETCH_BULK_ACTION_RSN_CDS,
  EXECUTE_ACTION,
  FETCH_BUSINESS_ADMIN_GRID_DATA,
  SET_BUSINESS_ADMIN_GRID_DATA,
  FETCH_BUSINESS_ADMIN_UPLOAD_DATA_HISTORY,
  FILTER_BA_RESULTS,
  GATHER_AUTO_COMPLETE_BA_MATCH,
  SET_AUTO_COMPLETE_BA_DATA,
  SUBMIT_MOD_SUPPORT_REQUEST,
  SUBMIT_UPLOAD_SHEET_REQUEST,
  FETCH_MOD_INVESTORS,
  SET_MOD_INVESTORS,
  SET_CASE_STATUS,
  SET_EVAL_STATUS,
  SET_CASE_SUBSTATUS,
  SET_EVAL_SUBSTATUS,
} from './types';


function* handleBadRequest(fetchGridData, actionBody, actionType, payload) {
  yield call(fetchGridData, actionBody);
  yield put({
    type: SET_RESULT_OPERATION,
    payload: {
      level: 'Error',
      status: `Invalid Request for  ${actionType}`,
    },
  });
  yield put({
    type: SET_RECORDS_INFO,
    payload,
  });
  yield put({
    type: TOGGLE_LOADER,
    payload: false,
  });
}

function* gatherGridData(action) {
  try {
    yield put({
      type: TOGGLE_LOADER,
      payload: true,
    });
    yield put({
      type: SET_AUTO_COMPLETE_DATA,
      payload: {
        field: 'serviceNowTicket',
        data: [],
      },
    });
    yield put({
      type: SET_AUTO_COMPLETE_DATA,
      payload: {
        field: 'requestorId',
        data: [],
      },
    });
    const {
      pageNumber, pageSize, idType, unClearRecordsInfo,
    } = action.payload;
    const actionType = yield select(selectors.getSAselectedTab);
    const isFilterEnabled = yield select(selectors.isFilterEnabled);
    const actionTypeDpDwn = yield select(selectors.getActionType);

    const payload = {
      action: enumActionTypeMap[actionType],
      busKeyType: enumIdTypeMap[idType],
    };
    yield put({
      type: SET_ID_TYPE,
      payload: idType,
    });
    if (!unClearRecordsInfo || !isFilterEnabled) {
      yield put({
        type: SET_RECORDS_INFO,
        payload: {},
      });
    }
    yield put({
      type: IS_FILTER_ENABLED,
      payload: false,
    });
    if (payload.action === 'BULK_ACTION' && actionTypeDpDwn) {
      payload.action = enumActionTypeMap[actionTypeDpDwn];
    }
    const response = yield call(Api.callPost, `/api/dataservice/admin/support/history?page=${pageNumber}&pageSize=${pageSize}`, payload);
    if (response) {
      yield put({
        type: SET_SB_HISTORY_GRID_DATA,
        payload: response,
      });
    }
    yield put({
      type: TOGGLE_LOADER,
      payload: false,
    });
  } catch (e) {
    yield put({
      type: SET_SB_HISTORY_GRID_DATA,
      payload: {},
    });
  }
}

function populatePagination(records) {
  return {
    records,
  };
}

function* gatherBusinessAdminGridData(action) {
  try {
    const { page, pageSize } = action.payload;
    yield put({
      type: TOGGLE_LOADER,
      payload: true,
    });
    yield put({
      type: SET_AUTO_COMPLETE_BA_DATA,
      payload: {
        field: 'createdBy',
        data: [],
      },
    });
    const actionType = yield select(selectors.getBaSelectedTab);
    const investorName = yield select(selectors.getInvestorName);
    const payload = {
      action: businessEnumActionTypeMap[actionType],
      busKeyType: 'EMPTY',
    };
    yield put({
      type: IS_FILTER_ENABLED,
      payload: false,
    });
    let response = null;
    if (actionType === 'Update Interest Rate') {
      if (investorName !== '') {
        payload.investorName = investorName;
        response = yield call(Api.callPost,
          `/api/dataservice/admin/business/modSupport/history?page=${page}&pageSize=${pageSize}`, payload);
      } else {
        response = yield call(Api.callGet, '/api/dataservice/admin/business/modSupport/history/updateInterestRate');
        response = yield call(populatePagination, response);
      }
    } else {
      response = yield call(Api.callPost,
        `/api/dataservice/admin/business/modSupport/history?page=${page}&pageSize=${pageSize}`, payload);
    }
    if (response) {
      yield put({
        type: SET_BUSINESS_ADMIN_GRID_DATA,
        payload: response,
      });
    }
  } catch (e) {
    yield put({
      type: SET_BUSINESS_ADMIN_GRID_DATA,
      payload: {},
    });
  } finally {
    yield put({
      type: TOGGLE_LOADER,
      payload: false,
    });
  }
}

function* getSubStatusRequestOptions(action) {
  const requestType = action.payload;
  try {
    const response = yield call(Api.callGet, `/api/dataservice/admin/support/getClassCodes/${requestType}`);

    if (response) {
      if (requestType === CASE_STATUS) {
        yield put({ type: SET_CASE_STATUS, payload: response });
      } else if (requestType === CASE_SUB_STATUS) {
        yield put({ type: SET_CASE_SUBSTATUS, payload: response });
      } else if (requestType === EVAL_STATUS) {
        yield put({ type: SET_EVAL_STATUS, payload: response });
      } else if (requestType === EVAL_SUB_STATUS) {
        yield put({ type: SET_EVAL_SUBSTATUS, payload: response });
      }
    }
  } catch (e) {
    yield put({
      type: SET_SUBSTATUS_REQUEST_OPTIONS,
      payload: [],
    });
  }
}

function* fetchCaseTypes() {
  try {
    const response = yield call(Api.callGet, '/api/dataservice/admin/support/getCaseTypes');
    if (response) {
      yield put({ type: SET_CASE_TYPES, payload: response });
    }
  } catch (e) {
    yield put({
      type: SET_CASE_TYPES,
      payload: [],
    });
  }
}

function* fetchFilteredData(action) {
  try {
    const { filterBy, pageNumber, pageSize } = action.payload;
    const actionType = yield select(selectors.getSAselectedTab);
    filterBy.action = enumActionTypeMap[actionType];
    const { serviceNowTicket } = filterBy;
    const actionTypeDpDwn = yield select(selectors.getActionType);
    if (filterBy.action === 'BULK_ACTION' && actionTypeDpDwn) {
      filterBy.action = enumActionTypeMap[actionTypeDpDwn];
    }
    if (serviceNowTicket) {
      filterBy.ticket = +serviceNowTicket;
      delete filterBy.serviceNowTicket;
    }
    yield put({
      type: TOGGLE_LOADER,
      payload: true,
    });
    const response = yield call(Api.callPost, `/api/dataservice/admin/support/history?page=${pageNumber}&pageSize=${pageSize}`, filterBy);
    if (response) {
      yield put({
        type: IS_FILTER_ENABLED,
        payload: true,
      });
      yield put({
        type: SET_SB_HISTORY_GRID_DATA,
        payload: response,
      });
      yield put({
        type: FILTER_CRITERIA,
        payload: filterBy,
      });
    }
    yield put({
      type: TOGGLE_LOADER,
      payload: false,
    });
  } catch (e) {
    yield put({
      type: SET_SB_HISTORY_GRID_DATA,
      payload: {},
    });
  }
}

function* gatherBusinessAdminUploadDataHistory(action) {
  try {
    const { page, pageSize } = action.payload;
    yield put({
      type: TOGGLE_LOADER,
      payload: true,
    });
    yield put({
      type: SET_AUTO_COMPLETE_BA_DATA,
      payload: {
        field: 'createdBy',
        data: [],
      },
    });
    const actionType = yield select(selectors.getBaSelectedTab);
    const payload = {
      action: businessEnumActionTypeMap[actionType],
    };
    yield put({
      type: IS_FILTER_ENABLED,
      payload: false,
    });
    const response = yield call(Api.callPost,
      `/api/dataservice/admin/business/uploadData/history?page=${page}&pageSize=${pageSize}`, payload);
    if (response) {
      yield put({
        type: SET_BUSINESS_ADMIN_GRID_DATA,
        payload: response,
      });
    }
    yield put({
      type: TOGGLE_LOADER,
      payload: false,
    });
  } catch (e) {
    yield put({
      type: SET_BUSINESS_ADMIN_GRID_DATA,
      payload: {},
    });
  }
}

function* unRejectRequest(action) {
  try {
    const {
      ticket,
      eval: evalId,
      requestorID,
      comments,
      case: caseId,
    } = action.payload;

    const user = yield select(loginSelectors.getUser);
    const userEmail = R.path(['userDetails', 'email'], user);

    const actionType = yield select(selectors.getSAselectedTab);

    const body = {
      serviceNowTicket: ticket,
      id: +evalId || +caseId,
      requestorId: requestorID,
      comments,
      idType: evalId ? 'eval' : 'case',
      user: userEmail,
      action: actionType,
    };
    yield put({
      type: TOGGLE_LOADER,
      payload: true,
    });
    const response = yield call(Api.callPost, '/api/dataservice/admin/support/unRejectRequest', body);
    const actionBody = {
      payload: {
        pageNumber: 0,
        pageSize: DEFAULT_ROWS_PER_PAGE,
        idType: evalId ? 'eval' : 'case',
        unClearRecordsInfo: true,
      },
    };
    if (response) {
      const payload = {
        successCount: R.propOr(0, 'successCount', response),
        failureCount: R.propOr(0, 'failureCount', response),
        unsuccessfulRecords: R.propOr([], 'unsuccessfulRecords', response),
      };
      if (response.status === 400) {
        yield call(handleBadRequest, gatherGridData, actionBody, actionType, payload);
      }
      yield call(gatherGridData, actionBody);
      yield put({
        type: SET_RECORDS_INFO,
        payload,
      });
    }
    yield put({
      type: TOGGLE_LOADER,
      payload: false,
    });
  } catch (e) {
    console.error('Something went wrong while unRejecting the request');
  }
}

function* submitForceCreateCase(action) {
  try {
    const {
      ticket,
      evalId,
      loanId,
      requestorID,
      comments,
      case: caseType,
    } = action.payload;

    const user = yield select(loginSelectors.getUser);
    const userEmail = R.path(['userDetails', 'email'], user);

    const actionType = yield select(selectors.getSAselectedTab);

    const body = {
      serviceNowTicket: ticket,
      id: evalId || loanId,
      requestorID,
      comments,
      idType: evalId ? 'eval' : 'loan',
      user: userEmail,
      actionType,
      caseType,
    };
    yield put({
      type: TOGGLE_LOADER,
      payload: true,
    });
    const response = yield call(Api.callPost, '/api/data-aggregator/admin/support/forceCreateCase', body);
    const actionBody = {
      payload: {
        pageNumber: 0,
        pageSize: DEFAULT_ROWS_PER_PAGE,
        idType: evalId ? 'eval' : 'loan',
        unClearRecordsInfo: true,
      },
    };
    if (response) {
      const payload = {
        successCount: R.propOr(0, 'successCount', response),
        failureCount: R.propOr(0, 'failureCount', response),
        unsuccessfulRecords: R.propOr([], 'unsuccessfulRecords', response),
      };
      if (response.status === 400) {
        yield call(handleBadRequest, gatherGridData, actionBody, actionType, payload);
        return;
      }
      yield call(gatherGridData, actionBody);
      yield put({
        type: SET_RECORDS_INFO,
        payload,
      });
    }
    yield put({
      type: TOGGLE_LOADER,
      payload: false,
    });
  } catch (e) {
    console.error('Something went wrong while submitting force create case request');
  }
}

function* submitForceCreateEvalCase(action) {
  try {
    const {
      ticket,
      loanId,
      requestorID,
      comments,
      evalType,
      preApprovedType,
      evaluationRules,
      caseRules,
    } = action.payload;

    const user = yield select(loginSelectors.getUser);
    const userEmail = R.path(['userDetails', 'email'], user);

    const actionType = yield select(selectors.getSAselectedTab);
    const evalCaseType = EVAL_CASE_TYPE_MAP[evalType];
    const body = {
      serviceNowTicket: ticket,
      id: loanId,
      idType: 'loan',
      requestorID,
      comments,
      user: userEmail,
      actionType,
      evalType: evalCaseType,
      preApprovedType,
      skipEvaluationRules: evaluationRules,
      skipCaseRules: caseRules,
    };
    yield put({
      type: TOGGLE_LOADER,
      payload: true,
    });
    const response = yield call(Api.callPost, '/api/data-aggregator/admin/support/forceCreateEvalCase', body);
    const actionBody = {
      payload: {
        pageNumber: 0,
        pageSize: DEFAULT_ROWS_PER_PAGE,
        idType: 'loan',
        unClearRecordsInfo: true,
      },
    };
    if (response) {
      const payload = {
        successCount: R.propOr(0, 'successCount', response),
        failureCount: R.propOr(0, 'failureCount', response),
        unsuccessfulRecords: R.propOr([], 'unsuccessfulRecords', response),
      };
      if (response.status === 400) {
        yield call(handleBadRequest, gatherGridData, actionBody, actionType, payload);
        return;
      }
      yield call(gatherGridData, actionBody);
      yield put({
        type: SET_RECORDS_INFO,
        payload,
      });
    }
    yield put({
      type: TOGGLE_LOADER,
      payload: false,
    });
  } catch (e) {
    console.error('Something went wrong while submitting force create eval case request');
  }
}

function* submitStatusRequestOptions(action) {
  try {
    yield put({
      type: TOGGLE_LOADER,
      payload: true,
    });
    const { busKeyType } = action.payload;
    const response = yield call(Api.callPost, '/api/dataservice/admin/support/subStatusRequest/changeStatus', action.payload);
    if (response) {
      const actionBody = {
        payload: {
          pageNumber: 0,
          pageSize: DEFAULT_ROWS_PER_PAGE,
          idType: busKeyType,
          unClearRecordsInfo: true,
        },
      };
      const payload = {
        successCount: R.propOr(0, 'successCount', response),
        failureCount: R.propOr(0, 'failureCount', response),
        unsuccessfulRecords: R.propOr([], 'unsuccessfulRecords', response),
      };
      if (response.status === 400) {
        yield call(handleBadRequest, gatherGridData, actionBody, 'Sub Status Change', payload);
        return;
      }
      yield call(gatherGridData, actionBody);
      yield put({
        type: SET_RECORDS_INFO,
        payload,
      });
    }
  } catch (e) {
    yield put({
      type: SET_RECORDS_INFO,
      payload: {},
    });
  }
  yield put({
    type: TOGGLE_LOADER,
    payload: false,
  });
}

function* evalDisasterIdRequestTrigger(action) {
  try {
    const {
      ticket,
      evalId,
      disasterID,
      requestorID,
      comments,
    } = action.payload;

    yield put({
      type: TOGGLE_LOADER,
      payload: true,
    });

    const user = yield select(loginSelectors.getUser);
    const userEmail = R.path(['userDetails', 'email'], user);

    const actionType = yield select(selectors.getSAselectedTab);

    const requestPayload = {
      serviceNowTicket: +ticket,
      evalId,
      disasterId: disasterID,
      requestorId: requestorID,
      comments,
      createdBy: userEmail,
      action: enumActionTypeMap[actionType],
    };
    const response = yield call(Api.callPost, '/api/dataservice/admin/support/evalDisaster', requestPayload);

    const actionBody = {
      payload: {
        pageNumber: 0,
        pageSize: DEFAULT_ROWS_PER_PAGE,
        idType: 'eval',
        unClearRecordsInfo: true,
      },
    };

    if (response) {
      const payload = {
        successCount: R.propOr(0, 'successCount', response),
        failureCount: R.propOr(0, 'failureCount', response),
        unsuccessfulRecords: R.propOr([], 'unsuccessfulRecords', response),
      };
      if (response.status === 400) {
        yield call(handleBadRequest, gatherGridData, actionBody, actionType, payload);
        return;
      }
      yield call(gatherGridData, actionBody);
      yield put({
        type: SET_RECORDS_INFO,
        payload,
      });
    }
  } catch (e) {
    console.error('Something went wrong while updating the request for Eval Disaster Id');
  }
  yield put({
    type: TOGGLE_LOADER,
    payload: false,
  });
}

function* applyBAFilterOptions(action) {
  try {
    const { filterBy, pageNumber, pageSize } = action.payload;
    const isUploadDataFilter = UPLOAD_DATA_LIST.includes(filterBy.action
      || (yield select(selectors.getBaSelectedTab)));
    const actionType = filterBy.action || (yield select(selectors.getBaSelectedTab));
    filterBy.action = businessEnumActionTypeMap[actionType];
    yield put({
      type: TOGGLE_LOADER,
      payload: true,
    });
    const response = yield call(Api.callPost, `/api/dataservice/admin/business/${isUploadDataFilter ? 'uploadData' : 'modSupport'}/history?page=${pageNumber}&pageSize=${pageSize}`, filterBy);
    if (response) {
      yield put({
        type: IS_FILTER_ENABLED,
        payload: true,
      });
      yield put({
        type: SET_BUSINESS_ADMIN_GRID_DATA,
        payload: response,
      });
      yield put({
        type: FILTER_CRITERIA,
        payload: filterBy,
      });
    }
    yield put({
      type: TOGGLE_LOADER,
      payload: false,
    });
  } catch (e) {
    yield put({
      type: SET_BUSINESS_ADMIN_GRID_DATA,
      payload: {},
    });
  }
}

function* submitModSupportRequest(action) {
  const {
    pathVariable, case: caseId, eval: evalId, ...request
  } = action.payload;
  yield put({
    type: TOGGLE_LOADER,
    payload: true,
  });
  try {
    const response = yield call(Api.callPost, '/api/data-aggregator/admin/business/modSupport', request);
    if (response) {
      const actionBody = {
        payload: {
          page: 0,
          pageSize: DEFAULT_ROWS_PER_PAGE,
          unClearRecordsInfo: true,
        },
      };
      const payload = {
        successCount: R.propOr(0, 'successCount', response),
        failureCount: R.propOr(0, 'failureCount', response),
        unsuccessfulRecords: R.propOr([], 'unsuccessfulRecords', response),
      };
      if (response.status === 400) {
        yield call(handleBadRequest, gatherBusinessAdminGridData,
          actionBody, request.action, payload);
        return;
      }
      yield call(gatherBusinessAdminGridData, actionBody);
      yield put({
        type: SET_RECORDS_INFO,
        payload,
      });
    }
  } catch (e) {
    yield put({
      type: SET_RECORDS_INFO,
      payload: {},
    });
  }
  yield put({
    type: TOGGLE_LOADER,
    payload: false,
  });
}


function* gatherAutoCompleteMatch(action) {
  const {
    field,
    inputData,
  } = action.payload;
  try {
    if (!R.isEmpty(inputData)) {
      let actionType = yield select(selectors.getSAselectedTab);
      const actionTypeDpDwn = yield select(selectors.getActionType);
      if (actionType === 'BULK_ACTION' && actionTypeDpDwn) {
        actionType = enumActionTypeMap[actionTypeDpDwn];
      }

      const response = yield call(Api.callPost, '/api/dataservice/admin/support/gatherAutoCompleteMatch', Object.assign({}, action.payload, { actionType }));
      if (R.has('statusCode', response) && response.statusCode === 204) {
        yield put({
          type: SET_AUTO_COMPLETE_DATA,
          payload: {
            field,
            data: [],
          },
        });
        return;
      }
      if (response) {
        yield put({
          type: SET_AUTO_COMPLETE_DATA,
          payload: {
            field,
            data: response,
          },
        });
      } else {
        yield put({
          type: SET_AUTO_COMPLETE_DATA,
          payload: {
            field,
            data: [],
          },
        });
      }
    } else {
      yield put({
        type: SET_AUTO_COMPLETE_DATA,
        payload: {
          field: 'serviceNowTicket',
          data: [],
        },
      });
      yield put({
        type: SET_AUTO_COMPLETE_DATA,
        payload: {
          field: 'requestorId',
          data: [],
        },
      });
    }
  } catch (e) {
    console.error('Something went wrong while gathering matched data');
    yield put({
      type: SET_AUTO_COMPLETE_DATA,
      payload: {
        field,
        data: [],
      },
    });
  }
}

function* submitBulkStatusRequestOptions(actionPayload) {
  try {
    const {
      ticket,
      fromStatus,
      toStatus,
      action,
      ids,
      busKeyType,
      requestorID,
      comments,
    } = actionPayload.payload;

    const user = yield select(loginSelectors.getUser);
    const userEmail = R.path(['userDetails', 'email'], user);
    const userName = R.path(['userDetails', 'name'], user);
    const requestPayload = {
      ticket,
      busKeyType,
      ids,
      toStatus,
      action,
      fromStatus,
      requestorID,
      comments,
      createdBy: userName,
      email: userEmail,
    };
    let response = null;
    const handleForbearanceUpdate = (fromStatus === 'Locked' && toStatus === 'Closed')
    || (fromStatus === 'Approved' && toStatus === 'Closed')
    || (fromStatus === 'Open' && toStatus === 'Closed');
    yield put({
      type: TOGGLE_LOADER,
      payload: true,
    });
    if (busKeyType === 'eval') {
      response = yield call(Api.callPost, '/api/dataservice/admin/support/bulkStatus/eval/changeStatus', requestPayload);
    } else if (busKeyType === 'case') {
      response = yield call(Api.callPost, `/api/${handleForbearanceUpdate ? 'data-aggregator' : 'dataservice'}/admin/support/ChangeCaseStatus`, requestPayload);
    }
    if (response) {
      const actionBody = {
        payload: {
          pageNumber: 0,
          pageSize: DEFAULT_ROWS_PER_PAGE,
          idType: busKeyType,
          unClearRecordsInfo: true,
        },
      };
      const payload = {
        successCount: R.propOr(0, 'successCount', response),
        failureCount: R.propOr(0, 'failureCount', response),
        unsuccessfulRecords: R.propOr([], 'unsuccessfulRecords', response),
      };
      if (response.status === 400) {
        yield call(handleBadRequest, gatherGridData, actionBody, action, payload);
        return;
      }
      yield call(gatherGridData, actionBody);
      yield put({
        type: SET_RECORDS_INFO,
        payload,
      });
    }
  } catch (e) {
    console.error('Something went wrong while upading bulk status', e);
    yield put({
      type: SET_RECORDS_INFO,
      payload: {},
    });
  }
  yield put({
    type: TOGGLE_LOADER,
    payload: false,
  });
}

function* fetchBulkActionReasonCodes() {
  try {
    const response = yield call(Api.callGet, '/api/dataservice/admin/support/fetchReasonCodes');
    if (response) {
      yield put({ type: SET_BULK_ACTION_RSN_CDS, payload: response });
    }
  } catch (e) {
    yield put({
      type: SET_BULK_ACTION_RSN_CDS,
      payload: [],
    });
  }
  yield put({
    type: TOGGLE_LOADER,
    payload: false,
  });
}

function* constructSaveBulkActnPayload(
  caseObj, idType, isSuccess, failureRsn, failureRsns,
) {
  let body = [];
  if (caseObj.Ids.length > 0) {
    const user = yield select(loginSelectors.getUser);
    const userEmail = R.path(['userDetails', 'email'], user);
    const req = {
      serviceNowTicket: caseObj.ticket,
      action: bulkActionTypeMap[caseObj.action],
      reasonCode: caseObj.reasonCode ? caseObj.reasonCode.split(',')[1].trim() : '',
      requestorId: caseObj.requestorID,
      comments: caseObj.comments,
      busKeyType: idType,
      status: isSuccess,
      createdBy: userEmail,
    };

    if (failureRsns === undefined) {
      body = caseObj.Ids.map(id => ({
        businessKeyVal: id,
        failReason: failureRsn,
        ...req,
      }));
    } else {
      body = caseObj.Ids.map((id, index) => ({
        businessKeyVal: id,
        failReason: failureRsns[index],
        ...req,
      }));
    }
  }

  return body;
}

function* rejectAndSaveBulkAction(caseObj, idType, loanNums = [], caseType = '') {
  let oltpBody = null;
  let failedRecords = null;
  let oltpRes = null;
  let uspRes = null;
  try {
    const user = yield select(loginSelectors.getUser);
    const userEmail = R.path(['userDetails', 'email'], user);

    if (idType === 'case') {
      const request = {
        dispositionComment: caseObj.comments,
        userId: caseObj.requestorID,
        caseType,
      };

      const body = caseObj.caseIDs.map((caseId, index) => ({
        caseId,
        loanNumber: loanNums[index],
        ...request,
      }));
      uspRes = yield call(Api.callPost, '/api/disposition/hampRejectCaseWithReason', body);

      failedRecords = R.pathOr([], ['details', 'errors'], uspRes);
    } else { // eval
      const evalIds = caseObj.evalIDs;
      const body = {
        email: userEmail,
        evalIds,
        reasonCode: caseObj.reasonCode.split(',')[0].trim(),
        comment: caseObj.comments,
      };
      uspRes = yield call(Api.callPost, '/api/data-aggregator/admin/support/rejectBulkEvals', body);
      failedRecords = uspRes;
    }

    const ids = idType === 'eval' ? caseObj.evalIDs : caseObj.caseIDs;
    let failedCasesReq = null;
    if (failedRecords !== null && !R.isEmpty(failedRecords)) {
      const failedIds = idType === 'eval' ? failedRecords : failedRecords.map(cases => cases.caseId);
      if (idType === 'eval') {
        failedCasesReq = yield call(
          constructSaveBulkActnPayload, { ...caseObj, Ids: failedIds },
          idType, 0, 'Error occurred while rejecting Eval', undefined,
        );
      } else {
        const failureRsns = failedRecords.map(cases => cases.error);
        failedCasesReq = yield call(constructSaveBulkActnPayload,
          { ...caseObj, Ids: failedIds }, idType, 0, undefined, failureRsns);
      }

      const passedIds = ids.filter(id => !failedIds.includes(id));
      const passedCasesReq = yield call(constructSaveBulkActnPayload, { ...caseObj, Ids: passedIds }, idType, 1, '', undefined);
      oltpBody = [...passedCasesReq, ...failedCasesReq];
    } else if (uspRes !== null && uspRes.statusCode === 200) {
      oltpBody = yield call(constructSaveBulkActnPayload, { ...caseObj, Ids: ids }, idType, 1, '', undefined);
    }

    if (oltpBody !== null && !R.isEmpty(oltpBody)) {
      oltpRes = yield call(Api.callPost, '/api/dataservice/admin/support/saveBulkSupportAdminAudit', oltpBody);
    }
  } catch (e) {
    console.error('Something went wrong while rejecting and saving Bulk Action');
  }
  return oltpRes;
}

function* saveBulkAction(caseObj, idType) {
  let oltpBody = null;
  let failedIds = null;
  let passedIds = null;
  let oltpRes = null;
  try {
    const user = yield select(loginSelectors.getUser);
    const userEmail = R.path(['userDetails', 'email'], user);
    const body = {
      email: userEmail,
      loanIDS: caseObj.loanIDs === '0' ? [] : caseObj.loanIDs,
      caseIDs: caseObj.caseIDs === '0' ? [] : caseObj.caseIDs,
      action: caseObj.action,
    };
    const ids = idType === 'loan' ? caseObj.loanIDs : caseObj.caseIDs;

    failedIds = yield call(Api.callPost, '/api/data-aggregator/admin/support/bulkActionDoor', body);
    passedIds = ids.filter(id => !failedIds.includes(id));
    const failedCasesReq = yield call(constructSaveBulkActnPayload,
      { ...caseObj, Ids: failedIds }, idType, 0, 'Failed to Update Forbearance Details', undefined);

    const passedCasesReq = yield call(constructSaveBulkActnPayload,
      { ...caseObj, Ids: passedIds }, idType, 1, '', undefined);
    oltpBody = [...passedCasesReq, ...failedCasesReq];
    if (oltpBody !== null && !R.isEmpty(oltpBody)) {
      oltpRes = yield call(Api.callPost, '/api/dataservice/admin/support/saveBulkSupportAdminAudit', oltpBody);
    }
  } catch (e) {
    console.error('Something went wrong while saveBulkAction');
  }
  return oltpRes;
}


function* executeAction(action) {
  yield put({
    type: TOGGLE_LOADER,
    payload: true,
  });
  try {
    let caseObj = action.payload;
    const isValidNumber = val => val !== null && val.trim() !== '';
    const idType = yield select(selectors.getIdType);
    if (idType === 'case') {
      caseObj = { ...caseObj, caseIDs: caseObj.caseIDs.split('\n').filter(isValidNumber).map(Number) };
    } else if (idType === 'loan') {
      caseObj = { ...caseObj, loanIDs: caseObj.loanIDs.split('\n').filter(isValidNumber).map(Number) };
    } else {
      caseObj = { ...caseObj, evalIDs: caseObj.evalIDs.split('\n').filter(isValidNumber).map(Number) };
    }
    let isValid = true;
    const { caseIDs, reasonCode } = caseObj;
    let failureRsn = null;
    let valResponse = null;
    let oltpResponse = null;
    const rejectBulkActions = ['reject-eval-with-reason', 'reject-case-with-reason'];
    if (rejectBulkActions.includes(caseObj.action)) {
      // caseIds Validation
      if (idType === 'case') {
        valResponse = yield call(Api.callPost, '/api/dataservice/admin/support/validateCaseIds', caseIDs);
        if (valResponse) {
          isValid = R.propOr(false, 'isCaseIdsValid', valResponse);
          if (isValid) {
            const reasonCodesList = R.propOr([], 'eligibleReasonCodes', valResponse);
            if (!reasonCodesList.includes(reasonCode.split(',')[1].trim())) {
              failureRsn = 'Reason Code does belong to the Appropriate Case Type';
              isValid = false;
            }
          } else {
            failureRsn = 'CaseIds are not of the same Case Type';
          }
        }
      }

      if (!isValid) {
        const body = yield call(
          constructSaveBulkActnPayload, { ...caseObj, Ids: caseObj.caseIDs },
          idType, 0, failureRsn, undefined,
        );
        oltpResponse = yield call(Api.callPost, '/api/dataservice/admin/support/saveBulkSupportAdminAudit', body);
      } else {
        console.log('Started to Reject and Save Bulk Action');
        if (idType === 'case') {
          const { loanNumbers, caseType } = valResponse;
          oltpResponse = yield call(rejectAndSaveBulkAction,
            caseObj, idType, loanNumbers, caseType);
        } else {
          oltpResponse = yield call(rejectAndSaveBulkAction, caseObj, idType);
        }
      }
    } else {
      oltpResponse = yield call(saveBulkAction, caseObj, idType);
    }

    const actionBody = {
      payload: {
        pageNumber: 0,
        pageSize: DEFAULT_ROWS_PER_PAGE,
        idType,
        unClearRecordsInfo: true,
      },
    };
    if (oltpResponse) {
      const payload = {
        successCount: R.propOr(0, 'successCount', oltpResponse),
        failureCount: R.propOr(0, 'failureCount', oltpResponse),
        unsuccessfulRecords: R.propOr([], 'unsuccessfulRecords', oltpResponse),
      };

      if (oltpResponse.status === 400 || oltpResponse.status === 500) {
        yield call(handleBadRequest, gatherGridData, actionBody, 'Bulk Action', payload);
        return;
      }

      yield call(gatherGridData, actionBody);
      yield put({
        type: SET_RECORDS_INFO,
        payload,
      });
    }
  } catch (e) {
    console.error('Something went wrong while executing Bulk Action');
  }
  yield put({
    type: TOGGLE_LOADER,
    payload: false,
  });
}

function* gatherAutoSuggestBAData(action) {
  const {
    field,
    inputData,
  } = action.payload;
  try {
    if (!R.isEmpty(inputData)) {
      const actionType = yield select(selectors.getBaSelectedTab);
      const response = yield call(Api.callPost, '/api/dataservice/admin/business/filterByName', Object.assign({}, action.payload, { actionType: R.equals(actionType, 'All') ? '' : actionType }));
      if (R.has('statusCode', response) && response.statusCode === 204) {
        yield put({
          type: SET_AUTO_COMPLETE_BA_DATA,
          payload: {
            field,
            data: [],
          },
        });
        return;
      }
      yield put({
        type: SET_AUTO_COMPLETE_BA_DATA,
        payload: {
          field,
          data: response || [],
        },
      });
    } else {
      yield put({
        type: SET_AUTO_COMPLETE_BA_DATA,
        payload: {
          field: 'createdBy',
          data: [],
        },
      });
    }
  } catch (e) {
    console.error('Something went wrong while gathering matched data');
    yield put({
      type: SET_AUTO_COMPLETE_BA_DATA,
      payload: {
        field,
        data: [],
      },
    });
  }
}

const checkSuccessfulRecordsForEscrow = function*
checkSuccessfulRecordsForEscrow(action) {
  const { inputFileRecords } = R.propOr([], 'payload', action);
  const { createdBy } = R.propOr('', 'payload', action);
  const successfullRecords = [];
  const updatedOutputFileObject = [];
  let failedRecordsCount = 0;
  let successfullRecordsCount = 0;
  const currentDate = new Date();
  const sixMonthsAfterNow = new Date(new Date().setMonth(currentDate.getMonth() + 6));
  inputFileRecords.forEach((record) => {
    let modEffectiveDate = R.propOr('', 'modEffectiveDate', record);
    modEffectiveDate = new Date(modEffectiveDate);
    if (modEffectiveDate <= sixMonthsAfterNow) {
      successfullRecords.push(record);
    } else {
      record.status = 'Unsuccessful';
      record.reason = 'ModEffectiveDate greater than 6 months from current date.';
      updatedOutputFileObject.push(record);
      failedRecordsCount += 1;
    }
  });
  const loanNumbers = [];
  if (successfullRecords.length > 0) {
    successfullRecords.forEach((resp) => {
      loanNumbers.push(resp.loanId.toString());
    });
    const sodsResponse = yield call(Api.callPost,
      '/api/ods-gateway/stager/loans', { loanNumbers });
    if (!(Object.keys(sodsResponse).includes('error'))) {
      // eslint-disable-next-line array-callback-return, consistent-return
      const responseWithBrand = successfullRecords.filter((item) => {
        let brand;
        const matchingRecord = sodsResponse.filter(resp => resp.loanNumber
          === item.loanId.toString());
        if (matchingRecord.length < 1) {
          item.status = 'Unsuccessful';
          item.reason = 'Invalid loanNumber';
          updatedOutputFileObject.push(item);
          failedRecordsCount += 1;
        }
        matchingRecord.forEach((record) => {
          brand = record.brandName;
        });
        if (brand) {
          item.brand = brand;
          return item;
        }
      });
      const escrowToOrderBulkSubmitPayLoad = responseWithBrand.map(item => ({
        loanNumber: item.loanId,
        modEffectiveDate: new Date(moment(R.propOr(null,
          'modEffectiveDate', item)).format('MM/DD/YYYY')),
        requestSpread: item.requestSpread,
        requestQuoteType: item.requestQuoteType,
        brand: item.brand,
        userEmail: createdBy,
        delinquentTaxes: item.delinquentTaxes,
        delinquentInsurance: item.delinquentInsurance,
        unpaidLegalFees: item.unpaidLegalFees,
      }));
      const escrowToOrderBulkSubmitResponse = yield call(Api.callPost,
        '/api/escrow/escrow/escrowToOrderBulkSubmit', escrowToOrderBulkSubmitPayLoad);
      // TODO - should call refresh net disbursement endpoint
      successfullRecords.forEach((record) => {
        const matchingRecord = escrowToOrderBulkSubmitResponse.filter(resp => resp.loanNumber
          === record.loanId.toString());
        if (matchingRecord.length > 0) {
          if (matchingRecord[0].status === 'Successful') {
            successfullRecordsCount += 1;
            record.status = 'Successful';
            record.reason = '';
          } else {
            record.reason = matchingRecord[0].failureReason;
            record.status = 'Unsuccessful';
            failedRecordsCount += 1;
          }
          updatedOutputFileObject.push(record);
        }
      });
    } else {
      // eslint-disable-next-line array-callback-return
      successfullRecords.filter((record) => {
        record.status = 'Unsuccessful';
        record.reason = 'Invalid loanNumber';
        updatedOutputFileObject.push(record);
      });
    }
  }
  const returnPayload = { successfullRecordsCount, failedRecordsCount, updatedOutputFileObject };
  return returnPayload;
};

const checkSuccessfulRecordsForEval = function*
checkSuccessfulRecordsForEval(action) {
  const { inputFileRecords } = R.propOr([], 'payload', action);
  const updatedOutputFileObject = [];
  let failedRecordsCount = 0;
  let successfullRecordsCount = 0;
  const evalPayload = [];
  const loanNumbers = [];
  const user = yield select(loginSelectors.getUser);
  const userEmail = R.path(['userDetails', 'email'], user);

  inputFileRecords.forEach((data) => {
    if (data.loanId) {
      loanNumbers.push(data.loanId.toString());
    }
  });
  const sodsResponse = yield call(Api.callPost,
    '/api/ods-gateway/stager/loans', { loanNumbers });
  if (!(Object.keys(sodsResponse).includes('error'))) {
    inputFileRecords.forEach((item) => {
      let brand;
      const matchingRecord = sodsResponse.filter(resp => resp.loanNumber
        === item.loanId.toString());
      if (matchingRecord.length < 1) {
        item.status = 'Unsuccessful';
        item.reason = 'Invalid loanNumber';
        updatedOutputFileObject.push(item);
        failedRecordsCount += 1;
      }
      matchingRecord.forEach((record) => {
        brand = record.brandName;
      });
      if (brand) {
        evalPayload.push(
          {
            loanId: item.loanId,
            brandName: brand,
            userEmail,
            skipEvalRules: R.propOr('', 'isSkipRejectionRules', item).toLowerCase() === 'yes',
            isInflight: R.propOr('', 'isInflight', item).toLowerCase() === 'yes',
            isPreApproved: R.propOr('', 'isPreApproved', item).toLowerCase() === 'yes',
            isNonDelegated: R.propOr('', 'isNonDelegated', item).toLowerCase() === 'yes',
          },
        );
      }
    });
    const createEvalResponse = yield call(Api.callPost,
      '/api/data-aggregator/admin/createEval', evalPayload);
    inputFileRecords.forEach((record) => {
      const matchingRecord = createEvalResponse.filter(resp => resp.loanNumber.toString()
        === record.loanId.toString());
      const responseMessage = (matchingRecord.length > 0) && R.pathOr('', ['createCaseResponse', 'responseMessage'], matchingRecord[0]);
      if (responseMessage === 'Success') {
        successfullRecordsCount += 1;
        record.status = 'Successful';
        record.reason = '';
      } else {
        record.reason = responseMessage;
        record.status = 'Unsuccessful';
        failedRecordsCount += 1;
      }
      updatedOutputFileObject.push(record);
    });
  } else {
    // eslint-disable-next-line array-callback-return
    inputFileRecords.filter((record) => {
      record.status = 'Unsuccessful';
      record.reason = 'Invalid loanNumber';
      updatedOutputFileObject.push(record);
    });
  }
  const returnPayload = { successfullRecordsCount, failedRecordsCount, updatedOutputFileObject };
  return returnPayload;
};

function* checkSuccessfulRecordsForRepay(action) {
  const { inputFileRecords } = R.propOr([], 'payload', action);
  const { uploadHistory } = R.propOr('', 'payload', action);
  const loanIds = {
    loanNumbers: R.flatten(R.map(x => R.props(['loanId'], x).toString(), inputFileRecords)),
  };
  const getLoanInfo = yield call(Api.callPost, '/api/ods-gateway/loans', loanIds);
  const updatedOutputFileObject = [];
  let failedRecordsCount = 0;
  let successfullRecordsCount = 0;
  const getAllSuccessLoanIds = [];
  const filterSameDayRecords = uploadHistory.records.filter(val => moment().isSame(moment(val.createdAt), 'day'));
  if (!R.isEmpty(filterSameDayRecords)) {
    filterSameDayRecords.forEach((item) => {
      JSON.parse(item.outputFileJson || '[]').forEach((val) => {
        if (val.status === 'Successful') getAllSuccessLoanIds.push(val.loanId);
      });
    });
  }
  const sodsLoanList = getLoanInfo && getLoanInfo.map(ln => +ln.loanNumber);
  // eslint-disable-next-line consistent-return
  inputFileRecords.forEach((record) => {
    if (getAllSuccessLoanIds.includes(record.loanId)) {
      record.status = 'Unsuccessful';
      record.reason = 'The Request already exists in RepayAutoExtension.';
      failedRecordsCount += 1;
    } else if (getLoanInfo && sodsLoanList.includes(record.loanId)) {
      const getUpbAmount = getLoanInfo.filter(lNumber => lNumber.loanNumber
        === record.loanId.toString());
      if (getUpbAmount[0].upbAmount > 0) {
        successfullRecordsCount += 1;
        record.status = 'Successful';
        record.reason = '';
      } else {
        record.status = 'Unsuccessful';
        record.reason = 'Loan UPB Amount must be greater than zero. Please correct it and try again.';
        failedRecordsCount += 1;
      }
    } else {
      record.status = 'Unsuccessful';
      record.reason = 'Invalid LoanID';
      failedRecordsCount += 1;
    }
    updatedOutputFileObject.push(record);
  });

  const returnPayload = { successfullRecordsCount, failedRecordsCount, updatedOutputFileObject };
  return returnPayload;
}


function* checkSuccessfulRecordsForCaseUpdate(action) {
  const { inputFileRecords } = R.propOr([], 'payload', action);
  const response = yield call(Api.callPost('/api/data-aggregator/eval/caseUpdate', inputFileRecords));
  let returnPayload = {
    successfulRecordsCount: 0,
    failedRecordsCount: 0,
    updatedOutputFileObject: [],
  };
  if (response) {
    const { successfulRecordsCount, failedRecordsCount, updatedOutputFileObject } = response;
    returnPayload = { successfulRecordsCount, failedRecordsCount, updatedOutputFileObject };
  }
  return returnPayload;
}

function* checkSuccessfulRecordsForSMDUManual(action) {
  let successfullRecordsCount = 0;
  const updatedOutputFileObject = [];
  let failedRecordsCount = 0;
  const { inputFileRecords } = R.propOr([], 'payload', action);
  const { uploadHistory } = R.propOr([], 'payload', action);
  const { fileName } = R.propOr('', 'payload', action);
  const { bussAdminUpldId } = R.propOr('', 'payload', action);
  const payload = {
    inputFileRecords,
    fileName,
    bussAdminUpldId,
  };

  const getAllSuccessLoanIds = [];
  const filterSameDayRecords = uploadHistory.records.filter(val => moment().isSame(moment(val.createdAt), 'day'));
  if (!R.isEmpty(filterSameDayRecords)) {
    filterSameDayRecords.forEach((item) => {
      JSON.parse(item.outputFileJson || '[]').forEach((val) => {
        if (val.status === 'Successful') getAllSuccessLoanIds.push(val.loanId);
      });
    });
  }

  inputFileRecords.forEach((record) => {
    if (getAllSuccessLoanIds.includes(record.loanId)) {
      record.status = 'Unsuccessful';
      record.reason = 'The Request already exists in SmduBatchManualRequest.';
      failedRecordsCount += 1;
    } else {
      successfullRecordsCount += 1;
      record.status = 'Successful';
      record.reason = '';
    }
    updatedOutputFileObject.push(record);
  });

  yield call(Api.callPost, '/api/dataservice/admin/updateSMDUBatchManualRequest', payload);

  const returnPayload = { successfullRecordsCount, failedRecordsCount, updatedOutputFileObject };
  return returnPayload;
}

function* submitUploadSheetData(action) {
  const { actionType, sheetData } = action.payload;
  const sheet = sheetData[0][TEMPLATE_FILE_INFO[actionType].fileName];
  const uploadHistory = yield select(selectors.getBusinessAdminGridData);
  const createdBy = yield select(loginSelectors.getUserPrincipalName);
  const createdAt = new Date();
  const inputFileRecords = sheet.slice(1).map((values) => {
    const obj = {};
    sheet[0].forEach((key, index) => {
      obj[key.substring(0, 1).toLowerCase() + key.substring(1)] = values[index];
    });
    return obj;
  });
  const saveBusinessAdminUploadDataPayload = {
    fileName: TEMPLATE_FILE_INFO[actionType].fileName,
    importStatus: 'InProgress',
    totalRecords: inputFileRecords.length,
    createdBy,
    createdAt,
    inputFileJson: JSON.stringify(inputFileRecords),
    templateType: actionType,
  };
  try {
    const response = yield call(Api.callPost, '/api/dataservice/admin/saveBusinessAdminUploadData', saveBusinessAdminUploadDataPayload);
    if (response) {
      const reponseData = { ...uploadHistory, records: [...uploadHistory.records, response] };
      yield put({
        type: SET_BUSINESS_ADMIN_GRID_DATA,
        payload: reponseData,
      });
    }
    yield put({
      type: TOGGLE_LOADER,
      payload: false,
    });
    const busUploadAuditId = R.propOr(null, 'id', response);

    const action1 = {
      payload: {
        inputFileRecords,
        createdBy,
        uploadHistory,
      },
    };

    const smduPayload = {
      payload: {
        inputFileRecords,
        uploadHistory,
        fileName: Object.keys(sheetData[0])[0],
        bussAdminUpldId: busUploadAuditId,
      },
    };
    let functionName = '';
    switch (actionType) {
      case 'Escrow': {
        functionName = checkSuccessfulRecordsForEscrow;
        break;
      }
      case 'Eval': {
        functionName = checkSuccessfulRecordsForEval;
        break;
      }
      case 'Repay Auto Extend': {
        functionName = checkSuccessfulRecordsForRepay;
        break;
      }
      case 'Case Update': {
        functionName = checkSuccessfulRecordsForCaseUpdate;
        break;
      }
      case 'SMDU Manual': {
        functionName = checkSuccessfulRecordsForSMDUManual;
        break;
      }
      default: {
        functionName = '';
        break;
      }
    }
    const functionPayload = actionType === 'SMDU Manual' ? smduPayload : action1;

    const {
      successfullRecordsCount, failedRecordsCount,
      updatedOutputFileObject,
    } = yield call(functionName, functionPayload);

    const importStatus = successfullRecordsCount === inputFileRecords.length ? 'Completed Successfully' : 'Unsuccessful';
    const updateBusinessAdminPayload = {
      id: busUploadAuditId,
      importStatus,
      outputFileJson: JSON.stringify(updatedOutputFileObject),
      successRecords: successfullRecordsCount,
      failedRecords: failedRecordsCount,
      modifiedBy: createdBy,
    };
    const action2 = {
      payload: {
        page: 0,
        pageSize: DEFAULT_ROWS_PER_PAGE,
      },
    };
    const isRecordSaved = yield call(Api.callPost, '/api/dataservice/admin/updateBusinessAdminUploadData', updateBusinessAdminPayload);
    const resp = yield call(gatherBusinessAdminUploadDataHistory, action2);
    if (isRecordSaved && resp) {
      yield put({
        type: SET_BUSINESS_ADMIN_GRID_DATA,
        payload: resp,
      });
    }
    yield put({
      type: TOGGLE_LOADER,
      payload: false,
    });
    const unsuccessfulRecords = R.filter(R.identity, R.map(arr => !R.isEmpty(arr.reason) && R.pick(['loanId', 'reason'], arr), updatedOutputFileObject));
    const emailPayload = {
      successCount: successfullRecordsCount,
      failureCount: failedRecordsCount,
      action: actionType,
      environment: process.env.NODE_ENV,
      fileName: TEMPLATE_FILE_INFO[actionType].fileName,
      status: importStatus,
      unsuccessfulRecords: successfullRecordsCount === inputFileRecords.length
        ? [] : unsuccessfulRecords,
      userEmail: createdBy,
    };
    yield call(Api.callPost, '/api/data-aggregator/admin/business/sendEmail', emailPayload);
  } catch (e) {
    yield put({
      type: SET_BUSINESS_ADMIN_GRID_DATA,
      payload: {},
    });
  }
}

function* fetchModInvestors() {
  try {
    const response = yield call(Api.callGet,
      '/api/dataservice/admin/business/modSupport/investors');
    yield put({
      type: SET_MOD_INVESTORS,
      payload: response || [],
    });
  } catch (e) {
    console.error('Something went wrong while fetching mod investors', e);
    yield put({
      type: SET_MOD_INVESTORS,
      payload: [],
    });
  }
}


function* watchFetchGridDetails() {
  yield takeEvery(FETCH_GRID_DATA, gatherGridData);
}

function* watchFetchSubStatusRequestOptions() {
  yield takeEvery(FETCH_SUBSTATUS_REQUEST_OPTIONS, getSubStatusRequestOptions);
}

function* watchSubmitStatusRequestOptions() {
  yield takeEvery(SUBMIT_STATUS_OPTIONS, submitStatusRequestOptions);
}

function* watchSubmitBulkStatusRequestOptions() {
  yield takeEvery(SUBMIT_BULK_STATUS_OPTIONS, submitBulkStatusRequestOptions);
}

function* watchFilterResults() {
  yield takeEvery(FILTER_RESULTS, fetchFilteredData);
}

function* watchUnRejectRequest() {
  yield takeEvery(UNREJECT_REQUEST, unRejectRequest);
}

function* watchEvalDisasterIdRequestTrigger() {
  yield takeEvery(EVAL_DISASTER_ID_REQUEST_SAGA, evalDisasterIdRequestTrigger);
}

function* watchGatherAutoCompleteMatch() {
  yield takeEvery(GATHER_AUTO_COMPLETE_MATCH, gatherAutoCompleteMatch);
}

function* watchGatherCaseTypes() {
  yield takeEvery(FETCH_CASE_TYPE, fetchCaseTypes);
}

function* watchSubmitForceCreateCase() {
  yield takeEvery(SUBMIT_FORCE_CREATE_CASE, submitForceCreateCase);
}

function* watchSubmitForceCreateEvalCase() {
  yield takeEvery(SUBMIT_FORCE_CREATE_EVAL_CASE, submitForceCreateEvalCase);
}

function* watchFetchBulkActionReasonCodes() {
  yield takeEvery(FETCH_BULK_ACTION_RSN_CDS, fetchBulkActionReasonCodes);
}

function* watchExecuteAction() {
  yield takeEvery(EXECUTE_ACTION, executeAction);
}

function* watchFetchBusinessAdminGridData() {
  yield takeEvery(FETCH_BUSINESS_ADMIN_GRID_DATA, gatherBusinessAdminGridData);
}

function* watchFetchBusinessAdminUploadDataHistory() {
  yield takeEvery(FETCH_BUSINESS_ADMIN_UPLOAD_DATA_HISTORY, gatherBusinessAdminUploadDataHistory);
}

function* watchApplyBAFilter() {
  yield takeEvery(FILTER_BA_RESULTS, applyBAFilterOptions);
}

function* watchGatherAutoSuggestBAData() {
  yield takeEvery(GATHER_AUTO_COMPLETE_BA_MATCH, gatherAutoSuggestBAData);
}

function* watchModSupportRequest() {
  yield takeEvery(SUBMIT_MOD_SUPPORT_REQUEST, submitModSupportRequest);
}

function* watchSubmitUploadSheetData() {
  yield takeEvery(SUBMIT_UPLOAD_SHEET_REQUEST, submitUploadSheetData);
}

function* watchModInvestors() {
  yield takeEvery(FETCH_MOD_INVESTORS, fetchModInvestors);
}

export function* combinedSaga() {
  yield all([
    watchFetchGridDetails(),
    watchFilterResults(),
    watchUnRejectRequest(),
    watchFetchSubStatusRequestOptions(),
    watchSubmitStatusRequestOptions(),
    watchSubmitBulkStatusRequestOptions(),
    watchEvalDisasterIdRequestTrigger(),
    watchGatherAutoCompleteMatch(),
    watchGatherCaseTypes(),
    watchSubmitForceCreateCase(),
    watchSubmitForceCreateEvalCase(),
    watchFetchBulkActionReasonCodes(),
    watchExecuteAction(),
    watchFetchBusinessAdminGridData(),
    watchFetchBusinessAdminUploadDataHistory(),
    watchApplyBAFilter(),
    watchGatherAutoSuggestBAData(),
    watchModSupportRequest(),
    watchSubmitUploadSheetData(),
    watchModInvestors(),
  ]);
}
