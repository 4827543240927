/* eslint-disable import/prefer-default-export */
const FETCH_DOCUMENT_DATA = 'app/document/FETCH_DOCUMENT_DATA';
const RADIO_SELECT = 'app/document/RADIO_SELECT';
const FETCH_FILENET_DOCUMENTS = 'app/documents/FETCH_DOCUMENTS';
const LINK_DOCUMENTS = 'app/documents/LINK_DOCUMENTS';
const LINK_DOCUMENTS_SAGA = 'app/documents/LINK_DOCUMENTS_SAGA';
const BORRORWERS_NAMES = 'app/documents/BORRORWERS_NAMES';
const BORRORWERS_NAMES_SAGA = 'app/documents/BORRORWERS_NAMES_SAGA';
const UNLINK_DOCUMENTS = 'app/documents/UNLINK_DOCUMENTS';
const UNLINK_DOCUMENTS_SAGA = 'app/documents/UNLINK_DOCUMENTS_SAGA';
const SET_TAG_SAGA = 'app/documents/SET_TAG_SAGA';
const SET_TAG = 'app/documents/SET_TAG';
const DOC_REVIEW_STATUS_DROPDOWN = 'app/documents/DOC_REVIEW_STATUS_DROPDOWN';
const SAVE_DOC_REVIEW_STATUS_DROPDOWN = 'app/documents/SAVE_DOC_REVIEW_STATUS_DROPDOWN';
const FETCH_FILENET_DATA = 'app/incomecal/FETCH_FILENET_DATA';
const SET_FILENET_DATA = 'app/incomecal/SET_FILENET_DATA';
const SET_FILTER_START_DATE = 'app/documents/SET_FILTER_START_DATE';
const SET_FILTER_END_DATE = 'app/documents/SET_FILTER_END_DATE';
const SET_FILTER_DOC_CAT = 'app/documents/SET_FILTER_DOC_CAT';
const SAVE_FILENET_DOC_CAT = 'app/documents/SAVE_FILENET_DOC_CAT';
const UPLOADED_FILES = 'app/documents/UPLOADED_FILES';
const SAVE_FILENET_DOC_TYPE = 'app/documents/SAVE_FILENET_DOC_TYPE';
const DOCUMENT_DETAILS_CHANGE = 'app/documents/DOCUMENT_DETAILS_CHANGE';
const DOCUMENT_DETAILS_CHANGE_SAGA = 'app/documents/DOCUMENT_DETAILS_CHANGE_SAGA';
const FETCH_DOC_TXNS = 'app/documents/FETCH_DOC_TXNS';
const DOC_CHECKLIST_DATA = 'app/documents/DOC_CHECKLIST_DATA';
const SAVE_DOC_CHECKLIST_DATA = 'app/documents/SAVE_DOC_CHECKLIST_DATA';
const TRIGGER_DOC_VALIDATION = 'app/documents/TRIGGER_DOC_VALIDATION';
const SET_ERROR_FIELDS = 'app/documents/SET_ERROR_FIELDS';
const DEFECT_REASON_DROPDOWN = '/app/documents/DEFECT_REASON_DROPDOWN';
const SAVE_DEFECT_REASON_DROPDOWN = '/app/documents/SAVE_DEFECT_REASON_DROPDOWN';
const REMOVE_UPLOADED_FILE = '/app/documents/REMOVE_UPLOADED_FILE';
const DOC_CHECKLIST_ADD_CONTRIBUTOR = '/app/documents/DOC_CHECKLIST_ADD_CONTRIBUTOR';
const SET_SELECTED_BORROWER = '/app/documents/SET_SELECTED_BORROWER';
const FETCH_DOC_HISTORY = '/app/documents/FETCH_DOC_HISTORY';
const SET_DOC_HISTORY = '/app/documents/SET_DOC_HISTORY';
const RESET_DOC_CHK_DATA = '/app/documents/RESET_DOC_CHK_DATA';
const SET_LINK_DOC_STATUS = '/app/documents/SET_LINK_DOC_STATUS';
const SET_INITIAL_DOC_CHK_DATA = '/app/documents/SET_INITIAL_DOC_CHK_DATA';
const RESET_FILENET_DOC_TYPES = '/app/documents/RESET_FILENET_DOC_TYPES';
const FETCH_FILENET_CAT_TYPES = '/app/documents/FETCH_FILENET_CAT_TYPES';
const SET_FILENET_CAT_TYPES = '/app/documents/SET_FILENET_CAT_TYPES';
const FETCH_FILENET_TYPES = '/app/documents/FETCH_FILENET_TYPES';
const LOADER = '/app/documents/LOADER';
const DOC_CHK_SAVE_SUCCESS = '/app/documents/DOC_CHK_SAVE_SUCCESS';
const FETCH_BORROWERS = '/app/documents/FETCH_BORROWERS';
const DOC_CHECKLIST_EDIT_CONTRIBUTOR = '/app/documents/DOC_CHECKLIST_EDIT_CONTRIBUTOR';
const DOC_CHECKLIST_DELETE_CONTRIBUTOR = '/app/documents/DOC_CHECKLIST_DELETE_CONTRIBUTOR';
const DOC_CHECKLIST_WIDGET_SAVE = '/app/documents/DOC_CHECKLIST_WIDGET_SAVE';
const DOC_WIDGET_SAVE_SUCCESS = '/app/documents/DOC_WIDGET_SAVE_SUCCESS';
const UNSAVED_DOC_WIDGET_CHANGES = '/app/documents/UNSAVED_DOC_WIDGET_CHANGES';

export {
  FETCH_FILENET_DATA,
  SET_FILENET_DATA,
  FETCH_DOCUMENT_DATA,
  RADIO_SELECT,
  FETCH_FILENET_DOCUMENTS,
  LINK_DOCUMENTS,
  LINK_DOCUMENTS_SAGA,
  BORRORWERS_NAMES,
  BORRORWERS_NAMES_SAGA,
  UNLINK_DOCUMENTS,
  UNLINK_DOCUMENTS_SAGA,
  SET_TAG_SAGA,
  SET_TAG,
  DOC_REVIEW_STATUS_DROPDOWN,
  SAVE_DOC_REVIEW_STATUS_DROPDOWN,
  SET_FILTER_START_DATE,
  SET_FILTER_END_DATE,
  SET_FILTER_DOC_CAT,
  SAVE_FILENET_DOC_CAT,
  UPLOADED_FILES,
  SAVE_FILENET_DOC_TYPE,
  DOCUMENT_DETAILS_CHANGE,
  DOCUMENT_DETAILS_CHANGE_SAGA,
  FETCH_DOC_TXNS,
  DOC_CHECKLIST_DATA,
  SAVE_DOC_CHECKLIST_DATA,
  TRIGGER_DOC_VALIDATION,
  SET_ERROR_FIELDS,
  DEFECT_REASON_DROPDOWN,
  SAVE_DEFECT_REASON_DROPDOWN,
  REMOVE_UPLOADED_FILE,
  DOC_CHECKLIST_ADD_CONTRIBUTOR,
  SET_SELECTED_BORROWER,
  FETCH_DOC_HISTORY,
  SET_DOC_HISTORY,
  RESET_DOC_CHK_DATA,
  SET_LINK_DOC_STATUS,
  SET_INITIAL_DOC_CHK_DATA,
  RESET_FILENET_DOC_TYPES,
  FETCH_FILENET_CAT_TYPES,
  SET_FILENET_CAT_TYPES,
  FETCH_FILENET_TYPES,
  LOADER,
  DOC_CHK_SAVE_SUCCESS,
  FETCH_BORROWERS,
  DOC_CHECKLIST_EDIT_CONTRIBUTOR,
  DOC_CHECKLIST_DELETE_CONTRIBUTOR,
  DOC_CHECKLIST_WIDGET_SAVE,
  DOC_WIDGET_SAVE_SUCCESS,
  UNSAVED_DOC_WIDGET_CHANGES,
};
