/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-closing-tag-location */
import React from 'react';
import moment from 'moment-timezone';
import Validators from 'lib/Validators';
import EditIcon from 'components/Tombstone/TombstoneComponents/EditIcon';
import ReasonableEffortViewIcon from 'components/Tombstone/TombstoneComponents/ReasonableEffort/ReasonableEffortViewIcon';
import NPVResultViewIcon from 'components/Tombstone/TombstoneComponents/NPVResult/NPVResultViewIcon';
import ViewIcon from 'components/Tombstone/TombstoneComponents/ViewIcon';
import * as R from 'ramda';
import * as Api from 'lib/Api';
import DashboardModel from '../Dashboard/index';

export const NA = 'NA';
const UNPAID_PRINCIPAL_BALANCE = 'Unpaid Principal Balance';
const FIRST_LEGAL_DEADLINE_DATE = 'First Legal Deadline date';
export const N = 'N';

const { getOr } = Validators;

function generateTombstoneItem(title, content, tooltip) {
  return {
    title,
    content,
    tooltip,
  };
}

function getLoanNumber(loanDetails) {
  const loanNumber = getOr('loanNumber', loanDetails, NA);
  return generateTombstoneItem('Loan No', loanNumber);
}

function getCapModIdForLoanView(loanDetails, loanViewData) {
  let capModId = getOr('capModId', loanViewData, NA);
  if (capModId === 0) {
    capModId = NA;
  }
  const obj = { component: <ViewIcon loanInfoComponent="Cap Mod" value={capModId} /> };
  const item = {
    ...generateTombstoneItem('Cap Mod Id', capModId),
    ...obj,
  };
  return item;
}

function getInvestorLoanItem(loanDetails) {
  const investorLoanNumber = getOr('investorLoanNumber', loanDetails, NA);
  return generateTombstoneItem('Investor Loan #', investorLoanNumber);
}

function getBrandNameItem(loanDetails) {
  const brandName = getOr('brandName', loanDetails, NA);
  return generateTombstoneItem('Brand Name', brandName);
}

function getPrimaryBorrowerName(loanDetails) {
  const firstName = R.path(['primaryBorrower', 'firstName'], loanDetails);
  const lastName = R.path(['primaryBorrower', 'lastName'], loanDetails);
  const primaryBorrower = firstName && lastName ? `${firstName} ${lastName}` : NA;
  return primaryBorrower;
}

function getCoBorrowersName(loanDetails) {
  const coBorrowers = loanDetails.coBorrowers
    .filter(({ firstName, lastName }) => firstName && lastName)
    .map(({ firstName, lastName }) => `${firstName} ${lastName}`)
    .join(', ');
  return coBorrowers || NA;
}

function getPrimaryBorrowerItem(loanDetails) {
  const primaryBorrower = getPrimaryBorrowerName(loanDetails);
  return generateTombstoneItem(
    'Borrower Full Name',
    primaryBorrower,
  );
}

function geCoBorrowerItem(loanDetails) {
  const coBorrowerName = getCoBorrowersName(loanDetails);
  return generateTombstoneItem(
    'Co-Borrower Full Name',
    coBorrowerName,
  );
}

function getInvestorItem(loanDetails) {
  const investorCode = R.path(['investorInformation', 'investorCode'], loanDetails);
  const investorName = R.path(['investorInformation', 'investorName'], loanDetails);
  const { levelNumber, levelName } = getOr('InvestorHierarchy', loanDetails, {});
  const investorL3 = levelNumber && levelNumber === 3 ? levelName : '';
  const investor = investorCode && investorName ? `${investorCode} - ${investorName} - ${investorL3}` : NA;
  return generateTombstoneItem('Investor', investor);
}

function getAssumptorDetails(_lv, _pd, _g, _t, _a, assumptorDetails) {
  const assumptorName = assumptorDetails.map(item => item.borrowerName).join('\n');
  return generateTombstoneItem('Assumptor', assumptorName || NA);
}

function getUPBItem(loanDetails) {
  const amount = getOr('upbAmount', loanDetails, NA);
  const upbAmount = amount === NA ? `${amount}` : `$${amount.toLocaleString('en-US')}`;
  return generateTombstoneItem('UPB', upbAmount, UNPAID_PRINCIPAL_BALANCE);
}

function getNextPaymentDueDateItem(loanDetails) {
  const date = moment.tz(loanDetails.nextPaymentDueDate, 'America/Chicago');
  const dateString = date.isValid() ? date.format('MM/DD/YYYY') : NA;
  return generateTombstoneItem('Next Payment Due Date', dateString);
}

function getModCreatedDate({ modInfoDetails }) {
  let dateString = NA;
  if (modInfoDetails && R.has('createdDate', modInfoDetails)) {
    const date = moment.tz(modInfoDetails.createdDate, 'America/Chicago');
    dateString = date.isValid() ? date.format('MM/DD/YYYY') : NA;
  }
  return generateTombstoneItem('Created Date', dateString);
}

function getEvalId({ modInfoDetails }) {
  const evalId = getOr('evalId', modInfoDetails, NA);
  const preApproved = getOr('evalType', modInfoDetails, NA);
  const preApprovedIcon = preApproved === 'Pre-Approved' ? <img alt="preApproved" src="/static/img/preapproved.svg" /> : '';
  const inflight = getOr('inflightFlag', modInfoDetails, false);
  const inflightIcon = inflight ? <img alt="inflight" src="/static/img/inflight.svg" /> : '';
  const item = {
    ...generateTombstoneItem('Eval Id', evalId),
    preApprovedIcon,
    inflightIcon,
  };
  return item;
}

function getWaterfallName({ modInfoDetails }) {
  const waterfall = getOr('waterfall', modInfoDetails, NA);
  return generateTombstoneItem('Waterfall', waterfall);
}

function getModName({ modInfoDetails }) {
  const evalType = getOr('evalType', modInfoDetails, NA);
  const inflight = getOr('inflightFlag', modInfoDetails, false);
  const mod = inflight ? `${evalType}/Inflight` : evalType;
  return generateTombstoneItem('Eval Type', mod);
}

function getModificationType({ modInfoDetails }) {
  const modificationType = getOr('resolutionChoiceType', modInfoDetails, NA);
  return generateTombstoneItem('Resolution Choice Type', modificationType);
}

function getStatus({ modInfoDetails }) {
  const status = getOr('status', modInfoDetails, NA);
  return generateTombstoneItem('Status', status);
}

function getSubStatus({ modInfoDetails }) {
  const subStatus = getOr('subStatus', modInfoDetails, NA);
  return generateTombstoneItem('Sub Status', subStatus);
}

function getNpvStatus({ modInfoDetails }) {
  const npvStatus = getOr('npvStatus', modInfoDetails, NA);
  const npvRequestId = R.pathOr(null, ['requestId'], modInfoDetails);
  const item = {
    ...generateTombstoneItem('NPV Result', npvStatus),
    component: <NPVResultViewIcon loanInfoComponent="NPVResults" npvRequestId={npvRequestId} npvStatus={npvStatus} />,
  };

  return item;
}

function getGrossIncome({ modInfoDetails }) {
  const grossIncome = getOr('grossIncome', modInfoDetails, NA);
  return generateTombstoneItem('Gross Income', grossIncome);
}

function getNetIncome({ modInfoDetails }) {
  const netIncome = getOr('netIncome', modInfoDetails, NA);
  return generateTombstoneItem('Net Income', netIncome);
}

function getMonthlyDebt({ modInfoDetails }) {
  const monthlyDebt = getOr('monthlyDebt', modInfoDetails, NA);
  return generateTombstoneItem('Monthly Debt', monthlyDebt);
}

function getDispossableIncome({ modInfoDetails }) {
  const disposableIncome = getOr('disposableIncome', modInfoDetails, NA);
  return generateTombstoneItem('Disposable Income', disposableIncome);
}

function getForeClosureFirm(loanDetails) {
  const firm = getOr('foreclosureAttorneyName', loanDetails, NA);
  const item = {
    ...generateTombstoneItem('ForeClosure Firm', firm),
    component: <ViewIcon loanInfoComponent="ForeClosureFirm" />,
  };
  return item;
}

function getDebtCoverageRatio({ modInfoDetails }) {
  const debtCoverageRatio = getOr('debtCoverageRatio', modInfoDetails, null);
  const debtRatio = debtCoverageRatio ? `${debtCoverageRatio.toFixed(2)}%` : NA;
  return generateTombstoneItem('Debt Coverage Ratio', debtRatio);
}

function getCapModId({ modInfoDetails }) {
  let capModId = getOr('capModId', modInfoDetails, NA);
  if (capModId === 0) {
    capModId = NA;
  }
  const { taskName } = window;
  let obj = null;
  if (!(taskName === 'Stager-Escrow-Ordered' || taskName === 'DocGenStager-Escrow-Ordered' || capModId === NA)) {
    obj = { component: <ViewIcon loanInfoComponent="Cap Mod" value={capModId} /> };
  }
  const item = {
    ...generateTombstoneItem('Cap Mod Id', capModId),
    ...obj,
  };
  return item;
}

function getReasonableEffort({ modInfoDetails }) {
  const reasonableEffort = getOr('reasonableEffortId', modInfoDetails, NA);
  const item = {
    ...generateTombstoneItem('Reasonable Effort', reasonableEffort),
    component: <ReasonableEffortViewIcon loanInfoComponent="Reasonable effort" />,
  };
  return item;
}

const getHardship = ({ hardshipDetails }) => {
  const hardship = getOr('hardshipType', hardshipDetails, NA);
  const item = {
    ...generateTombstoneItem('Hardship', hardship),
    component: <EditIcon loanInfoComponent="Hardship" />,
  };
  return item;
};

function getFormattedModDocsDate(dateToBeFormatted) {
  let dateInMmDdYyyyFormat = 'NA';
  if (dateToBeFormatted !== NA) {
    const dateWithoutTime = dateToBeFormatted.slice(0, 10);
    dateInMmDdYyyyFormat = `${dateWithoutTime.substring(5, 7)}/${dateWithoutTime.substring(8, 10)}/${dateWithoutTime.substring(2, 4)}`;
  }
  return dateInMmDdYyyyFormat;
}

function getReceivedDate({ modDocsDetails }) {
  const receivedDate = getOr('modDocsReceivedDate', modDocsDetails, NA);
  const receivedDateInMmDdYyyyFormat = getFormattedModDocsDate(receivedDate);
  return generateTombstoneItem('Received Date', receivedDateInMmDdYyyyFormat);
}

function getExecutedReceivedDate({ modDocsDetails }) {
  const executedReceivedDate = getOr('executedDocReceivedDate', modDocsDetails, NA);
  const executedReceivedDateInMmDdYyyyFormat = getFormattedModDocsDate(executedReceivedDate);
  return generateTombstoneItem('Executed Received Date', executedReceivedDateInMmDdYyyyFormat);
}

function getRecordedDate({ modDocsDetails }) {
  const recordedDate = getOr('modDocumentRecordedDate', modDocsDetails, NA);
  const recordedDateInMmDdYyyyFormat = getFormattedModDocsDate(recordedDate);
  return generateTombstoneItem('Recorded Date', recordedDateInMmDdYyyyFormat);
}

function getAllQualifiedDocsReceived({ modDocsDetails }) {
  const allQualifiedDocsReceived = getOr('allQualifyingPaymentsReceivedDate', modDocsDetails, NA);
  const
    allQualifiedDocsReceivedInMmDdYyyyFormat = getFormattedModDocsDate(allQualifiedDocsReceived);
  return generateTombstoneItem('All Qualified Docs Received', allQualifiedDocsReceivedInMmDdYyyyFormat);
}

function getUnderwritingApproved({ modDocsDetails }) {
  const underwritingApproved = getOr('docUnderWritingApproveddate', modDocsDetails, NA);
  const underwritingApprovedInMmDdYyyyFormat = getFormattedModDocsDate(underwritingApproved);
  return generateTombstoneItem('Underwriting Approved', underwritingApprovedInMmDdYyyyFormat);
}

function getPreviousDisposition({ previousDispositionDetails, taskName }) {
  if (previousDispositionDetails) {
    const taskObj = R.find(R.propEq('taskName', taskName))(previousDispositionDetails);
    const previousDisposition = taskObj
      ? getOr('stsChangedCode', taskObj, NA) : NA;
    return generateTombstoneItem('Previous Disposition', previousDisposition);
  }
  return generateTombstoneItem('Previous Disposition', NA);
}

function getLatestHandOffDisposition({ previousDispositionDetails, taskName }) {
  if (previousDispositionDetails) {
    const taskObj = R.find(R.propEq('taskName', taskName))(previousDispositionDetails);
    const previousDisposition = taskObj
      ? getOr('latestHandOffDispositionCode', taskObj, NA) : NA;
    return generateTombstoneItem('Latest Handoff Disposition', previousDisposition);
  }
  return generateTombstoneItem('Latest Handoff Disposition', NA);
}

function getFLDD(loanDetails) {
  const fldd = R.path(['LoanExtension', 'firstLegalDueDate'], loanDetails);
  if (fldd) {
    const date = moment.tz(fldd, 'America/Chicago');
    const dateString = date.isValid() ? date.format('MM/DD/YYYY') : NA;
    return generateTombstoneItem('FLDD Date', dateString, FIRST_LEGAL_DEADLINE_DATE);
  }
  return generateTombstoneItem('FLDD Date', NA, FIRST_LEGAL_DEADLINE_DATE);
}

function getForeclosureSalesDate(loanDetails) {
  const date = moment.tz(loanDetails.foreclosureSalesDate, 'America/Chicago');
  const dateString = date.isValid() ? date.format('MM/DD/YYYY') : NA;
  return generateTombstoneItem('Foreclosure Sale Date and Status', dateString);
}

function getSuccessorInInterestStatus(loanDetails) {
  const successorInInterestStatus = getOr('successorInInterestStatus', loanDetails, NA);
  return generateTombstoneItem('Successor in Interest Status', successorInInterestStatus);
}

function getReasonForDefault(_, loanViewData) {
  const reasonForDefault = getOr('reasonForDefault', loanViewData, NA);
  const item = {
    ...generateTombstoneItem('Reason for Default', reasonForDefault),
    component: <EditIcon loanInfoComponent="Reason for Default" />,
  };
  return item;
}

function getOccupancyType(_, loanViewData) {
  const occupancyType = getOr('occupancyType', loanViewData, NA);
  const item = {
    ...generateTombstoneItem('Occupancy Type', occupancyType),
    component: <EditIcon loanInfoComponent="Occupancy Type" />,
  };
  return item;
}
function getOriginalPropertyValue(_, loanViewData) {
  const originalPropValue = getOr('originalPropertyValue', loanViewData, NA);
  return generateTombstoneItem('Original Property Value', originalPropValue);
}
function getInterestFlag(loanDetails) {
  const interestFlag = getOr('interestFlag', loanDetails, NA);
  return generateTombstoneItem('Interest Flag', interestFlag);
}
function getMortgateInstrument(loanDetails) {
  const mortgageInstrumentValue = getOr('mortgageInstrument', loanDetails, NA);
  return generateTombstoneItem('Mortgage Instrument', mortgageInstrumentValue);
}
function getIOFlag(loanDetails) {
  const ioFlag = R.pathOr(NA, ['LoanExtension', 'intOnlyInd'], loanDetails);
  return generateTombstoneItem('IO Flag', ioFlag);
}
function getCFPBFlag(_, loanViewData) {
  const cfpbFlag = getOr('cfpbFlag', loanViewData, N);
  return generateTombstoneItem('CFPB Flag', cfpbFlag);
}

function getLakeviewTotalFlag(loanDetails) {
  const levelName = R.pathOr(NA, ['investorL7'], loanDetails);
  const isLakeviewTotal = levelName === 'Lakeview Total' ? 'Y' : 'N';
  return generateTombstoneItem('Lakeview Total Flag', isLakeviewTotal);
}

function getFreddieIndicator(_data) {
  const investorCode = R.path(['investorInformation', 'investorCode'], _data.loanDetails);
  const investorCodes = ['BJ9', 'CAT', 'BX6', 'BX7'];
  const freddieSystem = investorCodes.includes(investorCode) ? NA : _data.freddieIndicatorData;

  return generateTombstoneItem('Freddie System', freddieSystem || NA);
}

function getLienPosition(loanDetails) {
  const lienPosition = getOr('lienPosition', loanDetails, NA);
  return generateTombstoneItem('Lien position', lienPosition);
}

function createKickbackElement() {
  return (
    <div style={{
      background: 'linear-gradient(90deg, #eb6c6b 0%, #ea4680 96.01%)',
      color: 'white',
      borderRadius: '2px',
      padding: '.2rem',
    }}
    >
      Kickback Case
    </div>
  );
}

function shouldShowKickback({ disposition, groupName }) {
  if (!R.isNil(disposition)) {
    const isFEUW = groupName === 'FEUW';
    const isBEUW = groupName === 'BEUW';
    const isSendToFrontendUnderwriting = (disposition.toLowerCase() === 'sendtofrontendunderwriting'
      || disposition.toLowerCase() === 'sendtofeuw');
    const isReferralValidKB = disposition.toLowerCase() === 'referralvalidkb';
    const isSendToUnderwriting = disposition.toLowerCase() === 'sendtounderwriting';

    return (isFEUW && isSendToFrontendUnderwriting)
      || (isBEUW && (isReferralValidKB || isSendToUnderwriting));
  }
  return false;
}

function getKickbackIndicator({ disposition, groupName }) {
  if (shouldShowKickback({ disposition, groupName })) {
    return generateTombstoneItem(createKickbackElement(), '');
  }
  return null;
}

function getKickbackIndicatorForLoanView(_lvd,
  _ld, _pvd, groupName, taskname, assumptor, prevDispositionForLoanView) {
  if (shouldShowKickback({ disposition: prevDispositionForLoanView, groupName })) {
    return generateTombstoneItem(createKickbackElement(), '');
  }
  return null;
}


function getDelinquencyStartDate(_, loanViewData) {
  const cfbp = R.path(['delinquencyStartDate'], loanViewData);
  let dateString = NA;
  if (cfbp) {
    const date = moment.tz(cfbp, 'America/Chicago');
    dateString = date.isValid() ? date.format('MM/DD/YYYY') : NA;
  }
  const item = {
    ...generateTombstoneItem('Delinquency Start Date', dateString),
    component: <ViewIcon loanInfoComponent="Delinquency Start Date" />,
  };
  return item;
}

function getTombstoneItems(tombstoneData) {
  const {
    loanDetails,
    modInfoDetails,
    previousDispositionDetails,
    groupName, taskName,
    freddieIndicatorData,
    loanViewData,
    assumptorDetails,
    hardshipDetails,
    modDocsDetails,
    disposition,
  } = tombstoneData;


  const loanViewDataGenerator = [
    getKickbackIndicatorForLoanView,
    getLoanNumber,
    getAssumptorDetails,
    getUPBItem,
    getPrimaryBorrowerItem,
    geCoBorrowerItem,
    getSuccessorInInterestStatus,
    getBrandNameItem,
    getInvestorItem,
    getInvestorLoanItem,
    getLienPosition,
    getMortgateInstrument,
    getNextPaymentDueDateItem,
    getForeclosureSalesDate,
    getFLDD,
    getDelinquencyStartDate,
    getReasonForDefault,
    getOccupancyType,
    getOriginalPropertyValue,
    getInterestFlag,
    getIOFlag,
    getCFPBFlag,
    getLakeviewTotalFlag,
  ];
  if (window.isLossMitigationAgent) {
    loanViewDataGenerator.splice(13, 0, getForeClosureFirm);
  }
  if (groupName === 'SEARCH_LOAN') {
    loanViewDataGenerator.splice(14, 0, getCapModIdForLoanView);
  }
  const modViewDataGenerator = [
    getKickbackIndicator,
    getEvalId,
    getModCreatedDate,
    getWaterfallName,
    getModName,
    getModificationType,
    getStatus,
    getSubStatus,
    getNpvStatus,
    getGrossIncome,
    getNetIncome,
    getCapModId,
    getMonthlyDebt,
    getDispossableIncome,
    getDebtCoverageRatio,
    getReasonableEffort,
    getHardship,
    getPreviousDisposition,
    getLatestHandOffDisposition,
    getReceivedDate,
    getExecutedReceivedDate,
    getRecordedDate,
    getAllQualifiedDocsReceived,
    getUnderwritingApproved,
  ];
  if (R.equals(groupName, DashboardModel.BEUW) || (R.equals(groupName, 'INVSET') && R.equals(taskName, 'Investor Settlement')) || R.equals(groupName, DashboardModel.DOC_GEN)) {
    modViewDataGenerator.splice(4, 0, getFreddieIndicator);
  }
  const data = {};
  data.loanViewData = R.filter(value => value)(loanViewDataGenerator.map(fn => fn(loanDetails,
    loanViewData,
    previousDispositionDetails,
    groupName,
    taskName,
    assumptorDetails,
    disposition)));
  if (groupName !== 'SEARCH_LOAN') {
    data.modViewData = R.filter(value => value)(modViewDataGenerator.map(fn => fn({
      modInfoDetails,
      previousDispositionDetails,
      groupName,
      taskName,
      freddieIndicatorData,
      loanDetails,
      assumptorDetails,
      hardshipDetails,
      modDocsDetails,
      disposition,
    })));
  }
  return data;
}


async function fetchData(loanNumber, evalId, groupName, taskName, taskId, brand, userGroups) {
  window.taskName = taskName;
  const payload = {
    loanNumber, evalId, groupName, taskName, taskId, brand,
  };

  window.isLossMitigationAgent = !R.isEmpty(R.intersection(userGroups, ['lossmitigation', 'lossmitigation-mgr']));
  const response = await Api.callPost('/api/data-aggregator/tombstone/data', payload);
  const prevDispositonResponse = await Api.callGet(`api/bpm-audit/loanactivity/getPreviousDisposition/${evalId}`);
  const { disposition } = prevDispositonResponse;
  const {
    loanDetails,
    modInfoDetails,
    previousDispositionDetails,
    freddieIndicatorData,
    loanViewData,
    assumptorDetails,
    hardshipDetails,
    modDocsDetails,
  } = response;
  const { loanMAState } = loanViewData;
  const hardshipBegindate = moment.tz(loanDetails.nextPaymentDueDate, 'America/Chicago');
  const hardshipBeginDateString = hardshipBegindate.isValid() ? hardshipBegindate.format('YYYY-MM-DD') : NA;
  let hardshipEndDateString = null;
  if (!R.isNil(modInfoDetails)) {
    const hardshipEndDate = moment.tz(modInfoDetails.createdDate, 'America/Chicago');
    hardshipEndDateString = hardshipEndDate.isValid() ? hardshipEndDate.format('YYYY-MM-DD') : NA;
  }
  return {
    resolutionId: R.propOr(null, 'modId', modInfoDetails),
    investorHierarchy: R.propOr(null, 'InvestorHierarchy', loanDetails),
    investorCode: R.pathOr(null, ['investorInformation', 'investorCode'], loanDetails),
    brandName: R.propOr(null, 'brandName', loanDetails),
    loanType: R.pathOr(null, ['loanType'], loanDetails),
    waterfallId: R.pathOr(null, ['waterfallId'], modInfoDetails),
    resolutionChoiceType: R.pathOr(null, ['resolutionChoiceType'], modInfoDetails),
    messageCodes: R.propOr([], 'messageCodes', loanDetails),
    propertyInfo: R.propOr(null, 'Property', loanDetails),
    deliquentInfo: R.pathOr(0, ['loanDeliquent', 'daysDelinquent'], loanDetails),
    lienPosition: R.propOr(null, 'lienPosition', loanDetails),
    loanMAState,
    tombstoneData:
    {
      ...getTombstoneItems({
        loanDetails,
        modInfoDetails,
        previousDispositionDetails,
        groupName,
        taskName,
        freddieIndicatorData,
        loanViewData,
        assumptorDetails,
        hardshipDetails,
        modDocsDetails,
        disposition,
      }),
    },
    hardshipEndDate: hardshipEndDateString,
    hardshipBeginDate: hardshipBeginDateString,
  };
}

const LoanTombstone = {
  generateTombstoneItem,
  getTombstoneItems,
  fetchData,
};

export default LoanTombstone;
